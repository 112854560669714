<ng-container *ngIf="showUnAuth | async">
    <div class="card">
        <div class="card-header"><h1>Unauthenticated</h1></div>
        <div class="card-body"><h3>You're not authorized because you haven't provided any credential.</h3></div>
    </div>
</ng-container>
<ng-container *ngIf="showToUFailure | async">
    <div class="card">
        <div class="card-header">
            <h1>Terms of Use (ToU) Acceptance failed.</h1>
        </div>
        <div class="card-body">
            <h3>
                Acceptance of ToU is needed to access MAST. Please try again by visiting <a href="aka.ms/mast">aka.ms/mast</a> or contact
                <a href="mailto:masthelp@microsoft.com">MASTHelp</a>
            </h3>
        </div>
    </div>
</ng-container>
