<div class="card mast-app-context">
    <mast-shared-header
        class="card-header mast-menu"
        id="subjectHeader"
        [title]="subjectName"
        (refreshEvent)="refreshData()"
        enableJsonViewer="true"
        (viewerStateChangedEvent)="processViewerStateChange()"
        (jsonViewerStateChangedEvent)="toggleJsonViewer()"
        [viewerState]="viewerState"
    >
    </mast-shared-header>
    <mat-drawer-container class="mast-container">
        <div class="card-body mast-data-context-viewer">
            <ng-container [ngTemplateOutlet]="preViewer"></ng-container>
            <ng-container *ngIf="isLoading; then loading; else notLoading"></ng-container>
            <ng-template #loading>
                <mast-loader-status></mast-loader-status>
            </ng-template>
            <ng-template #notLoading>
                <ng-container *ngIf="wasSuccessful; then successful; else notSuccessful"></ng-container>
                <ng-template #successful>
                    <div *ngIf="data" class="mat-elevation-z2">
                        <mast-drill-down-viewer
                            [data]="data"
                            [schema]="schema"
                            [config]="config"
                            [propertyName]="subjectName"
                            (viewerStateChange)="viewerStateChanged($event)"
                            [tableLoading]="tableLoading"
                            [paginatorLeft]="tablePaginatorLeftChild"
                            [paginatorRight]="tablePaginatorRightChild"
                        >
                        </mast-drill-down-viewer>
                    </div>
                </ng-template>
                <ng-template #notSuccessful>
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Request failed</mat-card-title>
                            <mat-card-subtitle>We're unable to fulfill your request at this time</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>Please review the Debug panel for details.</mat-card-content>
                    </mat-card>
                </ng-template>
            </ng-template>
            <ng-container [ngTemplateOutlet]="postViewer"></ng-container>
        </div>
        <mat-drawer #jsonViewer class="{{ jsonViewerClass }}" mode="over" position="end">
            <mast-shared-json-viewer-pane (closeEvent)="jsonViewer.toggle()" (fullScreenEvent)="toggleJsonViewerFullScreen()"></mast-shared-json-viewer-pane>
        </mat-drawer>
    </mat-drawer-container>
</div>

<ng-template #tablePaginatorLeftChild>
    <ng-container [ngTemplateOutlet]="tablePaginatorLeft"></ng-container>
</ng-template>

<ng-template #tablePaginatorRightChild>
    <ng-container [ngTemplateOutlet]="tablePaginatorRight"></ng-container>
</ng-template>
