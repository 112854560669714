import { RootPath } from '../app-routing/root-paths';
import { ApplicationContextItem } from '../models/application-context-item';
import { SearchCriteriaValues } from '../models/search-criteria-values';
import { EnterpriseTenant_OrgId_Subpath } from './enterprise-subpaths';
import { EnterpriseLookupService, EnterpriseLookupServiceToken } from './services/lookup/enterprise-lookup.service';

export const enterpriseApplicationContextItems: ApplicationContextItem = {
    name: 'Enterprise',
    isRedirect: false,
    lookupService: EnterpriseLookupServiceToken,
    url: `/${RootPath.EnterpriseLegacy}`,
    categories: [
        {
            title: 'Account Profile',
            routerLink: `${RootPath.EnterpriseLegacy}/:environment/:tenantId/:?organizationId/accountprofile`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-legacy-account-profile',
            searchLandingPage: true,
            description: 'An overview of the Tenant/OrgId.',
        },
        {
            title: 'Users & Roles',
            routerLink: `${RootPath.EnterpriseLegacy}/:environment/:tenantId/:?organizationId/usersandroles`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-legacy-users-and-roles',
            description: 'Provides an overview of all of the users under a given tenant.',
        },
        // ENTERPRISE DECOMISSION
        // {
        //     title: 'Catalogs',
        //     routerLink: `${RootPath.EnterpriseLegacy}/:environment/:tenantId/:?organizationId/catalogs`,
        //     routerLinkActive: 'active',
        //     iconClass: 'enterprise-legacy-catalog',
        //     description: 'Provides an overview of all of the catalogs associated to a given tenant.',
        // },
        {
            title: 'Orders',
            routerLink: `${RootPath.EnterpriseLegacy}/${EnterpriseTenant_OrgId_Subpath.Orders}`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-legacy-orders',
            isVisible: showTenantAndOrgIdCategory,
            description: 'List of Orders for a given Tenant and OrgId. This view is visible only when OrgId lookup is used.',
        },
        {
            title: 'Entitlements',
            routerLink: `${RootPath.EnterpriseLegacy}/${EnterpriseTenant_OrgId_Subpath.Entitlements}`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-legacy-entitlements',
            isVisible: showTenantAndOrgIdCategory,
            description: 'Entitlement details for a tenant.',
        },
        // ENTERPRISE DECOMISSION
        // {
        //     title: 'Inventory',
        //     routerLink: `${RootPath.EnterpriseLegacy}/${EnterpriseTenant_Subpath.Inventory}`,
        //     routerLinkActive: 'active',
        //     iconClass: 'enterprise-legacy-inventory',
        //     isVisible: showTenantOnlyCategory,
        //     description: 'Provides an overview of all of the inventory in a given tenant.',
        // },
        // {
        //     title: 'MDMs',
        //     routerLink: `${RootPath.EnterpriseLegacy}/${EnterpriseTenant_Subpath.Mdms}`,
        //     routerLinkActive: 'active',
        //     iconClass: 'enterprise-legacy-mdms',
        //     // Like inventory, MDMs are a property of the tenant -- not a specific user.
        //     isVisible: showTenantOnlyCategory,
        //     description: 'Device Management Software that can be used by the enterprise.',
        // },
        // {
        //     title: 'Security Groups',
        //     routerLink: `${RootPath.EnterpriseLegacy}/${EnterpriseTenant_Subpath.SecurityGroups}`,
        //     routerLinkActive: 'active',
        //     iconClass: 'enterprise-legacy-security-groups',
        //     isVisible: showTenantOnlyCategory,
        //     description: 'Product targets for the tenant.',
        // },
        // {
        //     title: 'Tenant Logs',
        //     href: getCodexUrl,
        //     iconClass: 'codex',
        //     description: 'Codex logs for a given tenant.',
        // },
    ],
    primarySearchTypes: [
        {
            name: 'Tenant and Org ID',
            service: 'EnterpriseAccountProfileService',
            method: 'ByTenantAndOrg',
            routerLink: `${RootPath.EnterpriseLegacy}/:environment/:tenantId/:?organizationId/accountprofile`,
            identifiers: [
                {
                    name: 'Tenant Id',
                    value: 'tenantId',
                    description: 'The Application/Tenant ID',
                },
                {
                    name: 'Organization Id',
                    value: 'organizationId',
                    description: 'The User/Organization ID',
                },
            ],
        },
    ],
};

/** Show a tenant-only category, given the current search criteria?
 *
 * @return `true` when the provided `searchCriteria` do *not* specify an organization ID.
 */
export function showTenantOnlyCategory(searchCriteria: SearchCriteriaValues): boolean {
    return !(searchCriteria && searchCriteria[EnterpriseLookupService.organizationIdProperty]);
}

/** Show a category that requires both a tenant *and* an organization ID (user), given the
 * current search criteria?
 *
 * @return `true` when the provided `searchCriteria` *do* specify an organization ID.
 */
export function showTenantAndOrgIdCategory(searchCriteria: SearchCriteriaValues): boolean {
    return !showTenantOnlyCategory(searchCriteria);
}

export function getCodexUrl(searchCriteria: SearchCriteriaValues): string {
    const tenantId = searchCriteria[EnterpriseLookupService.tenantIdProperty];
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 2);
    const endDateStr = endDate.toISOString().substring(0, 10);
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 60);
    const startDateStr = startDate.toISOString().substring(0, 10);
    return `https://codex.microsoft.com/vault/cv/TenantId/summaries?SearchId=${tenantId}&SearchStartDate=${startDateStr}&SearchEndDate=${endDateStr}`;
}
