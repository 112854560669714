import { MastResult } from '../../../models/dal/mast-result';
import * as SM from '../../../models/state-manager/state-manager-search.actions';
import { EnterpriseTenantStateParameters } from '../../models/enterprise-tenant-state.parameters';

export const SEARCH = '[EnterpriseAccountProfile] Search';
export const SEARCH_FAILED = '[EnterpriseAccountProfile] Search Failed';
export const RESET = '[EnterpriseAccountProfile] Search Reset';
export const REFRESH = '[EnterpriseAccountProfile] Search Refresh';
export const SEARCH_SUCCESSFUL = '[EnterpriseAccountProfile] Search Successful';

export class EnterpriseAccountProfileSearchAction implements SM.StateManagerSearchAction<EnterpriseTenantStateParameters> {
    readonly type = SEARCH;
    constructor(public parameters: EnterpriseTenantStateParameters) {}
}

export class EnterpriseAccountProfileSearchRefreshAction implements SM.StateManagerSearchRefreshAction {
    readonly type = REFRESH;
}

export class EnterpriseAccountProfileSearchResetAction implements SM.StateManagerSearchResetAction {
    readonly type = RESET;
}

export class EnterpriseAccountProfileSearchSuccessfulAction implements SM.StateManagerSuccessfulAction<MastResult> {
    readonly type = SEARCH_SUCCESSFUL;
    constructor(public result: MastResult) {}
}

export class EnterpriseAccountProfileSearchFailedAction implements SM.StateManagerSearchFailedAction {
    readonly type = SEARCH_FAILED;
    constructor(public errorCode: string, public errorMessage) {}
}
