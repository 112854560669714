import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { TermsOfUseState } from './services/terms-of-use.state';
import { AppState } from '../app.states';
import { TermsOfUseInitializeAction } from './services/terms-of-use.actions';

@Injectable()
export class TermsOfUseGuard implements CanActivate, CanLoad {
    private termsOfUseState: Observable<TermsOfUseState>;
    constructor(private authStore$: Store<AppState>) {
        this.termsOfUseState = this.authStore$.pipe(
            select((result) => result.termsOfUseState),
            filter((x) => x.isComplete)
        );
    }

    public canLoad(route: Route): Observable<boolean> {
        this.authStore$.dispatch(new TermsOfUseInitializeAction([], {}, {}));
        return this.termsOfUseState.pipe(
            first(),
            map((termsOfUseState) => termsOfUseState.userHasConsented)
        );
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.authStore$.dispatch(
            new TermsOfUseInitializeAction(
                next.url.map((x) => x.path),
                next.queryParams,
                {}
            )
        );
        return this.termsOfUseState.pipe(
            first(),
            map((termsOfUseState) => termsOfUseState.userHasConsented)
        );
    }
}
