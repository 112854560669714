/**
 * Paths within the enterprise routing module that don't include an organization ID.
 * Used to create the enterprise child RouterModule.
 */
export enum EnterpriseTenant_Subpath {
    AccountProfile = ':environment/:tenantId/accountprofile',
    UsersAndRoles = ':environment/:tenantId/usersandroles',
    Catalogs = ':environment/:tenantId/catalogs',
    Inventory = ':environment/:tenantId/inventory',
    Mdms = ':environment/:tenantId/mdms',
    SecurityGroups = ':environment/:tenantId/securityGroups',
}

/**
 * Paths within the enterprise routing module that include an organization ID.
 * Used to create the enterprise child RouterModule.
 */
export enum EnterpriseTenant_OrgId_Subpath {
    AccountProfile = ':environment/:tenantId/:organizationId/accountprofile',
    UsersAndRoles = ':environment/:tenantId/:organizationId/usersandroles',
    Catalogs = ':environment/:tenantId/:organizationId/catalogs',
    Orders = ':environment/:tenantId/:organizationId/orders',
    Entitlements = ':environment/:tenantId/:organizationId/entitlements',
}
