export const optionalVariablePattern = /\/\:\?[a-z,0-9]*/i;
export const requiredVariablePattern = /\/\:[a-z,0-9]*/i;

export function applyParameterReplacement(link: string, parameterName: string, linkParameterName: string, value: any): string {
    if (value === undefined || value === null) {
        return link;
    }

    if (link.indexOf(`:${parameterName}`) > -1) {
        link = link.replace(`:${parameterName}`, value as string);
    } else if (link.indexOf(`:?${parameterName}`) > -1) {
        link = link.replace(`:?${parameterName}`, value as string);
    }

    if (link.indexOf(`:${linkParameterName}`) > -1 && parameterName !== linkParameterName) {
        link = link.replace(`:${linkParameterName}`, value as string);
    } else if (link.indexOf(`:?${linkParameterName}`) > -1 && parameterName !== linkParameterName) {
        link = link.replace(`:?${linkParameterName}`, value as string);
    }

    return link;
}

export function finalizeLink(link: string): string {
    if (optionalVariablePattern.test(link)) {
        link = link.replace(optionalVariablePattern, '');
    }

    if (requiredVariablePattern.test(link)) {
        throw new SyntaxError(`Missing variable detected: ${link}`);
    }

    return link;
}
