import { Component, Input } from '@angular/core';
import { FormFieldBaseDirective } from '../form-field-base/form-field-base';

@Component({
    selector: 'mast-form-select',
    templateUrl: './form-select.component.html',
    styleUrls: ['../form-field-base/form-field-base.css', './form-select.component.css'],
})
export class FormSelectComponent extends FormFieldBaseDirective {
    @Input()
    options: Array<string>;

    constructor() {
        super();
    }
}
