import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../authentication/authentication.guard';
import { AuthorizationGuard } from '../authorization/authorization.guard';
import { TermsOfUseGuard } from '../terms-of-use/terms-of-use.guard';
import { RootPath } from './root-paths';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: RootPath.Consumer,
        pathMatch: 'full',
    },
    {
        path: RootPath.EnterpriseLegacy,
        loadChildren: () => import('../enterprise/enterprise.module').then((m) => m.EnterpriseModule),
        canLoad: [AuthenticationGuard, AuthorizationGuard, TermsOfUseGuard],
    },
    {
        path: RootPath.Consumer,
        loadChildren: () => import('../consumer/consumer.module').then((m) => m.ConsumerModule),
        canLoad: [AuthenticationGuard, AuthorizationGuard, TermsOfUseGuard],
    },
    {
        path: RootPath.Tools,
        loadChildren: () => import('../tools/tools.module').then((m) => m.ToolsModule),
        canLoad: [AuthenticationGuard, AuthorizationGuard, TermsOfUseGuard],
    },
    {
        path: RootPath.ConsumerPurchase,
        loadChildren: () => import('../consumerpurchase/consumerpurchase.module').then((m) => m.ConsumerPurchaseModule),
        canLoad: [AuthenticationGuard, AuthorizationGuard, TermsOfUseGuard],
    },
    {
        path: RootPath.Unauthorized,
        loadChildren: () => import('../authorization/authorization.module').then((m) => m.AuthorizationModule),
        data: { title: 'Unauthorized' },
    },
];

/**
 * This controls the routing for the entire application.
 * For best practices, don't modify this module directly, unless it's to instantiate the default home page.
 */
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false, useHash: true } // <-- debugging purposes only
        ),
    ],
    declarations: [],
    exports: [RouterModule],
})
export class AppRoutingModule {}
