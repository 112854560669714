import { Component, OnInit, Input } from '@angular/core';

import { MastResponseTrace } from '../../../models/dal/mast-response-trace';
import { MastJsonEditorWrapperOptions } from '../../../models/mast-json-editor-wrapper/mast-json-editor-wrapper-options';

@Component({
    selector: 'mast-debug-response-unit',
    templateUrl: './debug-response-unit.component.html',
    styleUrls: ['./debug-response-unit.component.css'],
})
export class DebugResponseUnitComponent implements OnInit {
    public jsonViewerOptions: MastJsonEditorWrapperOptions = {
        mode: 'view',
        modes: ['code', 'view'], // allowed modes
        indentation: 4,
        escapeUnicode: true,
        onEditable: (node) => false,
        autocomplete: { getOptions: (text, path, input, editor) => null },
    };

    @Input()
    public responseTrace: MastResponseTrace;

    @Input()
    public accordionName: string;

    /**
     * The ordinal number in respect to the list of requests made.
     */
    @Input()
    public outerIndex: number;

    /**
     * The ordinal number in respect to the list of external requests made by the MAX Web API service.
     */
    @Input()
    public innerIndex: number;

    @Input()
    public hasErrored: boolean;

    /**
     * Response body as an object.
     */
    public body: any;

    public ngOnInit(): void {
        if (this.responseTrace && this.responseTrace.Body) {
            try {
                const newObject = JSON.parse(this.responseTrace.Body);
                if (newObject) {
                    this.body = newObject;
                }
            } catch (e) {
                // If JSON parsing fails, then proceed with displaying the original data.
                this.body = this.responseTrace.Body;
            }
        }
    }
}
