import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * The last line of defense for handling ADAL.JS callbacks and ToU error messages.
 */
@Component({
    selector: 'mast-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.css'],
})
export class AuthenticationComponent implements OnInit {
    public showToUFailure: Observable<boolean>;
    public showUnAuth: Observable<boolean>;

    constructor() {}

    ngOnInit() {
        // default to show the unAuth page
        this.showUnAuth = this.showUnAuth === undefined ? of(true) : this.showUnAuth;
        this.showToUFailure = this.showToUFailure === undefined ? of(false) : this.showToUFailure;
    }

    public setShowToUFailure(value: boolean) {
        this.showToUFailure = of(value);
    }

    public setShowUnAuth(value: boolean) {
        this.showUnAuth = of(value);
    }
}
