import { Injector } from '@angular/core/core';
import * as Lodash from 'lodash-es';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, share } from 'rxjs/operators';
import { DataRetrievalState } from './data-retrieval-state';
import { DrillDownConfig } from './drill-down-config';
import { DrillDownConfigBag } from './drill-down-config-bag';
import { noOpDataTransformerFunction } from './drill-down-data-transformer';
import { DrillDownDeferredDataProviderSchema } from './schemas/drill-down-deferred-data-provider-schema';
import { DrillDownPropertyMappingSchema } from './schemas/drill-down-property-mapping-schema';

/**
 * Represents data, deferred or not, for a given request.
 */
export class DeferredDataProviderState {
    /**
     * Indicates whether the data is in array or object form.
     */
    public isArray: boolean;
    public searchStatus: Observable<boolean>;
    public data: Observable<any>;

    private dataSubject$: BehaviorSubject<DataRetrievalState> = new BehaviorSubject<DataRetrievalState>({
        searchCompleted: false,
        searchInProgress: false,
        data: null,
    });
    private dataObservable$: Observable<DataRetrievalState> = this.dataSubject$.asObservable().pipe(share());

    constructor(
        public deferredDataProviderSchema?: DrillDownDeferredDataProviderSchema,
        public config?: DrillDownConfig,
        public fieldName?: string,
        public parentData?: any,
        protected injector?: Injector
    ) {
        this.searchStatus = this.dataSubject$.pipe(map((x) => x.searchCompleted));
        this.data = this.dataSubject$.pipe(
            filter((x) => x.searchCompleted),
            map((x) => x.data)
        );
    }

    /**
     * Starts data retrieval or Refreshes the data. This data can originate from a data provider service (if the
     * provider and the function name is present in the DrillDownDeferredDataProviderSchema). If the dataFieldName
     * property of the DrillDownDeferredDataProviderSchema has been provided, then the data will be retrieved from
     * field name provide from the parentData's object. Otherwise, the data will be extracted from the entire
     * parentData directly.
     * @param clearCache If set to true, the prior data will be overriden by freshly retrieved data.
     */
    public invokeDataRetrievalProcess(clearCache?: boolean): Observable<boolean> {
        if (clearCache || (!this.dataSubject$.value.searchCompleted && !this.dataSubject$.value.searchInProgress)) {
            this.dataSubject$.next({ searchCompleted: false, searchInProgress: true, data: null });
            const deferedDataProvider = this.deferredDataProviderSchema;
            const globalProperties: DrillDownConfigBag = this.config && this.config.globalVariables ? this.config.globalVariables : {};
            if (deferedDataProvider && deferedDataProvider.dataProvider && deferedDataProvider.dataProviderFunctionName) {
                const dataTransformer = deferedDataProvider.dataTransformer ? deferedDataProvider.dataTransformer : noOpDataTransformerFunction;
                const dataProvider = this.injector.get(deferedDataProvider.dataProvider);
                if (!dataProvider || !(deferedDataProvider.dataProviderFunctionName in dataProvider)) {
                    throw new Error(`Data Provider ${deferedDataProvider.dataProvider} wasn't registered.`);
                } else {
                    const functionOfDataProvider: Function = dataProvider[deferedDataProvider.dataProviderFunctionName];
                    let dataProviderExecution: Observable<Object>;
                    if (deferedDataProvider.parameterProperties) {
                        const parameters = [];
                        deferedDataProvider.parameterProperties.forEach((parameterSchema) => {
                            const propertyValue = this.getPropertyValueForParameter(parameterSchema);
                            if (propertyValue !== undefined) {
                                parameters.push(propertyValue);
                            }
                        });

                        dataProviderExecution = <Observable<Object>>functionOfDataProvider.apply(dataProvider, parameters);
                    } else {
                        dataProviderExecution = <Observable<Object>>functionOfDataProvider.apply(dataProvider);
                    }

                    if (dataProviderExecution) {
                        dataProviderExecution
                            .pipe(
                                map((result) => dataTransformer(result, this.deferredDataProviderSchema.schema, this.config)),
                                catchError((ex) => {
                                    this.dataSubject$.next({
                                        searchCompleted: true,
                                        searchInProgress: false,
                                        data: null,
                                    });
                                    return of(null);
                                })
                            )
                            .subscribe((dataRetrieved) => {
                                if (dataRetrieved) {
                                    if (dataRetrieved && dataRetrieved instanceof Array) {
                                        this.isArray = true;
                                    }
                                }

                                this.dataSubject$.next({
                                    searchCompleted: true,
                                    searchInProgress: false,
                                    data: dataRetrieved,
                                });
                            });
                    }
                }
            } else if (this.fieldName) {
                let data: any = null;
                if (this.fieldName.startsWith('$$') && globalProperties) {
                    data = globalProperties[this.fieldName];
                } else if (this.parentData) {
                    data = Lodash.get(this.parentData, this.fieldName);
                }

                if (data instanceof Array) {
                    this.isArray = true;
                }

                this.dataSubject$.next({ searchCompleted: true, searchInProgress: false, data: data });
            } else {
                const data = this.parentData;
                if (data instanceof Array) {
                    this.isArray = true;
                }

                this.dataSubject$.next({ searchCompleted: true, searchInProgress: false, data: data });
            }
        }

        return this.dataObservable$.pipe(map((x) => x.searchCompleted));
    }

    protected getPropertyValueForParameter(parameterSchema: DrillDownPropertyMappingSchema) {
        const propertyName = parameterSchema.propertyName;
        let propertyValue;
        if (propertyName) {
            if (propertyName === 'this') {
                propertyValue = this.parentData;
            } else if (propertyName.startsWith('$$')) {
                propertyValue = this.config.globalVariables[parameterSchema.propertyName];
            } else if (this.parentData) {
                propertyValue = Lodash.get(this.parentData, parameterSchema.propertyName);
            }
        }

        if (propertyValue === undefined || propertyValue === null || propertyValue === '') {
            propertyValue = parameterSchema.defaultValue;
        }

        return propertyValue;
    }
}
