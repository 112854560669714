import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { generateUrl } from '../../../../utils/url-tools';
import { formatIdForUrl } from '../../../../utils/utils';
import { DrillDownAction } from '../../models/drill-down-action';
import { GenericDefaultViewerDirective } from '../../models/generic-default-viewer';
import { DrillDownPropertySchema } from '../../models/schemas/drill-down-property-schema';
import { DrillDownStateService } from '../../service/drill-down-state.service';
import { TransformationUtils } from '../../utils/transformation-utils';

// Used to generate unique HTML ids. Follows the pattern described here
// https://github.com/angular/angular/issues/5145#issuecomment-226129881
let nextId = 1;

@Component({
    selector: 'mast-default-property-viewer',
    templateUrl: './default-property-viewer.component.html',
    styleUrls: ['./default-property-viewer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultPropertyViewerComponent extends GenericDefaultViewerDirective<Object, DrillDownPropertySchema> implements OnChanges {
    public idName: string;
    public isPrimitive = false;
    public isArray = false;
    public isObject = false;
    public isHidden = true;
    public isNullOrEmpty = false;
    public isExpandedByDefault = false;
    public id: number = nextId++;
    public definedAction?: DrillDownAction;
    public linkUrl?: string;

    public get propertyGroupId(): string {
        return `${this.idName}_group_${this.id}`;
    }

    public get propertyExpandId(): string {
        return `${this.idName}_${this.currentDepth}_collapse_${this.id}`;
    }

    constructor(private navStateService: DrillDownStateService) {
        super();
    }
    @Input()
    set data(value: Object) {
        this.currentData = value;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['name'] && this.name) {
            this.name = this.name.trim();
        }

        if (this.currentData === null || this.currentData === undefined) {
            this.isPrimitive = true;
            this.setIsNullOrEmpty();
        } else {
            const dataType = typeof this.currentData;
            this.isHidden = !this.currentSchema || this.currentSchema.isHidden;
            if (dataType === 'string' || dataType === 'number' || dataType === 'boolean') {
                this.isPrimitive = true;
            } else {
                if (this.currentData instanceof Array) {
                    this.isArray = true;

                    // If the array is empty, prevent the user having to navigate through it to find out.
                    // This is to reduce the amount of unnecessary mouse clicks.
                    if (this.currentData.length === 0) {
                        this.setIsNullOrEmpty();
                    }
                } else {
                    this.isObject = true;
                    if (Object.keys(this.currentData).length === 0) {
                        this.setIsNullOrEmpty();
                    }
                }
            }
        }

        if (!this.isHidden && this.currentSchema) {
            if (this.currentSchema.action !== undefined) {
                this.definedAction = this.currentSchema.action as DrillDownAction;
            } else if (this.currentSchema.linkableIdType !== undefined) {
                const formattedId = formatIdForUrl(String(this.currentData));
                this.linkUrl = generateUrl(this.currentSchema.linkableIdType, formattedId, this.currentConfig.globalVariables);
            }
        }

        if (this.currentSchema) {
            this.idName = this.currentSchema.name;
            this.isExpandedByDefault = !this.isPrimitive && this.currentSchema.isExpanded;
        } else if (this.name) {
            this.idName = this.name.replace(' ', '-');
        }

        if (!this.name) {
            if (this.currentSchema && this.currentSchema.name) {
                this.name = TransformationUtils.transformPropertyName(this.currentSchema.name);
            } else {
                throw new Error(`Property doesn't contain a valid name!`);
            }
        }
    }

    /**
     * Execute a declared custom action.
     */
    public executeAction(): void {
        if (!this.definedAction) {
            throw new Error(`Action was undefined!`);
        }

        this.definedAction(
            this.currentData,
            this.currentLineage,
            this.navStateService.allNavStates,
            this.navStateService.injector,
            this.currentConfig ? this.currentConfig.globalVariables : null
        );
    }

    private setIsNullOrEmpty(): void {
        this.isNullOrEmpty = true;
        this.isHidden =
            !this.currentSchema ||
            this.currentSchema.hideIfNullOrUndefined ||
            this.currentSchema.isHidden ||
            (this.currentConfig && this.currentConfig.hideAllIfNull);
    }
}
