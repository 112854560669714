import { AllTermsOfUseActions, FAILED, INITIALIZE, PROMPT_USER, SEARCH, SUCCESS, USER_CONSENTED } from './terms-of-use.actions';
import { TermsOfUseState } from './terms-of-use.state';

const initialState: TermsOfUseState = {
    isComplete: false,
    userHasConsented: false,
};

export function reducer(state: TermsOfUseState, action: AllTermsOfUseActions): TermsOfUseState {
    if (!state) {
        state = initialState;
    }

    switch (action.type) {
        case INITIALIZE:
            return {
                ...state,
                path: action.path,
                query: action.query,
                extras: action.extras,
            };
        case PROMPT_USER:
            /**
             * This is considered a deferred state, which means that the TermsOfUse guard
             * will consider it's request completed and that the user hasn't consented.
             */
            return {
                ...state,
                isComplete: true,
                userHasConsented: false,
                key: action.key,
            };
        case USER_CONSENTED:
            return {
                ...state,
                isComplete: false,
                userHasConsented: true,
            };
        case SEARCH:
            return {
                ...state,
            };
        case SUCCESS:
            return {
                isComplete: true,
                userHasConsented: true,
            };
        case FAILED:
            return {
                ...state,
                isComplete: true,
                userHasConsented: false,
            };
        default:
            return state;
    }
}
