import { Action } from '@ngrx/store';

export const INITIALIZE = '[Authentication] Initialize';
export const SEARCH = '[Authentication] Search';
export const SEARCH_COMPLETE = '[Authentication] Search Complete';

export type AllAuthenticationActions = AuthenticationSearchAction | AuthenticationCompleteAction | AuthenticationInitializeAction;

export class AuthenticationInitializeAction implements Action {
    readonly type = INITIALIZE;

    constructor() {}
}

export class AuthenticationSearchAction implements Action {
    readonly type = SEARCH;

    constructor() {}
}

export class AuthenticationCompleteAction implements Action {
    readonly type = SEARCH_COMPLETE;

    constructor(public username: string, public fullyQualifiedUsername: string, public isAuthenticated: boolean) {}
}
