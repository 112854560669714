/**
 * The rendering state of the DrillDownViewer.
 */
export enum DrillDownState {
    /**
     * Displays only the list of selectable items in tabular form.
     */
    Summary,

    /**
     * This displays both the list of selectable items and the item that's currently selected.
     */
    ShowingParentList,

    /**
     * This displays only the item that's currently selected.
     */
    HidingParentList,
}
