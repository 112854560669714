import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DrillDownViewerComponent } from '../../shared-components/drill-down-viewer/drill-down-viewer/drill-down-viewer.component';
import { DrillDownState } from '../../shared-components/drill-down-viewer/models/drill-down-state.enum';
import { DrillDownStateService } from '../../shared-components/drill-down-viewer/service/drill-down-state.service';
import { DrillDownConfig } from '../drill-down-viewer/models/drill-down-config';
import { DrillDownArraySchema } from '../drill-down-viewer/models/schemas/drill-down-array-schema';

@Component({
    selector: 'mast-shared-subject-page',
    templateUrl: './shared-subject-page.component.html',
    styleUrls: ['./shared-subject-page.component.css'],
    providers: [DrillDownStateService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedSubjectPageComponent implements OnDestroy, OnChanges {
    @Input()
    public subjectName: string;
    @Input()
    public isLoading = false;
    @Input()
    public wasSuccessful = false;
    @Input()
    public data: any[];
    @Input()
    public config: DrillDownConfig;
    @Input()
    public schema: DrillDownArraySchema;
    @Input()
    public preViewer: any;
    @Input()
    public postViewer: any;
    @Input()
    public tablePaginatorLeft: any;
    @Input()
    public tablePaginatorRight: any;
    @Input()
    public tableLoading = false;
    @Input()
    public tableCanFetchMoreData = false;
    @Output()
    public refreshDataRequestEvent = new EventEmitter<void>();
    public viewerState: DrillDownState;
    jsonViewerFullscreen = false;

    constructor(private ref$: ChangeDetectorRef) {}

    @ViewChild(DrillDownViewerComponent)
    private drillDownViewer: DrillDownViewerComponent;

    @ViewChild('jsonViewer', { read: MatDrawer })
    public jsonViewer: MatDrawer;

    public ngOnChanges(changes: SimpleChanges): void {
        this.ref$.markForCheck();
    }

    public ngOnDestroy(): void {
        this.refreshDataRequestEvent.complete();
        this.refreshDataRequestEvent.unsubscribe();
    }

    public refreshData(/*origin: string*/): void {
        this.refreshDataRequestEvent.emit();
    }

    /**
     * Process all requests to alter the viewer's state of the Drill Down Viewer Component.
     */
    public processViewerStateChange(): void {
        if (this.drillDownViewer) {
            this.drillDownViewer.toggleVisibilityState();
        }
    }

    /**
     * Processes any change in state from the Drill Down Viewer Component.
     * @param viewerState
     */
    public viewerStateChanged(viewerState: DrillDownState) {
        this.viewerState = viewerState;
    }

    /**
     * Used to toggle the JsonViewer.
     */
    public toggleJsonViewer(): void {
        this.jsonViewer.toggle();
        this.ref$.markForCheck();
    }

    /**
     * Used to toggle the JsonViewer fullscreen.
     */
    public toggleJsonViewerFullScreen(): void {
        this.jsonViewerFullscreen = !this.jsonViewerFullscreen;
        this.ref$.markForCheck();
    }

    get jsonViewerClass() {
        return this.jsonViewerFullscreen ? 'mast-json-viewer-fullscreen' : 'mast-json-viewer';
    }
}
