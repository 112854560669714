import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { combineLatest, filter, map } from 'rxjs/operators';
import { AppState } from './app.states';
import { SearchStatus } from './models/search-criteria-values';
import { LookupContextService } from './shared-services/lookup/lookup-context/lookup-context.service';

@Component({
    selector: 'mast-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
    public isLoading: Observable<boolean>;
    public isInitializing: Observable<boolean>;
    public userCleared: Observable<boolean>;
    private searchCompleteSubscription: Subscription;

    constructor(
        private lookupContextService: LookupContextService,
        private appState: Store<AppState>,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.titleService.setTitle('MAST');
        this.isInitializing = this.appState.pipe(
            select((x) => x.authenticationState.isComplete),
            combineLatest(this.appState.select((x) => x.authorizationState.isComplete)),
            map(([authenticationComplete, authorizationComplete]) => !authenticationComplete || !authorizationComplete)
        );
        this.userCleared = this.appState.pipe(
            select((x) => x.authenticationState),
            combineLatest(this.appState.select((x) => x.authorizationState)),
            filter(([authenticationState, authorizationState]) => authenticationState.isComplete && authorizationState.isComplete),
            map(([authenticationState, authorizationState]) => authenticationState.isAuthenticated && authorizationState.isAuthorized)
        );
        this.isLoading = this.lookupContextService.searchComplete.pipe(
            map((searchCriteriaValues) => searchCriteriaValues.searchStatus === SearchStatus.SearchInProgress)
        );
    }

    ngOnInit() {
        const appTitle = this.titleService.getTitle();
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let child = this.activatedRoute.firstChild;
                    while (child.firstChild) {
                        child = child.firstChild;
                    }
                    if (child.snapshot.data['title']) {
                        return child.snapshot.data['title'];
                    }
                    return appTitle;
                })
            )
            .subscribe((ttl: string) => {
                this.titleService.setTitle(ttl);
            });
    }

    public ngOnDestroy(): void {
        if (this.searchCompleteSubscription) {
            this.searchCompleteSubscription.unsubscribe();
        }
    }
}
