import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { CookieModule } from 'ngx-cookie';
import { CorrelationVectorInterceptor } from './correlation-vector.interceptor';
import { BaseService } from './dal/base.service';
import { DebuggingInterceptor } from './debugging.interceptor';
import { DebuggingService } from './debugging.service';
import { ErrorHandlerService } from './error-handler.service';
import { JsllService } from './jsll.service';
import { LogService } from './log.service';
import { LookupContextService } from './lookup/lookup-context/lookup-context.service';
import { LookupGuard } from './lookup/lookup.guard';
import { NoopLookupService } from './lookup/noop-lookup.service';
import { MastRequestInterceptor } from './mast-request.interceptor';
import { SettingsService } from './settings.service';

@NgModule({
    imports: [CommonModule, HttpClientModule, CookieModule.forRoot()],
    providers: [
        SettingsService,
        JsllService,
        BaseService,
        DebuggingService,
        LookupGuard,
        LookupContextService,
        NoopLookupService,
        LogService,
        { provide: HTTP_INTERCEPTORS, useClass: MastRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CorrelationVectorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DebuggingInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
    ],
})
export class SharedServicesModule {}
