/**
 * All possible root paths in MAST, used to create the root RouterModule.
 */
export enum RootPath {
    Consumer = 'consumer',
    EnterpriseLegacy = 'enterprise/legacy',
    Tools = 'tools',
    ConsumerPurchase = 'consumerpurchase',
    Unauthorized = 'unauthorized',
}
