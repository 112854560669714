/**
 * All possible paths within the consumer routing module.
 * Used to create the consumer child RouterModule.
 */
export enum ConsumerSubpath {
    AccountProfile = ':environment/:puid/accountprofile',
    Devices = ':environment/:puid/devices',
    Orders = ':environment/:puid/orders',
    PaymentInstruments = ':environment/:puid/paymentinstruments',
    Flights = ':environment/:puid/flights',
    Family = ':environment/:puid/family',
    Subscriptions = ':environment/:puid/subscriptions',
    Entitlements = ':environment/:puid/entitlements',
}
