import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MastResult } from '../../models/dal/mast-result';
import { DebuggingService } from '../../shared-services/debugging.service';

@Component({
    selector: 'mast-debug',
    templateUrl: './debug.component.html',
    styleUrls: ['./debug.component.css'],
})
export class DebugComponent implements OnInit {
    readonly pageSize: number = 5;
    pagedExternalRequests: MastResult[];
    currentPage: number;
    private originalExternalRequests: MastResult[];

    constructor(public activeModal: NgbActiveModal, public debuggingService: DebuggingService) {}

    ngOnInit(): void {
        this.reset();
    }

    /**
     * Handles all page change requests.
     */
    pageChange(): void {
        const startIndex = this.pageSize * (this.currentPage - 1);
        const endIndex = startIndex + this.pageSize;
        this.pagedExternalRequests = this.debuggingService.logs.slice(startIndex, endIndex);
    }

    /**
     * Resets the debug logs.
     * TODO: We will need to make debuggingService.logs observable.
     */
    private reset(): void {
        this.originalExternalRequests = this.debuggingService.logs;
        this.currentPage = 1;
        this.pageChange();
    }
}
