import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormFieldBaseDirective } from '../form-field-base/form-field-base';

@Component({
    selector: 'mast-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['../form-field-base/form-field-base.css', './form-input.component.css'],
})
export class FormInputComponent extends FormFieldBaseDirective {
    constructor() {
        super();
    }

    trim() {
        const control: AbstractControl = this.form.get(this.controlName);
        if (control.value) {
            control.setValue(control.value.trim());
        }
    }
}
