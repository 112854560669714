import { EnterpriseTenantAndOrgIdStateParameters } from '../../models/enterprise-tenant-and-org-id-state.parameters';
import * as SM from '../../../models/state-manager/state-manager-search.actions';
import { MastResult } from '../../../models/dal/mast-result';

export const SEARCH = '[EnterpriseOrgIdProfile] Search';
export const REFRESH = '[EnterpriseOrgIdProfile] Refresh';
export const RESET = '[EnterpriseOrgIdProfile] Reset';
export const SUCCESSFUL = '[EnterpriseOrgIdProfile] Successful';
export const FAILED = '[EnterpriseOrgIdProfile] Failed';

export class OrganizationSearchAction implements SM.StateManagerSearchAction<EnterpriseTenantAndOrgIdStateParameters> {
    readonly type = SEARCH;
    constructor(public parameters: EnterpriseTenantAndOrgIdStateParameters) {}
}

export class OrganizationSearchRefreshAction implements SM.StateManagerSearchResetAction {
    readonly type = REFRESH;
    constructor() {}
}

export class OrganizationSearchResetAction implements SM.StateManagerSearchResetAction {
    readonly type = RESET;
    constructor() {}
}

export class OrganizationSearchSuccessfulAction implements SM.StateManagerSuccessfulAction<MastResult> {
    readonly type = SUCCESSFUL;
    constructor(public result: any) {}
}

export class OrganizationSearchFailedAction implements SM.StateManagerSearchFailedAction {
    readonly type = FAILED;
    constructor(public errorCode: string, public errorMessage) {}
}
