import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsumerAccountProfileSearchState } from './consumer-account-profile-search/consumer-account-profile-search.state';
import { ConsumerRootSearchState } from './consumer-root/consumer-root-search.state';
import { ConsumerStates } from './consumer.states';

export const consumerFeatureSelector = createFeatureSelector<ConsumerStates>('consumer');

export const selectConsumerRootState = createSelector(consumerFeatureSelector, (state: ConsumerStates) => state.consumerRoot);
export const selectConsumerAccountProfileState = createSelector(consumerFeatureSelector, (state: ConsumerStates) => state.consumerAccountProfile);

export const selectConsumerRootResults = createSelector(selectConsumerRootState, (state: ConsumerRootSearchState) => state.results);
export const selectConsumerAccountProfileResults = createSelector(
    selectConsumerAccountProfileState,
    (state: ConsumerAccountProfileSearchState) => state.results
);

export const selectConsumerRootStateSearchSuccessful = createSelector(selectConsumerRootState, (state: ConsumerRootSearchState) => state.searchSuccessful);
export const selectConsumerAccountProfileStateSearchSuccessful = createSelector(
    selectConsumerAccountProfileState,
    (state: ConsumerAccountProfileSearchState) => state.results
);

export const selectConsumerRootSearchCompleted = createSelector(selectConsumerRootState, (state: ConsumerRootSearchState) => state.searchCompleted);
export const selectConsumerAccountProfileSearchCompleted = createSelector(
    selectConsumerAccountProfileState,
    (state: ConsumerAccountProfileSearchState) => state.searchCompleted
);
