import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, MonoTypeOperatorFunction, Observable, of, OperatorFunction, throwError as _throw } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, concat, map, retryWhen, switchMap, take, timeout } from 'rxjs/operators';
import { generateCommonHeaderConfiguration } from '../../utils/service-tools';

export interface MaxRetryError {
    status: string;
    error: string;
}
export interface RoleInfo {
    description: string;
    link: string;
}

export enum AuthorizationRole {
    ConsumerAccess = 'Consumer.Access',
    EnterpriseAccess = 'Enterprise.Access',
    ImeiAccess = 'Imei.Access',
    GreenOrdersAccess = 'GreenOrders.Access',
    PrivacyTraining = 'Privacy.101.Check',
    MastUsers = 'MAST.Users',
    ConsumerPurchaseAccess = 'TM.CCPurchase',
}

/**
 * This service is used to authorize a user for clearance to use MAST.
 */
@Injectable()
export class AuthorizationService {
    constructor(private http: HttpClient) {}
    public static readonly TimeUntilRetry = 10000; // in milliseconds
    public static readonly Retries = 5;
    public static readonly roleInfos: { [role in AuthorizationRole]: RoleInfo } = {
        [AuthorizationRole.ConsumerAccess]: {
            description: 'Consumer Section Access',
            link: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-platform-experiences/consumer-purchase-subscriptions/mast-support-tool/mast/onboard/access#consumer-section-access',
        },
        [AuthorizationRole.EnterpriseAccess]: {
            description: 'Enterprise Section Access',
            link: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-platform-experiences/consumer-purchase-subscriptions/mast-support-tool/mast/onboard/access#enterprise-section-access',
        },
        [AuthorizationRole.ImeiAccess]: {
            description: 'Imei Section Access',
            link: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-platform-experiences/consumer-purchase-subscriptions/mast-support-tool/mast/onboard/access#imei-access',
        },
        [AuthorizationRole.GreenOrdersAccess]: {
            description: 'Green Orders Section Access',
            link: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-platform-experiences/consumer-purchase-subscriptions/mast-support-tool/mast/onboard/access#green-orders-access',
        },
        [AuthorizationRole.PrivacyTraining]: {
            description: 'Privacy 101 Training and CPMT Project',
            link: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-platform-experiences/consumer-purchase-subscriptions/mast-support-tool/mast/onboard/access#privacy-101-training-and-wamt-business-role',
        },
        [AuthorizationRole.MastUsers]: {
            description: 'MAST Users Security Group',
            link: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-platform-experiences/consumer-purchase-subscriptions/mast-support-tool/mast/onboard/access#mast-users-security-group',
        },
        [AuthorizationRole.ConsumerPurchaseAccess]: {
            description: 'Consumer Purchase Section Access',
            link: '',
        },
    };

    // Translates the boolean / Error emitted by the source observable into an array of RoleInfos.
    // true => empty array
    // false => throws Error
    // HttpErrorResponse containing AuthorizationRole[]
    // Other Error => rethrows the Error
    private static handleVerificationRequestObservable(serviceName: string): OperatorFunction<boolean, AuthorizationRole[]> {
        return (source: Observable<boolean>) =>
            source.pipe(
                AuthorizationService.retryOnTimeout(serviceName),
                map((verified) => {
                    if (verified) {
                        return [] as AuthorizationRole[];
                    }
                    throw new Error(`${serviceName} returned false. (This should never happen).`);
                }),
                catchError((error) => AuthorizationService.getAuthorizationRolesOrThrow$(error))
            );
    }

    // Resubscribes (retries) on the source observable a set number of times if it doesn't emit any values for a set
    // duration.
    private static retryOnTimeout<T>(serviceName: string): MonoTypeOperatorFunction<T> {
        return (source: Observable<T>) =>
            source.pipe(
                timeout(AuthorizationService.TimeUntilRetry),
                retryWhen((errors) =>
                    errors.pipe(
                        switchMap((error) => {
                            if (error.status > 499 || error.name === 'TimeoutError') {
                                // Retry, the returned observable doesn't matter
                                return of(null);
                            } else {
                                // Stop retrying and bubble up the error
                                return _throw(error);
                            }
                        }),
                        take(AuthorizationService.Retries),
                        concat(_throw(new Error(`Sent ${AuthorizationService.Retries + 1} requests to ${serviceName}, ` + `but they timed out.`)))
                    )
                )
            );
    }

    // Returns an observable that emits the array of AuthorizationRoles in the given error if it exists,
    // otherwise rethrows the given error.
    private static getAuthorizationRolesOrThrow$(error: any): Observable<AuthorizationRole[]> | ErrorObservable<any> {
        if (
            error instanceof HttpErrorResponse &&
            error.status === 403 &&
            Array.isArray(error.error) &&
            error.error.every((role) => Object.values(AuthorizationRole).includes(role))
        ) {
            return of(error.error as AuthorizationRole[]);
        }
        return _throw(error);
    }

    /**
     * Verifies that the user fulfills all the requirements to access MAST.
     *
     * The observable returned emits an array where each element represents a failed requirement. Each failed
     * requirement is represented by an array of RoleInfos, where the user must have at least one of the roles to
     * fulfill the requirement.
     *
     * If the user is authorized, the array emitted is empty.
     */
    public verifyUser(): Observable<AuthorizationRole[]> {
        return forkJoin(
            // Verify that the user has access to at least one MAST section.
            this.verify('verifySectionAccess').pipe(AuthorizationService.handleVerificationRequestObservable('verifySectionAccess')),
            // Verify that the user has access to one of the valid MAST Users security groups.
            this.verify('verifyMastUserSG').pipe(AuthorizationService.handleVerificationRequestObservable('verifyMastUserSG')),
            // Verify that the user has completed the privacy training and is part of one of the OSG projects on CPMT.
            this.verify('verifyPrivacyTraining').pipe(AuthorizationService.handleVerificationRequestObservable('verifyPrivacyTraining'))
        ).pipe(
            // Filter out empty inner arrays
            map((missingRequirements) => {
                return [].concat(...missingRequirements);
            })
        );
    }

    public verifyAccessToEnterpriseSection(): Observable<boolean> {
        return this.verify('verifyAccessToEnterpriseSection').pipe(catchError(() => of(false)));
    }

    public verifyAccessToConsumerSection(): Observable<boolean> {
        return this.verify('verifyAccessToConsumerSection').pipe(catchError(() => of(false)));
    }

    public verifyAccessToImeiSection(): Observable<boolean> {
        return this.verify('verifyAccessToImeiSection').pipe(catchError(() => of(false)));
    }

    public verifyAccessToGreenOrdersSection(): Observable<boolean> {
        return this.verify('verifyAccessToGreenOrdersSection').pipe(catchError(() => of(false)));
    }

    public verifyAccessToConsumerPurchaseSection(): Observable<boolean> {
        return this.verify('verifyAccessToConsumerPurchaseSection').pipe(catchError(() => of(false)));
    }

    private verify(serviceName: string): Observable<boolean> {
        return this.http.get<boolean>(`/api/security/${serviceName}`, {
            headers: generateCommonHeaderConfiguration(serviceName, true, false),
        });
    }
}
