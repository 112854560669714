import { Type } from '@angular/core';

import { DrillDownCustomMenu } from '../drill-down-custom-menu';
import { DrillDownObjectSchema } from './drill-down-object-schema';
import { DrillDownPropertySchema } from './drill-down-property-schema';

/**
 * Represents an array's schema for rendering by the DefaultArrayViewer or the DrillDownViewer.
 */
export interface DrillDownArraySchema {
    /**
     * This indicates that DefaultArrayViewer will only display properties that were explicitly provided in the propertiesToShow parameter.
     */
    showOnlyPropertiesIfDeclared?: boolean;

    /**
     * Schemas of each property that is to be displayed by the DefaultArrayViewer or DrillDownViewer.
     */
    propertiesToShow: DrillDownPropertySchema[];

    /**
     * The schema that represents each array element.  This will eventually be used by the DefaultObjectViewer.
     */
    elementSchema: DrillDownObjectSchema;

    /**
     * This indicates that the first element of the array should be selected if the array contains one element by default.
     */
    focusOnOnlyElement?: boolean;

    /**
     * The interface a component must implement in order to be used as a Custom Menu.
     */
    customMenuComponent?: Type<DrillDownCustomMenu>;
}

/** Default values for a DrillDownArraySchema. */
export const DrillDownArraySchemaDefaults: DrillDownArraySchema = {
    showOnlyPropertiesIfDeclared: false,
    focusOnOnlyElement: false,
    propertiesToShow: [],
    elementSchema: null,
    customMenuComponent: null,
};
