import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DrillDownState } from '../drill-down-viewer/models/drill-down-state.enum';
import { DrillDownStateService } from '../drill-down-viewer/service/drill-down-state.service';

@Component({
    selector: 'mast-shared-header',
    templateUrl: './shared-header.component.html',
    styleUrls: ['./shared-header.component.css'],
})
export class SharedHeaderComponent implements OnInit, OnDestroy, OnChanges {
    public drillDownStateOptions: typeof DrillDownState = DrillDownState;
    public permalink: string;
    public viewerStateTitle: string;

    /**
     * The only construtor.  All parameters must be injectable.
     */
    constructor(private navService: DrillDownStateService) {}

    @Input()
    public showDrillDownNavigationBar = true;
    /**
     * The desired name for the content submenu.
     */
    @Input()
    public title: string;

    /**
     * The identifier for the submenu instance in question.
     */
    @Input()
    public id: string;

    @Input()
    public viewerState: DrillDownState;

    @Input()
    public enableJsonViewer: boolean;

    /**
     * This is used to hook the components refreshData event handler with the EventEmitter.
     */
    @Output()
    refreshEvent: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    titleClickedEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    viewerStateChangedEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    jsonViewerStateChangedEvent: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Initializes all subscruptions and variables.
     */
    public ngOnInit(): void {
        if (!this.id) {
            throw Error(`The id property wasn't provided!`);
        }

        if (!this.title) {
            this.title = this.id;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['viewerState']) {
            if (this.viewerState === DrillDownState.ShowingParentList) {
                this.viewerStateTitle = 'Hide List';
            } else if (this.viewerState === DrillDownState.HidingParentList) {
                this.viewerStateTitle = 'Show List';
            }
        }
    }

    /**
     * Performs the necessary cleanup.
     */
    public ngOnDestroy(): void {}

    /**
     * This actuates the data refreshing process.
     */
    public refreshData(): void {
        this.refreshEvent.emit(this.id);
    }

    public toggleJsonViewer(): void {
        this.jsonViewerStateChangedEvent.emit();
    }

    /**
     * This alters the viewer to the root navigational state.
     */
    public titleClicked(): void {
        this.navService.setToRoot();
        this.titleClickedEvent.emit();
    }

    /**
     * This actuates the view state change process.
     */
    public viewerStateChangeRequestEvent(): void {
        this.viewerStateChangedEvent.emit();
    }
}
