import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AppState } from '../app.states';
import { WindowInjectionToken } from '../models/window-proxy';
import { SettingsService } from '../shared-services/settings.service';
import { AuthenticationComponent } from './authentication.component';
import { AuthenticationInitializeAction } from './services/authentication.actions';
import { AuthenticationState } from './services/authentication.state';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanLoad {
    private authenticationState: Observable<AuthenticationState>;
    constructor(
        private router: Router,
        private authStore: Store<AppState>,
        private settingsService: SettingsService,
        @Inject(WindowInjectionToken) private window: Window
    ) {
        this.authenticationState = this.authStore.pipe(
            select((result) => result.authenticationState),
            filter((x) => x.isComplete)
        );
    }

    public canLoad(route: Route): Observable<boolean> {
        this.authStore.dispatch(new AuthenticationInitializeAction());
        this.setLoadingUrl();
        return this.authenticationState.pipe(
            first(),
            map((authState) => {
                return authState.isAuthenticated;
            })
        );
    }

    /**
     * This ensure that the callback is handled and the intended component is activated when all is complete.
     * @param next The snapshot.
     * @param state The current router state.
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.authStore.dispatch(new AuthenticationInitializeAction());
        if (next.component === AuthenticationComponent) {
            return this.authenticationState.pipe(
                first(),
                map((authState) => {
                    if (authState.isAuthenticated) {
                        let returnUrl = next.queryParams['returnUrl'];
                        if (!returnUrl) {
                            returnUrl = this.settingsService.getItemFromApplicationStorage('msal.login.request', '');

                            this.router.navigateByUrl(this.getReturnUrlSegment(returnUrl));
                        } else {
                            this.router.navigate([this.getReturnUrlSegment(returnUrl)], {
                                queryParams: next.queryParams,
                            });
                        }

                        // In this case, we don't want to see that AuthenticationComponent,
                        // since we're already authenticated.
                        return false;
                    }

                    // In this case, we don't want to see that AuthenticationComponent,
                    // since we're already authenticated.
                    return true;
                })
            );
        } else {
            return this.authenticationState.pipe(
                first(),
                map((authState) => {
                    return authState.isAuthenticated;
                })
            );
        }
    }

    /**
     * Retrieves the segment portion of the url that's applicable to Angular.
     * @param returnUrl The fully qualified URL.
     * @returns {string} The segment portion of the url that's applicable to Angular
     */
    private getReturnUrlSegment(returnUrl: string): string {
        if (returnUrl) {
            const root = this.window.location.origin + this.window.location.pathname;
            if (returnUrl.indexOf(root) > -1) {
                returnUrl = returnUrl.substring(root.length);
                if (returnUrl) {
                    const hashCharacter = returnUrl[0];
                    if (hashCharacter === '#') {
                        returnUrl = returnUrl.substring(1);
                    }
                }
            }
        }

        return returnUrl;
    }

    private setLoadingUrl() {
        const loadingUrl = '/' + window.location.hash;
        localStorage.setItem(`redirectUrl`, loadingUrl);
    }
}
