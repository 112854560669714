import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'mast-loader-status',
    templateUrl: './loader-status.component.html',
    styleUrls: ['./loader-status.component.css'],
})
export class LoaderStatusComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public isActive = true;

    private isReady = false;
    private INDETERMINATE = 'indeterminate';
    private DETERMINATE = 'determinate';
    public mode = this.INDETERMINATE;
    public diameter = 250;

    constructor() {}

    public ngOnInit() {
        this.handleAnimation();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.processActivity();
    }

    public ngOnDestroy(): void {
        if (this.isReady) {
            this.mode = this.DETERMINATE;
        }
    }

    /**
     * Receiveds that Animation
     * @param anim
     */
    public handleAnimation() {
        this.isReady = true;
        this.processActivity();
    }

    private processActivity(): void {
        if (this.isReady) {
            if (this.isActive) {
                this.mode = this.INDETERMINATE;
            } else {
                this.mode = this.DETERMINATE;
            }
        }
    }
}
