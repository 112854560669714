import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';
import { AppState } from '../../app.states';
import { ApplicationContextItem } from '../../models/application-context-item';
import { SearchCriteriaIdentifier } from '../../models/search-criteria-identifier';
import { SearchCriteriaValues, SearchStatus } from '../../models/search-criteria-values';
import { SearchItem } from '../../models/search-item';
import { LogService } from '../../shared-services/log.service';
import { LookupContextService } from '../../shared-services/lookup/lookup-context/lookup-context.service';

/**
 * This is the primary search component found at the center of the new MAST application.
 */
@Component({
    selector: 'mast-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    public searchGroup: FormGroup;
    public currentIdentifiers: SearchCriteriaIdentifier[];
    public isVisible = true;
    public isConsumerAccessAuthorized: Observable<boolean>;
    public isEnterpriseAccessAuthorized: Observable<boolean>;
    private searchSubscription: Subscription;

    constructor(
        private appState$: Store<AppState>,
        private lookupContextService: LookupContextService,
        private fb: FormBuilder,
        private router: Router,
        private ref: ChangeDetectorRef,
        private logService: LogService
    ) {
        this.isEnterpriseAccessAuthorized = this.appState$.select((x) => x.authorizationState.isEnterpriseAuthorized);
        this.isConsumerAccessAuthorized = this.appState$.select((x) => x.authorizationState.isConsumerAuthorized);
        this.searchGroup = fb.group({});
    }

    public get applicationContext(): ApplicationContextItem {
        return this.lookupContextService.applicationContext;
    }

    @Input()
    public set applicationContext(value: ApplicationContextItem) {
        if (value && value !== this.applicationContext) {
            this.lookupContextService.applicationContext = value;
            this.isVisible = this.lookupContextService.isValidService;
            if (this.isVisible) {
                if (!this.searchItem || !this.lookupContextService.applicationContext.primarySearchTypes.some((x) => x === this.searchItem)) {
                    this.searchItem = value.primarySearchTypes[0];
                } else {
                    // Reset the Form Group.
                    this.buildForm(this.searchItem);
                }
            }
        }
    }

    public set searchItem(value: SearchItem) {
        if (value) {
            this.buildForm(value);
            this.lookupContextService.searchItem = value;
            this.searchCriteriaIdentifiers.method = value.method;
            this.ref.markForCheck();
        }
    }

    public get searchItem(): SearchItem {
        return this.lookupContextService.searchItem;
    }

    public get searchCriteriaIdentifiers(): SearchCriteriaValues {
        return this.lookupContextService.searchCriteria;
    }

    public searchTypeChanged(): void {
        const searchTypeComponent = this.searchGroup.controls['searchType'];
        if (searchTypeComponent) {
            const searchItem = searchTypeComponent.value;
            this.buildForm(searchItem);
            this.logService.logEvent(`Search Item: ${searchItem.name}`);
        }
    }

    // Used to disable input in the search bar depending on user's access to specific sections.
    public isUserAuthorized(): Observable<boolean> {
        if (
            this.lookupContextService.applicationContext &&
            (this.lookupContextService.applicationContext.name === 'Enterprise' || this.lookupContextService.applicationContext.name === 'Modern Commerce')
        ) {
            return this.isEnterpriseAccessAuthorized;
        } else if (this.lookupContextService.applicationContext && this.lookupContextService.applicationContext.name === 'Consumer') {
            return this.isConsumerAccessAuthorized;
        }

        return null;
    }

    public ngOnInit(): void {
        this.searchSubscription = this.lookupContextService.searchComplete
            .pipe(skipWhile((searchCriteria) => !searchCriteria))
            .subscribe((searchCriteria) => this.navigate(searchCriteria));
    }

    public ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
    }

    /**
     * Invokes the filter lookup process.
     */
    public performSearch(): void {
        if (this.transposeForm()) {
            // Log events
            this.logEvents();

            this.lookupContextService
                .performSearch(true)
                .pipe(
                    skipWhile((x) => x.searchStatus === SearchStatus.Idle),
                    first()
                )
                .subscribe();
        }
    }

    public normalize(originalValue: string): string {
        if (originalValue) {
            return originalValue.replace(' ', '-');
        }

        return originalValue;
    }

    protected buildForm(searchItem: SearchItem) {
        const newSearchGroup: TempSearchType = new TempSearchType();

        if (searchItem && this.applicationContext) {
            const identifiers = searchItem.identifiers;
            const searchGroupData = this.searchGroup ? this.searchGroup.value : null;
            // Don't rebuild if nothing has changed.
            if (searchGroupData && searchGroupData.searchType && identifiers && Object.keys(searchGroupData).length === identifiers.length + 1) {
                const allIdentifiersFound = identifiers.every((x) => searchGroupData[x.value] !== undefined && searchGroupData[x.value] !== null);
                if (allIdentifiersFound) {
                    return;
                }
            }
            const criteria = this.searchCriteriaIdentifiers;
            newSearchGroup.searchType = searchItem;
            if (identifiers) {
                identifiers.forEach((identifier) => {
                    let criteriaValue: any = '';
                    if (criteria) {
                        criteriaValue = criteria[identifier.value];
                    }

                    newSearchGroup[identifier.value] = criteriaValue;
                });
            }
        }

        this.searchGroup = this.fb.group(newSearchGroup);
        this.currentIdentifiers = searchItem ? searchItem.identifiers : [];
    }

    protected transposeForm(): boolean {
        if (this.searchGroup) {
            const searchGroupData = this.searchGroup.value;
            const { puid, searchType } = searchGroupData;
            if (puid) {
                const msaArray = puid.toLowerCase().split('msa:');
                if (msaArray.length > 1) {
                    // allow puid value with msa: prefix + doing an early .trim to better update the form value for the user.
                    searchGroupData.puid = msaArray.at(-1).trim(); 
                    this.searchGroup.setValue({ puid: searchGroupData.puid, searchType });
                }
            }
            this.searchItem = searchGroupData['searchType'];

            Object.keys(this.searchGroup.controls).forEach((x) => {
                this.searchCriteriaIdentifiers[x] = searchGroupData[x];
            });

            return true;
        }

        return false;
    }

    protected navigate(searchCriteria: SearchCriteriaValues) {
        if (this.searchItem && searchCriteria) {
            this.buildForm(this.searchItem);
            if (searchCriteria.searchStatus === SearchStatus.SearchSuccessful) {
                if (searchCriteria.userInvoked) {
                    const populateUrl = this.lookupContextService.transformLink(this.searchItem.routerLink);
                    const populateQueryParams = this.lookupContextService.populateQueryParams(this.searchItem.queryParams);
                    this.router.navigate([populateUrl], { queryParams: populateQueryParams });
                }
            } else if (searchCriteria.searchStatus === SearchStatus.SearchFailed) {
                let url = '/';
                if (this.applicationContext.url) {
                    url = this.applicationContext.url;
                }

                this.router.navigate([url]);
            }
        }

        this.ref.markForCheck();
    }

    private logEvents() {
        if (this.searchGroup && this.searchGroup.value && this.searchGroup.value['searchType']) {
            let contentName: string = null;
            const method = this.searchGroup.value['searchType'].method;
            // Log Consumer Service
            if (this.searchGroup.value['searchType'].service === 'ConsumerRootService') {
                if (method === 'getMSA' || method === 'getMSAfromPuidHex') {
                    if (this.isPuidInput(this.searchGroup.value['puid'])) {
                        contentName = 'ConsumerPuidLookup';
                    } else if (this.isEmailInput(this.searchGroup.value['puid'])) {
                        contentName = 'ConsumerMSALookup';
                    }
                } else if (method === 'getMSAfromXuid') {
                    contentName = 'ConsumerXuidLookup';
                } else if (method === 'getMSAfromGamerTag') {
                    contentName = 'ConsumerGamerTagLookup';
                }
            } else if (this.searchGroup.value['searchType'].service === 'EnterpriseAccountProfileService') {
                if (method === 'ByTenantAndOrg') {
                    if (this.searchGroup.value['organizationId']) {
                        contentName = 'EnterpriseByTenantAndOrgIdLookup';
                    } else {
                        contentName = 'EnterpriseByTenantOnlyLookup';
                    }
                }
            }

            if (contentName) {
                this.logService.logEvent('CL', {
                    pageName: 'SearchTypes',
                    pageType: 'Lookup',
                    content: {
                        contentName,
                    },
                });
            }
        }
    }

    private isEmailInput(input): boolean {
        const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        return emailRegex.test(input);
    }

    private isPuidInput(input): boolean {
        const numberRegex = /^(p:)?[0-9]+$/;
        return numberRegex.test(input);
    }
}

export class TempSearchType {
    searchType: SearchItem;
    [key: string]: any;
}
