import { Component, Input } from '@angular/core';
import { FormFieldBaseDirective } from '../form-field-base/form-field-base';

@Component({
    selector: 'mast-form-datepicker',
    templateUrl: './form-datepicker.component.html',
    styleUrls: ['../form-field-base/form-field-base.css', './form-datepicker.component.css'],
})
export class FormDatepickerComponent extends FormFieldBaseDirective {
    @Input()
    min?: Date;
    @Input()
    max?: Date;

    constructor() {
        super();
    }
}
