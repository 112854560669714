<ng-container *ngIf="applicationContext && searchItem && searchGroup && isVisible">
    <form class="form-row" [formGroup]="searchGroup" (ngSubmit)="performSearch()" novalidate attr.aria-label="{{ applicationContext.name + ' search' }}">
        <ng-container *ngIf="applicationContext.primarySearchTypes && applicationContext.primarySearchTypes.length > 1">
            <div class="col-auto mr-sm-2">
                <select
                    placeholder="Search Type"
                    id="searchType"
                    name="searchType"
                    formControlName="searchType"
                    class="form-control"
                    (change)="searchTypeChanged()"
                    aria-label="Search type"
                >
                    <option *ngFor="let option of applicationContext.primarySearchTypes" [ngValue]="option">{{ option.name }}</option>
                </select>
            </div>
        </ng-container>
        <div *ngFor="let identifier of currentIdentifiers; let i = index" class="mr-sm-2" [class.col]="true">
            <div class="form-group">
                <input
                    class="form-control"
                    id="searchInput_{{ identifier.name }}"
                    [placeholder]="identifier.name"
                    [title]="identifier.description"
                    [formControlName]="identifier.value"
                    [attr.disabled]="(isUserAuthorized() | async) ? null : false"
                />
            </div>
        </div>
        <button
            class="btn btn-sm btn-outline-default my-2 my-sm-0"
            type="submit"
            title="Search"
            id="search"
            [attr.disabled]="(isUserAuthorized() | async) ? null : false"
        >
            <span class="generic-lookup-spyglass"></span>
        </button>
    </form>
</ng-container>
