import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MastJsonEditorWrapperModule } from '../../shared-components/mast-json-editor-wrapper/mast-json-editor-wrapper.module';
import { DebugExternalRequestComponent } from './debug-external-request/debug-external-request.component';
import { DebugRequestUnitComponent } from './debug-request-unit/debug-request-unit.component';
import { DebugResponseUnitComponent } from './debug-response-unit/debug-response-unit.component';
import { DebugComponent } from './debug.component';

@NgModule({
    imports: [CommonModule, NgbModule, MastJsonEditorWrapperModule],
    declarations: [DebugComponent, DebugExternalRequestComponent, DebugRequestUnitComponent, DebugResponseUnitComponent],
    entryComponents: [DebugComponent],
})
export class DebugModule {}
