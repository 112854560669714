import { routerReducer } from '@ngrx/router-store';
import * as AuthenticationReducer from './authentication/services/authentication.reducer';
import * as AuthorizationReducer from './authorization/services/authorization.reducer';
import * as TermsOfUseReducer from './terms-of-use/services/terms-of-use.reducers';

/**
 * This contains all of the Action Reducers for MAST's state manager.
 */
export const reducers = {
    authenticationState: AuthenticationReducer.reducer,
    authorizationState: AuthorizationReducer.reducer,
    termsOfUseState: TermsOfUseReducer.reducer,
    routerReducer: routerReducer,
};
