import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Observable } from 'rxjs';

import { SearchCriteriaValues } from '../../models/search-criteria-values';
import { applyParameterReplacement } from '../../utils/lookup-tools';

/**
 * The common class for all lookup search providers.
 */
export abstract class LookupService {
    /**
     * Resets the cached data and the state of the service.
     */
    abstract reset(): void;

    /**
     * Transforms the abstract link by populating the final lookup results;
     * @param link The abstract URL in question.
     * @returns {string} The fully populated URL.
     */
    abstract convertLink(link: string, searchCriteria: SearchCriteriaValues): string;

    /**
     * Populates all abstract query parameters. An abstract query parameter value is prefixed by ':'
     * @param queryParams The query parameters to populate
     * @param searchCriteria The search criteria values from which to find values to replace in queryParams
     */
    populateQueryParams(queryParams: Params, searchCriteria: SearchCriteriaValues): Params {
        if (queryParams == null || searchCriteria == null) {
            return queryParams;
        }
        Object.keys(queryParams).forEach((queryParamsKey: string) => {
            Object.keys(searchCriteria).forEach((searchCriteriaKey) => {
                queryParams[queryParamsKey] = applyParameterReplacement(
                    queryParams[queryParamsKey],
                    searchCriteriaKey,
                    searchCriteriaKey,
                    searchCriteria[searchCriteriaKey]
                );
            });
        });
        return queryParams;
    }

    /**
     * Executes the lookup search request.
     * @returns {SearchCriteriaValues} The fully populated search critera values.
     */
    abstract performLookup(searchCriteria: SearchCriteriaValues): Observable<SearchCriteriaValues>;

    /**
     * Transforms URL paramaters into SearchCriteriaValues.
     * @param params Url Parameters
     * @returns {SearchCriteriaValues} The fully populated search critera values.
     */
    abstract produceSearchCriteriaValues(snapshot: ActivatedRouteSnapshot): SearchCriteriaValues;
}
