<ng-container [ngSwitch]="isReady">
    <ng-container *ngSwitchCase="false">
        <mast-loader-status *ngIf="isOperational" [isActive]="!isReady && isOperational"></mast-loader-status>
        <div *ngIf="!isOperational">
            <h3>An error occurred and we're unable to fulfill your request at this time{{ errorMessage ? ': ' + errorMessage : '' }}</h3>
        </div>
    </ng-container>
    <div *ngSwitchDefault class="drill-down-viewer-pane">
        <div class="row" *ngIf="!navStateService.allNavStates || navStateService.allNavStates.length === 1">
            <mast-default-header
                [state]="currentState"
                [name]="currentNavState ? currentNavState.propertyName : ''"
                *ngIf="config.showDrillDownHeader"
                jsonViewerRequestEvent=""
            >
            </mast-default-header>
        </div>
        <div class="drill-down-main-viewer">
            <ng-template #drillDownCustomMenu></ng-template>
            <div
                class="selector-table"
                [@menuState]="viewState"
                [hidden]="hideSummaryPane"
                (@menuState.start)="animationStarted($event)"
                (@menuState.done)="animationDone(done)"
                [class.selector-table-expand]="currentState === drillDownStateOptions.Summary"
                [class.selector-table-split]="currentState === drillDownStateOptions.ShowingParentList"
                [class.selector-table-closed]="currentState === drillDownStateOptions.HidingParentList"
            >
                <p-table
                    id="array-view-grid"
                    [value]="displayableData"
                    #dt
                    dataKey="id"
                    [globalFilterFields]="filterFields"
                    selectionMode="single"
                    (onRowSelect)="selectionMade($event)"
                    [scrollable]="true"
                    [resizableColumns]="true"
                    [paginator]="true"
                    [rows]="25"
                    [showCurrentPageReport]="true"
                    [columnResizeMode]="'expand'"
                    responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    styleClass="p-datatable-gridlines"
                    [loading]="tableLoading"
                >
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                            <span class="p-input-icon-left p-ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th
                                *ngFor="let col of primitiveTableFields.value"
                                class="table-header"
                                pResizableColumn
                                pSortableColumn="{{ col.name }}"
                                style="width: 250px"
                            >
                                {{ col.title }}
                                <p-sortIcon field="{{ col.name }}"></p-sortIcon>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of primitiveTableFields.value">
                                <ng-template *ngIf="col.isBoolean; then booleanFilter; else normalFilter"></ng-template>
                                <ng-template #normalFilter>
                                    <p-columnFilter
                                        type="text"
                                        [field]="col.name"
                                        [matchMode]="'contains'"
                                        [showMenu]="false"
                                        placeholder="Filter {{ col.title }}"
                                        style="min-width: fit-content"
                                    ></p-columnFilter>
                                </ng-template>
                                <ng-template #booleanFilter>
                                    <p-columnFilter [field]="col.name" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown
                                                [ngModel]="value"
                                                [options]="[
                                                    { label: 'True', value: true },
                                                    { label: 'False', value: false }
                                                ]"
                                                placeholder="All"
                                                appendTo="body"
                                                (onChange)="filter($event.value)"
                                                [showClear]="true"
                                            >
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{ option.label }}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </ng-template>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex">
                            <td *ngFor="let col of primitiveTableFields.value" style="white-space: normal" class="ui-resizable-column">
                                {{ col.aggregationFunction 
                                    ? col.aggregationFunction(row)
                                    : col.name.indexOf('.') > 0
                                        ? comboAttribute(col.name,row)
                                        : row[col.name] }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns?.length">No records found</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="paginatorleft">
                        <ng-container [ngTemplateOutlet]="paginatorLeft"></ng-container>
                    </ng-template>
                    <ng-template pTemplate="paginatorright">
                        <ng-container [ngTemplateOutlet]="paginatorRight"></ng-container>
                    </ng-template>
                </p-table>
            </div>
            <div class="detail-view">
                <ng-container [ngSwitch]="detailsComponent != null">
                    <ng-template *ngSwitchCase="true" #detailView></ng-template>
                    <ng-container *ngSwitchDefault>
                        <ng-container [ngSwitch]="!currentSchema || !currentSchema.elementSchema || !currentSchema.elementSchema.useTabs">
                            <ng-container *ngSwitchCase="true">
                                <mast-default-object-viewer
                                    [data]="dataElement"
                                    [config]="config"
                                    [schema]="currentSchema ? currentSchema.elementSchema : null"
                                    [depth]="currentDepth"
                                ></mast-default-object-viewer>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mast-default-tabbed-viewer
                                    [data]="dataElement"
                                    [config]="config"
                                    [schema]="currentSchema ? currentSchema.elementSchema : null"
                                    [depth]="currentDepth"
                                    [tab]="tabName"
                                ></mast-default-tabbed-viewer>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
