<!-- The primary content navigation menu. -->
<div class="card menu-card" [style.height.px]="mainViewerHeight | async">
    <div class="menu-row">
        <div
            class="card bg-dark menu-panel"
            (@menuState.start)="animationStarted($event)"
            (@menuState.done)="animationDone(done)"
            [@menuState]="contextMenuDisplay ? 'active' : 'inactive'"
            *ngIf="(userCleared | async) && (termsSatisfied | async)"
        >
            <div class="card-header text-white menu-card-header">
                <button
                    class="btn navbar-toggler text-white"
                    [class.icon-menu-item-expand]="contextMenuDisplay"
                    (click)="menuChanged()"
                    [disabled]="disableResize"
                    id="resizeButton"
                    data-bi-area="MastSideNav"
                    aria-label="Toggle category names"
                >
                    <span class="expand-menu"></span>
                </button>
                <a
                    class="navbar-brand text-light"
                    *ngIf="showMenuText"
                    [routerLink]="contextMenuUrl"
                    [attr.data-bi-name]="contextMenuName + 'Home_Btn'"
                    data-bi-area="MastSideNav"
                    >{{ contextMenuName }}</a
                >
            </div>
            <div>
                <div class="nav flex-column nav-pills" *ngIf="navigationItems" id="v-pills-tab">
                    <ng-container -container v *ngFor="let navigationItem of navigationItems">
                        <button
                            *ngIf="navigationItem.routerLink; else nonRouterLink"
                            class="nav-link btn btn-dark btn-block text-left"
                            data-toggle="pill"
                            [class.disabled]="!isActive || navigationItem.isDisabled || !isSubjectVisible(navigationItem)"
                            [disabled]="!isActive || navigationItem.isDisabled || !isSubjectVisible(navigationItem)"
                            [class.text-white]="isActive"
                            routerLinkActive="active"
                            [routerLink]="!navigationItem.isDisabled && isSubjectVisible(navigationItem) ? standardize(navigationItem.routerLink) : 'disabled'"
                            [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: updateCount }"
                            data-bi-area="MastSideNav"
                            [attr.data-bi-name]="navigationItem.title + '_Btn'"
                            [attr.aria-label]="navigationItem.title"
                        >
                            <span
                                class="{{ navigationItem.iconClass }}"
                                [class.icon-menu-item-expand]="contextMenuDisplay"
                                *ngIf="navigationItem.iconClass"
                                title="{{ navigationItem.title }}"
                            ></span>
                            <span class="text-menu-item" *ngIf="showMenuText">{{ navigationItem.title }}</span>
                        </button>

                        <ng-template #nonRouterLink>
                            <button
                                class="nav-link btn btn-dark btn-block text-left"
                                [class.disabled]="!isActive || navigationItem.isDisabled"
                                [disabled]="!isActive || navigationItem.isDisabled"
                                [class.text-white]="isActive"
                                data-bi-area="MastSideNav"
                                (click)="processUrlRequest(navigationItem)"
                                [attr.data-bi-name]="navigationItem.title + '_Btn'"
                                [attr.aria-label]="navigationItem.title"
                            >
                                <span
                                    class="{{ navigationItem.iconClass }}"
                                    [class.icon-menu-item-expand]="contextMenuDisplay"
                                    *ngIf="navigationItem.iconClass"
                                    title="{{ navigationItem.title }}"
                                ></span>
                                <span class="text-menu-item" *ngIf="showMenuText">{{ navigationItem.title }}</span>
                            </button>
                        </ng-template>
                        <!--</ng-container>-->
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="work-panel" [@displayState]="!((userCleared | async) && (termsSatisfied | async)) ? 'unauth' : contextMenuDisplay ? 'active' : 'inactive'">
            <main class="tab-content" id="pills-tabContent">
                <router-outlet></router-outlet>
                <mast-footer></mast-footer>
            </main>
        </div>
    </div>
</div>
