<div class="card">
    <div class="card-header text-light common-menu-banner"><h3>Terms of Use</h3></div>
    <div class="card-body">
        <u>Overview</u>
        <p>
            The MAST tool gives you as a user access to customer data. This Terms of Use (ToU) document is intended to help ensure that customer data accessed
            via MAST is managed in accordance with Microsoft data security and privacy policies, as well as the data policies of the teams that provide data to
            MAST. In order to be able to access or continue to access data via MAST, you will need to accept this ToU.
        </p>

        <p>
            <u>Core Data Principles</u>
        </p>

        <p>
            Data consumed must follow the expected usage and management procedures outlined by Microsoft data security standards or policies, Microsoft privacy
            standards or policies, and CELA.
        </p>

        <p>
            All data must be used for purposes that align with Microsoft's privacy principles and policies. For more information on these policies, start at the
            <a href="https://xhub/sites/OSGEnS/GRC/OSGPrivacy/SitePages/Main.aspx" target="_blank">WDG Privacy Site</a>, where you can find your privacy contact
            and other resources for your privacy questions. For more information about asset classification and protection standards, please consult
            <a href="http://egrc" target="_blank">http://egrc</a>. You should also complete your privacy training in accordance with corporate guidelines.
        </p>

        <p>
            If the MAST team has reasonable cause to believe that you are using data obtained through MAST in ways that are outside of the corporate security
            and/or privacy guidelines, your access to MAST will be immediately revoked until the issue can be addressed.
        </p>

        <p>
            <u>Audit</u>
        </p>

        <p>
            MAST and/or its partner teams may at times be subject to external audit requests. We are logging MAST access and usage to be ready to comply with
            these audits if and as needed. If questions arise during an audit around your usage of MAST, you may be asked to participate in the audit response.
        </p>

        <p>
            <u>Terms of Use Acceptance</u>
        </p>

        <p>
            Please use the button below to accept this ToU. If you do not accept the ToU, you will not be granted access to use MAST. If you have any questions,
            please <a href="mailto:masthelp@microsoft.com">email</a> the MAST team. Thank you for treating our customers' data with respect."
        </p>
    </div>

    <div class="card-footer">
        <button data-bi-name="AcceptTermsOfUse" id="termsOfUseModalCloseBtn" class="btn" (click)="acceptTermsOfUse()">Accept</button>
    </div>
</div>
