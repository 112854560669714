import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EnvironmentServiceDetails } from '../../models/environment-service-details';
import { LogService } from '../../shared-services/log.service';
import { RegisteredServicesService } from '../../shared-services/registered-services.service';
import { SettingsService } from '../../shared-services/settings.service';

@Component({
    selector: 'mast-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'],
    providers: [RegisteredServicesService],
})
export class SettingsComponent implements OnInit, OnDestroy {
    public environments: Array<EnvironmentServiceDetails>;
    private _currentEnvironment: EnvironmentServiceDetails;
    private environmentsSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private settingsService: SettingsService,
        private registeredServices: RegisteredServicesService,
        private logService: LogService
    ) {}

    public ngOnDestroy(): void {
        this.environmentsSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        /**
         * Retrieves the current (in memory) value of the environment.
         */
        this.environmentsSubscription = this.registeredServices.getAllServicesByEnvironment().subscribe((env) => this.setEnvironments(env));
    }

    public get currentEnvironment(): EnvironmentServiceDetails {
        return this._currentEnvironment;
    }

    public set currentEnvironment(value: EnvironmentServiceDetails) {
        this._currentEnvironment = value;
        this.settingsService.environment = value.env;
        this.logService.logEvent(`Update Environment: ${value.env}`);
    }

    private setEnvironments(environmentMetadata: Array<EnvironmentServiceDetails>): void {
        this.environments = environmentMetadata;
        let env = this.settingsService.environment;
        if (!env) {
            env = 'Prod';
        }

        this._currentEnvironment = environmentMetadata.find((x) => x.env === env);
    }
}
