import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'mast-notification-banner',
    templateUrl: './notification-banner.component.html',
    styleUrls: ['./notification-banner.component.css'],
})
export class NotificationBannerComponent implements OnInit {
    @Input()
    public showNotificationBanner: boolean;

    @Input()
    public message: string;

    sanitizedMessage: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {}
    ngOnInit(): void {
        this.sanitizedMessage = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }
}
