import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable()
export class MastRequestInterceptor implements HttpInterceptor {
    constructor(private settingsService: SettingsService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url;
        const headers = req.headers;
        const mastRequest = headers.get('$mast-web-request');
        if (url && mastRequest) {
            const mastWebRequestUrl = `${this.settingsService.mastApiServiceUrl}${url}`;
            const newRequest = req.clone({ url: mastWebRequestUrl, headers: req.headers.delete('$mast-web-request') });
            return next.handle(newRequest);
        }

        return next.handle(req);
    }
}
