import { Component, Input, OnInit } from '@angular/core';
import { MastRequestTrace } from '../../../models/dal/mast-request-trace';
import { MastJsonEditorWrapperOptions } from '../../../models/mast-json-editor-wrapper/mast-json-editor-wrapper-options';

/**
 * Represents the request state of an external web service request.
 */
@Component({
    selector: 'mast-debug-request-unit',
    templateUrl: './debug-request-unit.component.html',
    styleUrls: ['./debug-request-unit.component.css'],
})
export class DebugRequestUnitComponent implements OnInit {
    public jsonViewerOptions: MastJsonEditorWrapperOptions = {
        mode: 'view',
        modes: ['code', 'view'], // allowed modes
        indentation: 4,
        escapeUnicode: true,
        onEditable: (node) => false,
        autocomplete: { getOptions: (text, path, input, editor) => null },
    };

    @Input()
    public requestTrace: MastRequestTrace;

    @Input()
    public accordionName: string;

    /**
     * The ordinal number in respect to the list of requests made.
     */
    @Input()
    public outerIndex: number;

    /**
     * The ordinal number in respect to the list of external requests made by the MAX Web API service.
     */
    @Input()
    public innerIndex: number;

    @Input()
    public hasErrored: boolean;

    /**
     * Request body as an object.
     */
    public body: any;

    public headers: any;

    public ngOnInit(): void {
        if (this.requestTrace && this.requestTrace.Body) {
            try {
                const newObject = JSON.parse(this.requestTrace.Body);
                if (newObject) {
                    this.body = newObject;
                }
            } catch (e) {
                // If JSON parsing fails, then proceed with displaying the original data.
                this.body = this.requestTrace.Body;
            }
        }

        // Format Request headers
        try {
            const newObject = JSON.parse(this.requestTrace.Headers);
            if (newObject) {
                // Parse TestHeaders
                if (newObject['x-ms-test']) {
                    newObject['x-ms-test'] = JSON.parse(newObject['x-ms-test']);
                }

                this.headers = newObject;
            }
        } catch (e) {
            this.headers = this.requestTrace.Headers;
        }
    }
}
