import { Inject, Injectable } from '@angular/core';
import { JSLL } from '@dna/jsll';
import { WindowInjectionToken } from '../models/window-proxy';
import { SettingsService } from './settings.service';

/**
 * A wrapper for the JSLL v4 library.
 */
@Injectable()
export class JsllService {
    private jsll: JSLL.awa;

    constructor(private settingsService: SettingsService, @Inject(WindowInjectionToken) private window: Window) {
        this.jsll = (window as any).awa;
        this.jsll.cv.init();
    }

    /**
     * Gets the name of the current page
     * @returns {string} Name of the page.
     */
    public get pageName(): string {
        let pageName = 'Home';
        if (this.window.location.href) {
            // Parse and set pagename from the window.location.Href attribute into #/(Consumer or Enterprise or Tools)/PageName
            const pathArray = this.window.location.href.split('/');
            if (pathArray.length > 5) {
                pageName = pathArray[3] + '/' + pathArray[4] + '/' + pathArray[5] + '/';
            }
        }

        return pageName;
    }

    /**
     * Gets the current instance of the correlation vector.
     * @returns {ICv} The correlation vector
     */
    public get correlationVector(): JSLL.Jsll.ICv {
        return this.jsll.cv;
    }

    public pageAction(overrideValues: JSLL.IOverrideValues): void {
        this.jsll.ct.capturePageAction(null, this.applyAdditionalChanges(overrideValues));
    }

    public pageView(overrideValues?: JSLL.IOverrideValues): void {
        this.jsll.ct.capturePageView(this.applyAdditionalChanges(overrideValues));
    }

    public contentUpdate(overrideValues?: JSLL.IOverrideValues): void {
        this.jsll.ct.captureContentUpdate(this.applyAdditionalChanges(overrideValues));
    }

    public contentPageAction(overrideValues?: JSLL.IOverrideValues): void {
        this.jsll.ct.captureContentPageAction(this.applyAdditionalChanges(overrideValues));
    }

    public setCoreData(pageProperties: JSLL.ICoreData): void {
        this.jsll.extendCoreData(pageProperties);
    }

    public webRequestComplete(eventData: JSLL.IQosOptions): void {
        this.jsll.vortexEvents.sendApiComplete(eventData);
    }

    private applyAdditionalChanges(overrideValues?: JSLL.IOverrideValues): JSLL.IOverrideValues {
        if (!overrideValues) {
            overrideValues = {} as JSLL.IOverrideValues;
        }

        overrideValues.uri = this.window.location.href;

        return overrideValues;
    }
}
