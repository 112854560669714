import { ApplicationContextItem } from './models/application-context-item';
import { toolsApplicationContextItems } from './tools/tools-application-context-item';
import { enterpriseApplicationContextItems } from './enterprise/enterprise-application-context-items';
import { consumerApplicationContextItems } from './consumer/consumer-application-context-item';
import { consumerPurchaseApplicationContextItems } from './consumerpurchase/consumerpurchase-application-context-item';

/**
 * All application contexts must be registered here.  Do not reference this const directly.
 * Please use the SettingsServer.getAllRegisteredApplicationContextItems() to retrieve these values.
 * If you reference this const, you run the risk of side-effects that may hinder the UX.
 */
export const applicationContextItems: Array<ApplicationContextItem> = [
    consumerApplicationContextItems,
    enterpriseApplicationContextItems,
    toolsApplicationContextItems,
    consumerPurchaseApplicationContextItems,
];
