import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from '../authentication/authentication.component';
import { TermsOfUseService } from './services/terms-of-use.service';
import { TermsOfUseComponent } from './terms-of-use.component';
import { TermsOfUseGuard } from './terms-of-use.guard';

const routes: Routes = [
    { path: 'termsOfUse', component: TermsOfUseComponent, data: { title: 'Terms Of Use' } },
    { path: 'auth', component: AuthenticationComponent, data: { title: 'Auth' } },
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [TermsOfUseComponent],
    providers: [TermsOfUseService, TermsOfUseGuard, AuthenticationComponent],
})
export class TermsOfUseModule {}
