import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../app.states';
import { extractErrorMessage } from '../../utils/utils';
import {
    AuthorizationConsumerAccessFailedAction,
    AuthorizationConsumerAccessSuccessAction,
    AuthorizationConsumerPurchaseAccessFailedAction,
    AuthorizationConsumerPurchaseAccessSuccessAction,
    AuthorizationEnterpriseAccessFailedAction,
    AuthorizationEnterpriseAccessSuccessAction,
    AuthorizationFailedAction,
    AuthorizationGetConsumerAction,
    AuthorizationGetConsumerPurchaseAction,
    AuthorizationGetEnterpriseAction,
    AuthorizationSuccessfulAction,
    GET_CONSUMER_ACCESS,
    GET_CONSUMER_PURCHASE_ACCESS,
    GET_ENTERPRISE_ACCESS,
    GET_RBAC,
    SEARCH,
} from './authorization.actions';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthorizationEffects {
    constructor(private actions$: Actions, private authStore$: Store<AppState>, private authService: AuthorizationService) {}

    search$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(SEARCH),
            switchMap((_) => this.authStore$),
            // If the authorization state is already complete, we don't need to do anything
            filter((appState) => !appState.authorizationState.isComplete),
            switchMap(() => this.authService.verifyUser()),
            map((missingRequirements) =>
                missingRequirements.length === 0 ? new AuthorizationSuccessfulAction() : new AuthorizationFailedAction(missingRequirements)
            ),
            // An unexpected error happened. Save it in string form to display it to the user.
            catchError((error) => of(new AuthorizationFailedAction([], extractErrorMessage(error))))
        )
    );

    getRbac$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_RBAC),
            switchMap((_) => this.authStore$),
            filter((appState) => {
                const {
                    isConsumerAuthComplete,
                    isConsumerAuthInProgress,
                    isConsumerPurchaseAuthComplete,
                    isConsumerPurchaseAuthInProgress,
                    isEnterpriseAuthComplete,
                    isEnterpriseAuthInProgress,
                } = appState.authorizationState;
                return (
                    !isConsumerAuthComplete &&
                    !isConsumerAuthInProgress &&
                    !isConsumerPurchaseAuthComplete &&
                    !isConsumerPurchaseAuthInProgress &&
                    !isEnterpriseAuthComplete &&
                    !isEnterpriseAuthInProgress
                );
            }),
            switchMap((appState) => {
                const {
                    isConsumerAuthComplete,
                    isConsumerAuthInProgress,
                    isConsumerPurchaseAuthComplete,
                    isConsumerPurchaseAuthInProgress,
                    isEnterpriseAuthComplete,
                    isEnterpriseAuthInProgress,
                } = appState.authorizationState;
                const observables = [];

                if (!isConsumerAuthComplete && !isConsumerAuthInProgress) {
                    observables.push(new AuthorizationGetConsumerAction());
                }

                if (!isConsumerPurchaseAuthComplete && !isConsumerPurchaseAuthInProgress) {
                    observables.push(new AuthorizationGetEnterpriseAction());
                }

                if (!isEnterpriseAuthComplete && !isEnterpriseAuthInProgress) {
                    observables.push(new AuthorizationGetConsumerPurchaseAction());
                }
                return observables;
            })
        )
    );

    /*
     * Checks if the user is member of the Enterprise.Access role
     */
    getEnterpriseAccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_ENTERPRISE_ACCESS),
            switchMap(() => this.authService.verifyAccessToEnterpriseSection()),
            map((verified) => (verified ? new AuthorizationEnterpriseAccessSuccessAction() : new AuthorizationEnterpriseAccessFailedAction()))
        )
    );

    /*
     * Checks if the user is member of the Consumer.Access role
     */
    getConsumerAccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_CONSUMER_ACCESS),
            switchMap(() => this.authService.verifyAccessToConsumerSection()),
            map((verified) => (verified ? new AuthorizationConsumerAccessSuccessAction() : new AuthorizationConsumerAccessFailedAction()))
        )
    );

    /*
     * Checks if the user is member of the TM.CCPurchase or Purchase.Subs.Extended role
     */
    getConsumerPurchaseAccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_CONSUMER_PURCHASE_ACCESS),
            switchMap(() => this.authService.verifyAccessToConsumerPurchaseSection()),
            map((verified) => {
                return verified ? new AuthorizationConsumerPurchaseAccessSuccessAction() : new AuthorizationConsumerPurchaseAccessFailedAction();
            })
        )
    );
}
