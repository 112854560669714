import { Action } from '@ngrx/store';
import { AuthorizationRole } from './authorization.service';

export const SEARCH = '[Authorization] Search';
export const SUCCESS = '[Authorization] Successful';
export const FAILURE = '[Authorization] Failed';

export const GET_RBAC = '[Authorization] GET_RBAC';

export const GET_ENTERPRISE_ACCESS = '[Authorization] GET_ENTERPRISE_ACCESS';
export const ENTERPRISE_ACCESS_FAILURE = '[Authorization] ENTERPRISE_ACCESS_FAILURE';
export const ENTERPRISE_ACCESS_SUCCESS = '[Authorization] ENTERPRISE_ACCESS_SUCCESS';

export const GET_CONSUMER_ACCESS = '[Authorization] GET_CONSUMER_ACCESS';
export const CONSUMER_ACCESS_FAILURE = '[Authorization] CONSUMER_ACCESS_FAILURE';
export const CONSUMER_ACCESS_SUCCESS = '[Authorization] CONSUMER_ACCESS_SUCCESS';

export const GET_CONSUMER_PURCHASE_ACCESS = '[Authorization] GET_CONSUMER_PURCHASE_ACCESS';
export const CONSUMER_PURCHASE_ACCESS_FAILURE = '[Authorization] CONSUMER_PURCHASE_ACCESS_FAILURE';
export const CONSUMER_PURCHASE_ACCESS_SUCCESS = '[Authorization] CONSUMER_PURCHASE_ACCESS_SUCCESS';

export type AllAutorizationActions =
    | AuthorizationSearchAction
    | AuthorizationFailedAction
    | AuthorizationSuccessfulAction
    | AuthorizationGetEnterpriseAction
    | AuthorizationEnterpriseAccessFailedAction
    | AuthorizationEnterpriseAccessSuccessAction
    | AuthorizationGetConsumerAction
    | AuthorizationConsumerAccessSuccessAction
    | AuthorizationConsumerAccessFailedAction
    | AuthorizationConsumerPurchaseAccessFailedAction
    | AuthorizationConsumerPurchaseAccessSuccessAction
    | AuthorizationGetConsumerPurchaseAction
    | GetRbacAction;

export class AuthorizationSearchAction implements Action {
    readonly type = SEARCH;

    constructor() {}
}

export class AuthorizationSuccessfulAction implements Action {
    readonly type = SUCCESS;

    constructor() {}
}

export class AuthorizationFailedAction implements Action {
    readonly type = FAILURE;
    constructor(public missingRequirements: AuthorizationRole[], public errorMessage?: string) {}
}

// To initiate authorization checks for the user to different sections. Currently checking for Consumer , Consumer Purchase and Enterprise
// access only.
export class GetRbacAction implements Action {
    readonly type = GET_RBAC;
    constructor() {}
}

export class AuthorizationGetEnterpriseAction implements Action {
    readonly type = GET_ENTERPRISE_ACCESS;
    constructor() {}
}

export class AuthorizationEnterpriseAccessFailedAction implements Action {
    readonly type = ENTERPRISE_ACCESS_FAILURE;
    constructor() {}
}

export class AuthorizationEnterpriseAccessSuccessAction implements Action {
    readonly type = ENTERPRISE_ACCESS_SUCCESS;
    constructor() {}
}

export class AuthorizationGetConsumerAction implements Action {
    readonly type = GET_CONSUMER_ACCESS;
    constructor() {}
}

export class AuthorizationConsumerAccessFailedAction implements Action {
    readonly type = CONSUMER_ACCESS_FAILURE;
    constructor() {}
}

export class AuthorizationConsumerAccessSuccessAction implements Action {
    readonly type = CONSUMER_ACCESS_SUCCESS;
    constructor() {}
}

export class AuthorizationGetConsumerPurchaseAction implements Action {
    readonly type = GET_CONSUMER_PURCHASE_ACCESS;
    constructor() {}
}

export class AuthorizationConsumerPurchaseAccessFailedAction implements Action {
    readonly type = CONSUMER_PURCHASE_ACCESS_FAILURE;
    constructor() {}
}

export class AuthorizationConsumerPurchaseAccessSuccessAction implements Action {
    readonly type = CONSUMER_PURCHASE_ACCESS_SUCCESS;
    constructor() {}
}
