import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LogService } from '../../../../shared-services/log.service';
import { DrillDownConfigDefaults } from '../../models/drill-down-config';
import { DrillDownCustomMenu } from '../../models/drill-down-custom-menu';
import { GenericDefaultViewerDirective } from '../../models/generic-default-viewer';
import { DrillDownArraySchema, DrillDownArraySchemaDefaults } from '../../models/schemas/drill-down-array-schema';
import { DrillDownPropertySchema } from '../../models/schemas/drill-down-property-schema';
import { DrillDownStateService } from '../../service/drill-down-state.service';
import { TransformationUtils } from '../../utils/transformation-utils';

@Component({
    selector: 'mast-default-array-viewer',
    templateUrl: './default-array-viewer.component.html',
    styleUrls: ['./default-array-viewer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultArrayViewerComponent extends GenericDefaultViewerDirective<any[], DrillDownArraySchema> implements OnChanges, OnDestroy, AfterViewInit {
    public propertiesToShow: DrillDownPropertySchema[];
    public noneHiddenProperties: DrillDownPropertySchema[];
    public isLoading = false;
    public currentSelection: number[] = [];
    public idField: DrillDownPropertySchema;
    public buttonCount = 5;
    public info = true;
    public pagerType: 'numeric' | 'input' = 'numeric';
    public pageSizes = true;
    public previousNext = true;
    public currentPageSize = 10;
    public skip = 0;
    private dataStatusSubscription: Subscription;
    private customMenu$: ViewContainerRef;
    /** Does the data array consist of scalar items? */
    public hasPrimitiveElements: boolean;
    public filterFields = [];

    constructor(
        private navStateService: DrillDownStateService,
        private logService: LogService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private ref: ChangeDetectorRef
    ) {
        super();
        this.hasPrimitiveElements = false;
        this.propertiesToShow = [];
        this.noneHiddenProperties = [];
    }

    /**
     * Used as a template holder for any custom detail containers.
     */
    @ViewChild('drillDownCustomMenuAc', { read: ViewContainerRef })
    public set customMenu(value: ViewContainerRef) {
        this.customMenu$ = value;
        if (this.currentSchema) {
            this.initCustomMenuContainer(this.currentData, this.currentSchema.customMenuComponent);
        }
    }

    @Input()
    set data(value: any[]) {
        this.currentData = value;
    }

    @Input()
    dataStatus: Observable<any>;

    public ngOnDestroy(): void {
        if (this.dataStatusSubscription) {
            this.dataStatusSubscription.unsubscribe();
        }
    }

    public ngAfterViewInit(): void {
        if (this.currentSchema) {
            this.initCustomMenuContainer(this.currentData, this.currentSchema.customMenuComponent);
        }
    }

    /**
     * Processes any change requests from all external parental components.
     * @param {SimpleChanges} changes Indicates which externally facing property has been altered.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['dataStatus']) {
            if (this.dataStatusSubscription) {
                this.dataStatusSubscription.unsubscribe();
            }
            this.isLoading = true;

            if (this.dataStatus) {
                this.dataStatusSubscription = this.dataStatus.subscribe((data) => {
                    if (data) {
                        this.isLoading = false;
                        this.ref.markForCheck();
                    }
                });
            }
        }

        // Ensure we have at least the default flags available to check in both schema and config.
        this.currentSchema = { ...DrillDownArraySchemaDefaults, ...this.currentSchema };
        this.currentConfig = { ...DrillDownConfigDefaults, ...this.currentConfig };

        // Initialize or update the list of properties to show from the schema
        if (!this.propertiesToShow || this.propertiesToShow.length === 0 || changes['schema']) {
            this.propertiesToShow = (this.currentSchema.propertiesToShow || []).slice();
        }

        // Fill in the gaps in the schema
        TransformationUtils.completePropertySchemas(this.currentData, this.propertiesToShow);

        if (changes['data'] && this.hasData()) {
            this.hasPrimitiveElements = false; // We'll re-evaluate this for the new data.

            if (!this.currentConfig.showOnlyIfInSchema && !this.currentSchema.showOnlyPropertiesIfDeclared) {
                // Show _all_ properties, since we haven't been told not to.  Create the list of
                // properties from both the data we have and the properties the schema says we
                // should be showing.
                this.propertiesToShow = TransformationUtils.mergePropertySchemas(this.currentData, this.currentSchema.propertiesToShow);
            }

            // `hasPrimitiveElements` should be set when the values are simple, scalar values.
            this.hasPrimitiveElements =
                (this.currentData[0] === null || typeof this.currentData[0] !== 'object') &&
                (this.propertiesToShow === null || this.propertiesToShow.length === 0);

            if (this.currentSchema.customMenuComponent) {
                this.initCustomMenuContainer(this.currentData, this.currentSchema.customMenuComponent);
            }
        }

        // Filter out non-primitive columns.  Note that this will filter out ALL the columns
        // unless there are fields in `propertiesToShow` that already have `isPrimitive` set.
        //
        // Since this will cause issues if we have no data from which to infer `isPrimitive`,
        // we'll only apply the filtered list if we have data and the filtered list is
        // non-empty.
        if (this.propertiesToShow && this.propertiesToShow.length > 0 && this.hasData()) {
            const filteredProperties = this.propertiesToShow.filter((x) => x.isPrimitive);
            if (filteredProperties.length > 0) {
                this.propertiesToShow = filteredProperties;
            }
            this.idField = this.findIdentifierField();
        }
        this.filterFields = [];
        this.propertiesToShow.forEach((f) => {
            if (!f.isHidden) {
                this.filterFields.push(f.name);
            }
        });
        this.noneHiddenProperties = this.propertiesToShow.filter((x) => !x.isHidden);
    }

    /** Check whether we should display the default data list. */
    public shouldShowData(): boolean {
        // We show the data area if
        //
        //   * we have a non-null, non-undefined, and non-empty list, or
        //
        //   * we have any "properties to show" specified, even with an empty or null list
        //     (because Kendo Grid has its own "No records..." message, and it shows the users
        //     users what fields _would_ be displayed if the list weren't empty).
        return this.hasData() || this.propertiesToShow.length > 0;
    }

    /** Check whether we have any data to display.
     */
    public hasData(): boolean {
        return this.currentData !== undefined && this.currentData !== null && this.currentData.length > 0;
    }

    /**
     * Find an identifier field to be used for identifying child objects when "drilling down"
     * and in URL tracking.
     *
     * Criteria:
     *   - The first field marked with `isIdentifier` will be returned.
     *   - If no field is marked with `isIdentifier`, the first field marked with
     *     `isHumanReadableId` will be returned.
     *   - If neither of the above conditions matches, the first available field will be
     *     returned.
     *
     * Preference *within* any one of the above criteria is given to fields in the array
     * viewer's `propertiesToShow` list, then to fields listed in the array viewer's element
     * schema.
     *
     * If there are no fields available, this function will return `null`.
     */
    private findIdentifierField(): DrillDownPropertySchema {
        const fields = ['isIdentifier', 'isHumanReadableId'];
        const searchIn = [
            this.propertiesToShow,
            this.currentSchema && this.currentSchema.elementSchema ? this.currentSchema.elementSchema.propertySchemas : null,
        ].filter((x) => x && x.length > 0);

        for (const field of fields) {
            for (const sourceArray of searchIn) {
                const prop = sourceArray.find((x) => x[field]);
                if (prop) {
                    return prop;
                }
            }
        }

        // If we haven't yet found a good candidate, just return the first field we can find.
        if (searchIn.length > 0) {
            return searchIn[0][0];
        } else {
            // No valid property definitions are available to us.  If we reach this point
            // there's not much we can do.
            return null;
        }
    }

    public selectionMade(event): void {
        const dataRetrieved = event.data;
        if (dataRetrieved) {
            const currentElement = dataRetrieved;
            const propertyId = this.findIdentifierField();
            this.logService.logEvent('CL', { areaName: 'DefaultArrayViewer', contentName: `${this.name} Selected` });

            let makeshiftSchema = this.schema;
            if (!makeshiftSchema) {
                makeshiftSchema = {} as DrillDownArraySchema;
                makeshiftSchema.propertiesToShow = this.propertiesToShow;
                makeshiftSchema.showOnlyPropertiesIfDeclared = true;
            }

            // Ensures the lineage is recorded for permalink building purposes.
            this.navStateService.setFieldPathForCurrentState(this.currentLineage);

            /*
             * This will ensure that there's a Summary state for this array.
             */
            this.navStateService.setNewNavState(null, `${this.name}`, this.currentData, propertyId, null, this.currentSchema, 0);

            let singleEntryPropertyName = this.name;
            const objectSchema = this.currentSchema.elementSchema;
            if (objectSchema && 'name' in objectSchema) {
                singleEntryPropertyName = objectSchema.name;
            }

            /*
             * This will ensure that there's a Detailed state for this selection.
             */
            this.navStateService.setNewNavState(
                currentElement,
                singleEntryPropertyName,
                this.currentData,
                propertyId,
                this.currentSchema.elementSchema,
                this.currentSchema,
                1
            );
        }
    }

    protected dataStateChange(): void {
        this.ref.markForCheck();
    }

    /**
     * This will set a custom menu..
     * @param data
     */
    private initCustomMenuContainer(data: any, customMenuComponent: Type<DrillDownCustomMenu>): void {
        if (customMenuComponent && this.customMenu$) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(customMenuComponent);
            this.customMenu$.clear();
            const customMenuViewRef = this.customMenu$.createComponent(factory);
            const detailsContainer = customMenuViewRef.instance as DrillDownCustomMenu;
            if (detailsContainer) {
                detailsContainer.data = data;
                detailsContainer.config = this.config;
                detailsContainer.schema = this.currentSchema;
            }

            customMenuViewRef.changeDetectorRef.detectChanges();
        }
    }

    public comboAttribute(attr: string, row: any) {
        const index = attr.indexOf('.');
        const pref = attr.substring(0, index);
        const post = attr.substring(index + 1);
        return row[pref][post];
    }
}
