<nav class="navbar navbar-expand-sm navbar-dark text-white common-menu-banner" [attr.aria-label]="title">
    <a class="navbar-brand navbar-brand-combo" (click)="titleClicked()">{{ title }}</a>
    <div class="collapse navbar-collapse">
        <mast-drill-down-navigation-bar
            *ngIf="showDrillDownNavigationBar"
            hideFirstElement="true"
            [displayByIdentifier]="true"
        ></mast-drill-down-navigation-bar>
    </div>
    <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item menu-item">
                <a class="nav-link icon-hover" (click)="refreshData()" title="Refresh Data" id="refreshData"
                    ><span class="refresh-menu icon-gap pointer"></span>Refresh</a
                >
            </li>
            <li class="nav-item menu-item" *ngIf="enableJsonViewer">
                <a class="nav-link icon-hover" (click)="toggleJsonViewer()" title="JSON Viewer" id="jsonViewer"
                    ><span class="json-viewer-icon icon-gap pointer"></span>JSON Viewer</a
                >
            </li>
            <li class="nav-item menu-item" *ngIf="viewerState !== drillDownStateOptions.Summary">
                <a class="nav-link icon-hover" (click)="viewerStateChangeRequestEvent()" [title]="viewerStateTitle" id="permalink">
                    <span
                        class="icon-gap pointer"
                        [class.viewer-fullscreen]="viewerState === drillDownStateOptions.HidingParentList"
                        [class.viewer-standardscreen]="viewerState === drillDownStateOptions.ShowingParentList"
                    >
                    </span
                    >{{ viewerStateTitle }}
                </a>
            </li>
        </ul>
    </div>
</nav>
