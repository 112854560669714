import { DrillDownAction } from '../drill-down-action';
import { DrillDownAggregateFunction } from '../drill-down-aggregate-function';
import { DrillDownArraySchema } from './drill-down-array-schema';
import { DrillDownObjectSchema } from './drill-down-object-schema';

/**
 * Represents the metadata of a property's schema.
 */
export interface DrillDownPropertySchema {
    /**
     * The actual name of the property.
     */
    name: string;

    /**
     * The width, in pixels, to display the property in a table/grid.
     */
    width?: number;

    /**
     * The logial moniker/alias of the property.
     */
    title?: string;

    /**
     * Indicates if the property is either a number, string or boolean in data type.
     */
    isPrimitive?: boolean;

    /**
     * Indicates if the property is boolean in data type.
     */
    isBoolean?: boolean;

    /**
     * Indicates if the property is to be used as a human-friendly identifier.
     * This is mostly used for the DrillDownViewer's select list and the navigation state breadcrumb list.
     */
    isHumanReadableId?: boolean;

    /**
     * Indicates if the property is to be used as its primary identifier.
     */
    isIdentifier?: boolean;

    /**
     * Indicates that the property is to be hidden from any of viewers
     * (i.e. DrillDownViewer, DefaultObjectViewer, DefaultArrayViewer, DefaultPropertyViewer and DefaultTabbedViewer).
     */
    isHidden?: boolean;

    /**
     * Indicates that the property is to be hidden from any of viewers if the value is null or undefined.
     */
    hideIfNullOrUndefined?: boolean;

    /**
     * The schema of the value of the property this schema represents.
     */
    schema?: DrillDownObjectSchema | DrillDownArraySchema;

    /**
     * An action when the property is clicked. This function will take three parameters: property value, parent object,
     * navigation array. Only one of action and linkableIdType should be defined.
     */
    action?: DrillDownAction;

    /**
     * The linkable id type this property represents.
     * Only one of action and linkableIdType should be defined.
     */
    linkableIdType?: LinkableIdType;

    /**
     * Should expand an array or object viewer by default.  This is only applicable when isPrimitive is set to false.
     */
    isExpanded?: boolean;

    /**
     * This will perform a custom aggregation process against the parent object value.
     */
    aggregationFunction?: DrillDownAggregateFunction;
}

/**
 * A property can represent a top level context ID within MAST.
 * This enum represents all the possible linkable top level context IDs.
 */
export enum LinkableIdType {
    ConsumerPuid = 'ConsumerPuid',
    EnterpriseTenantId = 'EnterpriseTenantId',
    EnterpriseOrgId = 'EnterpriseOrgId',
    ConsumerPurchaseRawOrderId = 'ConsumerPurchaseRawOrderId',
    ConsumerPurchaseBillingRecordSummaryId = 'ConsumerPurchaseBillingRecordSummaryId',
    ToolsProductCode = 'ToolsProductCode',
}
