<div *ngIf="!isHidden" class="default-property-viewer">
    <ng-container *ngIf="isPrimitive; then primitive; else notPrimitive"></ng-container>
    <ng-template #primitive>
        <div class="form-group row">
            <label class="col-md-2 col-form-label font-weight-bold" for="{{ propertyGroupId }}">{{ name }}</label>
            <div class="col-md-10 data-value">
                <ng-container *ngIf="definedAction; then action; else notAction"></ng-container>
                <ng-template #action>
                    <a
                        class="drill-down-action"
                        id="{{ propertyGroupId }}"
                        (click)="executeAction()"
                        (keyup.enter)="executeAction()"
                        tabindex="0"
                        data-bi-area="DefaultPropertyViewer"
                        data-bi-name="PerformActionForPrimitive"
                        >{{ currentData }}</a
                    >
                </ng-template>
                <ng-template #notAction>
                    <ng-container *ngIf="linkUrl; then link; else notLink"></ng-container>
                    <ng-template #link>
                        <a [href]="linkUrl" target="_blank" id="{{ propertyGroupId }}" data-bi-area="DefaultPropertyViewer" data-bi-name="LinkedTopLevelId">{{
                            currentData
                        }}</a>
                    </ng-template>
                    <ng-template #notLink>
                        <ng-container *ngIf="isNullOrEmpty; then nullOrEmpty; else notNullNorEmpty"></ng-container>
                        <ng-template #nullOrEmpty>
                            <span class="font-italic" id="{{ propertyGroupId }}">Empty</span>
                        </ng-template>
                        <ng-template #notNullNorEmpty>
                            <span id="{{ propertyGroupId }}">{{ currentData }}</span>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </ng-template>
    <ng-template #notPrimitive>
        <ng-container *ngIf="isNullOrEmpty; then nullOrEmpty; else notNullNorEmpty"></ng-container>
        <ng-template #nullOrEmpty>
            <div class="form-group row">
                <label class="col-md-2 col-form-label font-weight-bold">
                    {{ name }}
                </label>
                <div class="col-md-10 data-value">
                    <div class="card">
                        <ng-container *ngIf="definedAction; then action; else notAction"></ng-container>
                        <ng-template #action>
                            <div div class="card-body">
                                <a
                                    class="drill-down-action"
                                    id="{{ propertyGroupId }}"
                                    (click)="executeAction()"
                                    (keyup.enter)="executeAction()"
                                    tabindex="0"
                                    data-bi-area="DefaultPropertyViewer"
                                    data-bi-name="PerformActionForComplexType"
                                >
                                    <span class="font-italic">Click here for details</span>
                                </a>
                            </div>
                        </ng-template>
                        <ng-template #notAction>
                            <span class="font-italic" id="{{ propertyGroupId }}">Empty</span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #notNullNorEmpty>
            <div class="form-group row">
                <a
                    class="col-md-4 col-form-label font-weight-bold drill-down-option"
                    data-toggle="collapse"
                    href="#{{ propertyExpandId }}"
                    aria-expanded="false"
                    attr.aria-controls="{{ propertyExpandId }}"
                    data-bi-area="DefaultPropertyViewer"
                    data-bi-name="ExpandPropertyViewer"
                >
                    {{ name }}
                </a>
                <div class="col-md-11 ml-md-auto">
                    <div [class.show]="isExpandedByDefault" class="collapse property-child-viewer" id="{{ propertyExpandId }}">
                        <mast-default-array-viewer
                            *ngIf="isArray"
                            [data]="currentData"
                            [name]="name"
                            [depth]="currentDepth"
                            [config]="currentConfig"
                            [schema]="currentSchema.schema"
                            [lineage]="currentLineage"
                        >
                        </mast-default-array-viewer>
                        <mast-default-object-viewer
                            *ngIf="isObject"
                            [data]="currentData"
                            [name]="name"
                            [depth]="currentDepth"
                            [config]="currentConfig"
                            [schema]="currentSchema.schema"
                            [lineage]="currentLineage"
                        >
                        </mast-default-object-viewer>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
    <hr />
</div>
