import { Action } from '@ngrx/store';

export const SEARCH = '[ConsumerRoot] Search';
export const SEARCH_FAILED = '[ConsumerRoot] Search Failed';
export const SEARCH_RESET = '[ConsumerRoot] Search Reset';
export const SEARCH_SUCCESSFUL = '[ConsumerRoot] Search Successful';
export const BY_CONSUMER_ROOT_METHOD = 'getmsa';
export const BY_CONSUMER_ROOT_HEX_PUID_METHOD = 'getmsafrompuidhex';
export const BY_CONSUMER_ROOT_XUID_METHOD = 'getmsafromxuid';
export const BY_CONSUMER_ROOT_GAMERTAG_METHOD = 'getmsafromgamertag';

export type AllCrsActions = ConsumerRootSearchAction | ConsumerRootSearchSuccessfulAction | ConsumerRootSearchFailedAction | ConsumerRootSearchResetAction;

export class ConsumerRootSearchAction implements Action {
    readonly type = SEARCH;

    constructor(public environment: string, public id: string, public method: string) {}
}

export class ConsumerRootSearchFailedAction implements Action {
    readonly type = SEARCH_FAILED;

    constructor(public errorCode: string, public errorMessage: string) {}
}

export class ConsumerRootSearchResetAction implements Action {
    readonly type = SEARCH_RESET;
}

export class ConsumerRootSearchSuccessfulAction implements Action {
    readonly type = SEARCH_SUCCESSFUL;

    constructor(public results: any) {}
}
