// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    webApiService: 'https://mastapi-dev.microsoft.com',
    clientId: 'a82e6747-1d01-4b97-862c-615b61ba6760',
    appId: 'mastdev',
    appInsights: {
        instrumentationKey: 'f0d8d462-78cf-4406-b375-7e98de8c0065',
    },
};
