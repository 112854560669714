import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Observable, of, Subject } from 'rxjs';
/**
 * This is the authentication service via AAD.
 */
@Injectable()
export class AuthenticationService {
    constructor(private msalService: MsalService) {}

    /**
     * Handles whatever initialization processes (including Handling Callbacks)
     */
    public msalLogin(): void {
        this.msalService.loginRedirect();
    }

    public logout() {
        this.msalService.logoutRedirect();
    }

    public getMastWebApiServiceToken(): Observable<any> {
        return of(
            this.msalService.instance.acquireTokenSilent({
                scopes: ['user.read'],
            })
        );
    }

    public get msalUserInfo(): AccountInfo {
        return this.msalService.instance.getActiveAccount();
    }

    public get msalAllUserAccount() {
        return this.msalService.instance.getAllAccounts();
    }

    public setActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.msalUserInfo;
        const accounts = this.msalService.instance.getAllAccounts();
        if (!activeAccount && accounts.length > 0) {
            this.msalService.instance.setActiveAccount(accounts[0]);
            activeAccount = accounts[0];
        }

        return activeAccount;
    }
}
