import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
@Component({
    selector: 'mast-form-repeater',
    templateUrl: './form-repeater.component.html',
    styleUrls: ['./form-repeater.component.css'],
})
export class FormRepeaterComponent implements OnInit, OnChanges {
    @Input()
    public itemTemplate: TemplateRef<FormGroup>;
    @Input()
    public createItem: () => FormGroup;
    @Input()
    public formArrayName: string;
    @Input()
    public form: FormGroup;

    public items: FormArray = new FormArray([]);

    ngOnChanges(changes: SimpleChanges): void {
        this.initialize();
    }

    ngOnInit() {
        this.initialize();
    }

    addItem() {
        this.items.push(this.createItem());
    }

    removeItem(index: number) {
        this.items.removeAt(index);
    }

    getItem(index: number): FormGroup {
        return this.items.at(index) as FormGroup;
    }

    private initialize() {
        this.items = this.form.get(this.formArrayName) as FormArray;
        if (this.items.length === 0) {
            this.addItem();
        }
    }
}
