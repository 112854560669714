import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../app.states';
import { AuthenticationComponent } from '../../authentication/authentication.component';
import { SettingsService } from '../../shared-services/settings.service';
import {
    FAILED,
    INITIALIZE,
    PROMPT_USER,
    SEARCH,
    TermsOfUseAadAuthFailedAction,
    TermsOfUseFailedAction,
    TermsOfUseInitializeAction,
    TermsOfUsePromptUserAction,
    TermsOfUseSearchAction,
    TermsOfUseSuccessAction,
    TermsOfUseUserConsentedAction,
    USER_CONSENTED,
} from './terms-of-use.actions';
import { TermsOfUseService } from './terms-of-use.service';

@Injectable()
export class TermsOfUseEffects {
    constructor(
        private actions$: Actions,
        private authStore: Store<AppState>,
        private settingsService: SettingsService,
        private router: Router,
        private route: ActivatedRoute,
        private termsOfUseService: TermsOfUseService
    ) {}

    @Effect()
    initialize$: Observable<Action> = this.actions$.pipe(
        ofType<TermsOfUseInitializeAction>(INITIALIZE),
        mergeMap((action) =>
            this.authStore.pipe(
                first((state) => state.authorizationState.isComplete && state.authenticationState.isComplete),
                map((state) => ({ action, state }))
            )
        ),
        switchMap<{ action: Action; state: AppState }, Observable<Action>>((actionState) => {
            const state = actionState.state;
            const action = actionState.action;

            if (!state.authenticationState.isAuthenticated || !state.authorizationState.isAuthorized) {
                return of(new TermsOfUseAadAuthFailedAction());
            }

            if (state.termsOfUseState.isComplete && state.termsOfUseState.userHasConsented) {
                return of(new TermsOfUseSuccessAction());
            }

            const url = new URL(this.settingsService.mastApiServiceUrl);
            const key = `MAST-${url.hostname}-${state.authenticationState.fullyQualifiedUsername}`;
            const value = this.settingsService.getItemsFromCookie(key);
            if (value) {
                return of(new TermsOfUseSearchAction(key, value));
            } else {
                return this.termsOfUseService.readTermsOfUse().pipe(
                    catchError((error) => of('')),
                    map((termsValue) => {
                        if (termsValue.length > 0) {
                            // Store the results for 180 days.
                            this.settingsService.setItemsToCookie(key, `${termsValue}`, 180);
                            return new TermsOfUseSuccessAction();
                        }

                        return new TermsOfUsePromptUserAction(key);
                    })
                );
            }
        })
    );

    @Effect({ dispatch: false })
    promptUser$ = this.actions$.pipe(
        ofType<TermsOfUsePromptUserAction>(PROMPT_USER),
        tap((action) => {
            this.router.navigate(['termsOfUse'], { relativeTo: this.route.root });
        })
    );

    @Effect({ dispatch: false })
    userConsented$ = this.actions$.pipe(
        ofType<TermsOfUseUserConsentedAction>(USER_CONSENTED),
        withLatestFrom(this.authStore),
        switchMap(([action, state]) => {
            const currentTermsOfUseState = state.termsOfUseState;
            return this.termsOfUseService.writeTermsOfUse().pipe(
                catchError((error) => of('')),
                tap((termsValue) => {
                    // if return value is empty, then set failed action and navigate to proper error page.
                    if (termsValue.length === 0) {
                        AuthenticationComponent.prototype.setShowToUFailure(true);
                        AuthenticationComponent.prototype.setShowUnAuth(false);
                        this.router.navigate(['auth'], { relativeTo: this.route.root });
                        return;
                    }

                    this.settingsService.setItemsToCookie(currentTermsOfUseState.key, termsValue);
                    this.router.navigate([''], { relativeTo: this.route.root });
                })
            );
        })
    );

    @Effect()
    search$: Observable<Action> = this.actions$.pipe(
        ofType<TermsOfUseSearchAction>(SEARCH),
        switchMap((action) => {
            return this.termsOfUseService.verifyTermsOfUseEncryptedString(action.encryptedValue).pipe(catchError((error) => of(false)));
        }),
        map<any, Action>((results) => {
            if (results === true) {
                return new TermsOfUseSuccessAction();
            } else {
                return new TermsOfUseFailedAction();
            }
        })
    );

    @Effect({ dispatch: false })
    failed$ = this.actions$.pipe(
        ofType<TermsOfUseFailedAction>(FAILED),
        tap((action) => {
            AuthenticationComponent.prototype.setShowToUFailure(true);
            AuthenticationComponent.prototype.setShowUnAuth(false);

            this.router.navigate(['auth'], { relativeTo: this.route.root });
        })
    );
}
