import { Component, OnInit, forwardRef, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import * as JSONEditor from 'jsoneditor';
import { MastJsonEditorWrapperOptions } from '../../models/mast-json-editor-wrapper/mast-json-editor-wrapper-options';

@Component({
    selector: 'mast-json-editor-wrapper',
    templateUrl: './mast-json-editor-wrapper.component.html',
    styleUrls: ['./mast-json-editor-wrapper.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MastJsonEditorWrapperComponent),
            multi: true,
        },
    ],
})
export class MastJsonEditorWrapperComponent implements OnInit, ControlValueAccessor, OnChanges {
    private editor$: any;
    onTouched$: any;
    onChange$: any;
    json$: any;
    options$: MastJsonEditorWrapperOptions = {
        mode: 'tree',
        modes: ['code', 'form', 'text', 'tree', 'view'], // allowed modes
        onError: (err) => {
            console.error(err.toString());
        },
        onChange: () => {
            console.log('change');
        },
        indentation: 4,
        escapeUnicode: true,
    };

    constructor(private element$: ElementRef) {}

    get options(): MastJsonEditorWrapperOptions {
        return this.options$;
    }

    @Input()
    set options(optionValues: MastJsonEditorWrapperOptions) {
        this.options$ = optionValues;
    }

    @Input()
    set ngModel(val) {
        this.json$ = val;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.editor$) {
            let optionsSet = false;
            if (changes['options']) {
                const editorElement = this.element$.nativeElement.children[0];
                this.editor$ = new JSONEditor(editorElement, this.options$);
                optionsSet = true;
            }

            if (changes['ngModel'] || optionsSet) {
                this.editor$.set(this.json$);
            }
        }
    }

    public ngOnInit() {
        const editorElement = this.element$.nativeElement.children[0];
        this.editor$ = new JSONEditor(editorElement, this.options$);
        this.editor$.set(this.json$);
    }

    public writeValue(obj: any): void {
        this.json$ = obj;
    }

    public registerOnChange(fn: any): void {
        this.onChange$ = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched$ = fn;
    }

    public setDisabledState(isDisabled: boolean): void {}
}
