import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Generates all of the commonly used headers for web requests, especially Mast WebApi calls.
 * Please use this function if you plan to take advantage of the default HttpInjectors that provides
 * Authentication Bearer tokens, Debugging and Correlation vector population.
 * @param operationName The operation name.  This is essential to match this value with its SLL counterpart.
 * @param isMastWebRequest True - Indicates that this is a Mast WebApi request.
 * @param expectMastResultObject True - Indicates that the results will match the MastResult interface.
 */
export function generateCommonHeaderConfiguration(
    operationName: string,
    isMastWebRequest: boolean = true,
    expectMastResultObject: boolean = true,
    contentType: string = 'application/json; charset=utf-8'
): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
        'Content-Type': contentType,
        $opname: operationName,
        $expectmastresult: `${expectMastResultObject}`,
    });

    if (isMastWebRequest) {
        headers = headers.set('$mast-web-request', 'true');
    }

    return headers;
}

/**
 * The type of the parameters accepted by generateWebRequestOptions
 */
export interface Params {
    [param: string]: string | number | boolean | (string | number | boolean)[];
}

/**
 * Genearate an instance of a typically used for most GET web requests.
 * @param operationName The operation name.  This is essential to match this value with its SLL counterpart.
 * @param parameterValues A bag of query parameters.
 * @param isMastWebRequest True - Indicates that this is a Mast WebApi request.
 * @param expectMastResultObject True - Indicates that the results will match the MastResult interface.
 */
export function generateWebRequestOptions(
    operationName: string,
    parameterValues: Params = null,
    isMastWebRequest: boolean = true,
    expectMastResultObject: boolean = true,
    contentType: string = 'application/json; charset=utf-8'
): { headers: HttpHeaders; params?: HttpParams } {
    const options: { headers: HttpHeaders; params?: HttpParams } = {
        headers: generateCommonHeaderConfiguration(operationName, isMastWebRequest, expectMastResultObject, contentType),
    };

    if (parameterValues) {
        options['params'] = new HttpParams({ fromObject: stringifyParams(parameterValues) });
    }

    return options;
}

/**
 * Converts a Params object to the format required by HttpParamsOptions.fromObject
 * @param params the parameters to stringify
 */
function stringifyParams(params: Params): { [param: string]: string | string[] } {
    const stringParams: { [param: string]: string | string[] } = {};
    if (params == null) {
        return stringParams;
    }
    Object.keys(params).forEach((curKey) => {
        const curVal = params[curKey];

        if (Array.isArray(curVal)) {
            stringParams[curKey] = (curVal as unknown as (string | number | boolean)[]).map((val) => String(val).trim());
        } else if (typeof curVal === 'string' && /(\%\w+)+/.test(curVal)) {
            // if string is uri encoded, don't stringify it because it wil unencode.
            stringParams[curKey] = curVal as unknown as string;
        } else {
            stringParams[curKey] = String(curVal).trim();
        }
    });
    return stringParams;
}

/**
 * This is a tool for executing services dynamically.
 * @param service An instance of the service.
 * @param functionName The name of the service method/function.
 * @param parameters All of the service parameters.
 */
export function executeServiceRequest<T>(service: { [key: string]: () => void }, functionName: string, ...parameters: any[]): Observable<T> {
    const functionDeclaration = service[functionName];
    let dataProviderExecution: Observable<T>;
    if (parameters) {
        dataProviderExecution = functionDeclaration.call(service, ...parameters) as Observable<T>;
    } else {
        dataProviderExecution = functionDeclaration.call(service) as Observable<T>;
    }

    return dataProviderExecution;
}
