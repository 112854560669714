import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsllService } from './jsll.service';

@Injectable()
export class CorrelationVectorInterceptor implements HttpInterceptor {
    constructor(private jsllService: JsllService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url;
        const headers = req.headers;
        const cv = headers.get('MS-CV');
        if (url && !cv) {
            const cvValue = this.jsllService.correlationVector.increment();
            const newRequest = req.clone({ headers: req.headers.set('MS-CV', cvValue) });
            return next.handle(newRequest);
        }

        return next.handle(req);
    }
}
