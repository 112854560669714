<div [formGroup]="form">
    <mat-form-field>
        <mat-select [placeholder]="label" [formControlName]="controlName" [required]="required">
            <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
        </mat-select>
        <mat-error>
            {{ errorMessage }}
        </mat-error>
    </mat-form-field>
</div>
