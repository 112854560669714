<ng-container *ngIf="currentData">
    <mat-tab-group (selectedTabChange)="selectChange($event)" [selectedIndex]="activeTabIndex">
        <mat-tab *ngFor="let tab of tabsToDisplay | tabVisibility" [label]="tab.tabSchema.name" [disabled]="tab.isDisabled">
            <div *ngIf="tab.tabSchema.showBanner" class="notification-banner card-header bg-warning text-black" [innerHTML]="sanitizedMessage"></div>
            <ng-container [ngSwitch]="tab.isArray">
                <mast-default-array-viewer
                    *ngSwitchCase="true"
                    [data]="tab.data | async"
                    [dataStatus]="tab.searchStatus"
                    [config]="currentConfig"
                    [schema]="tab.tabSchema.dataSchema"
                    [depth]="0"
                    [name]="tab.tabSchema.name"
                    [lineage]="currentLineage"
                >
                </mast-default-array-viewer>
                <mast-default-object-viewer
                    lt-object-viewer
                    *ngSwitchDefault
                    [data]="tab.data | async"
                    [dataStatus]="tab.searchStatus"
                    [config]="currentConfig"
                    [schema]="tab.tabSchema.dataSchema"
                    [depth]="0"
                    [name]="tab.tabSchema.name"
                    [lineage]="currentLineage"
                >
                </mast-default-object-viewer>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-container>
