import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { SearchStatus } from '../../models/search-criteria-values';
import { LookupContextService } from '../../shared-services/lookup/lookup-context/lookup-context.service';

@Component({
    selector: 'mast-search-failed-banner',
    templateUrl: './search-failed-banner.component.html',
    styleUrls: ['./search-failed-banner.component.css'],
})
export class SearchFailedBannerComponent implements OnInit, OnDestroy {
    showSearchFailedBanner = false;
    searchCompleteSubscription: Subscription;

    constructor(private lookupContextService: LookupContextService) {}

    ngOnInit() {
        this.searchCompleteSubscription = this.lookupContextService.searchComplete
            .pipe(skipWhile((searchCriteria) => !searchCriteria))
            .subscribe((searchCriteria) => this.setSearchStatus(searchCriteria));
    }

    ngOnDestroy() {
        this.searchCompleteSubscription.unsubscribe();
    }

    private setSearchStatus(searchCriteria) {
        this.showSearchFailedBanner = searchCriteria.searchStatus === SearchStatus.SearchFailed;
    }
}
