import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { AppState } from '../app.states';
import { AuthorizationSearchAction, GetRbacAction } from './services/authorization.actions';
import { AuthorizationState } from './services/authorization.state';

@Injectable()
export class AuthorizationGuard implements CanActivate, CanLoad {
    private authorizationState: Observable<AuthorizationState>;
    constructor(private router: Router, private authStore: Store<AppState>) {
        this.authorizationState = this.authStore.pipe(
            select((result) => result.authorizationState),
            filter((x) => x.isComplete)
        );
    }

    public canLoad(route: Route): Observable<boolean> {
        this.authStore.dispatch(new AuthorizationSearchAction());
        return this.authorizationState.pipe(
            map((x) => x.isAuthorized),
            first(),
            tap((x) => {
                if (!x) {
                    this.router.navigate(['unauthorized']);
                } else {
                    this.authStore.dispatch(new GetRbacAction());
                }
            })
        );
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.authStore.dispatch(new AuthorizationSearchAction());
        return this.authorizationState.pipe(
            map((x) => x.isAuthorized),
            tap((x) => {
                if (!x) {
                    this.router.navigate(['unauthorized']);
                } else {
                    this.authStore.dispatch(new GetRbacAction());
                }
            })
        );
    }
}
