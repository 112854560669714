import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
    constructor(private logService: LogService) {
        super();
    }

    handleError(error: Error) {
        this.logService.logException(error); // Manually log exception
    }
}
