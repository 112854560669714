import { AllAuthenticationActions, INITIALIZE, SEARCH, SEARCH_COMPLETE } from './authentication.actions';
import { AuthenticationState } from './authentication.state';

const initialState: AuthenticationState = {
    username: null,
    fullyQualifiedUsername: null,
    isAuthenticated: false,
    isComplete: false,
    startTime: null,
    name: null,
};

export function reducer(state: AuthenticationState, action: AllAuthenticationActions): AuthenticationState {
    if (!state) {
        state = initialState;
    }

    switch (action.type) {
        case INITIALIZE:
            return {
                ...initialState,
            };
        case SEARCH:
            return {
                ...state,
            };
        case SEARCH_COMPLETE:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isComplete: true,
                username: action.username,
                fullyQualifiedUsername: action.fullyQualifiedUsername,
            };
        default:
            return state;
    }
}
