<ng-container [ngSwitch]="isLoading">
    <mast-loader-status *ngSwitchCase="true" [isActive]="isLoading"></mast-loader-status>
    <div
        *ngSwitchDefault
        [ngSwitch]="currentData && (!currentConfig || (currentConfig && (!currentConfig.maxDepth || currentConfig.maxDepth > currentDepth)))"
        class="default-object-viewer"
    >
        <div class="card" *ngSwitchCase="true">
            <ng-template #drillDownCustomMenuOc></ng-template>
            <div class="card-body">
                <ng-container *ngFor="let propertyItem of propertiesToShow">
                    <mast-default-property-viewer
                        [data]="propertyItem.value"
                        [name]="propertyItem.title"
                        [schema]="propertyItem.schema"
                        [depth]="propertyItem.depth"
                        [config]="currentConfig"
                        [lineage]="propertyItem.lineage"
                    ></mast-default-property-viewer>
                </ng-container>
            </div>
        </div>
        <ng-container *ngSwitchDefault>
            <ng-container [ngSwitch]="!currentData">
                <a (click)="expandData()" *ngSwitchCase="false" data-bi-area="DefaultObjectViewer" data-bi-name="ExpandDataViaDrillDown"
                    >Click here to view details for: {{ name }}</a
                >
                <span *ngSwitchDefault class="drill-down-no-data">No data found for {{ name }}</span>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
