import { Component, OnInit, Input } from '@angular/core';
import { DrillDownState } from '../../models/drill-down-state.enum';

@Component({
    selector: 'mast-default-header',
    templateUrl: './default-header.component.html',
    styleUrls: ['./default-header.component.css'],
})
export class DefaultHeaderComponent implements OnInit {
    public drillDownStateOptions: typeof DrillDownState = DrillDownState;

    constructor() {}

    @Input('name')
    public name: string;

    @Input('state')
    public state: DrillDownState;

    ngOnInit() {}
}
