import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, skipWhile, tap } from 'rxjs/operators';
import { AppState } from '../app.states';
import { NavigationItem } from '../models/navigation-item';
import { SearchCriteriaValues, SearchStatus } from '../models/search-criteria-values';
import { WindowInjectionToken } from '../models/window-proxy';
import { LookupContextService } from '../shared-services/lookup/lookup-context/lookup-context.service';

@Component({
    selector: 'mast-side-nav',
    templateUrl: './mast-side-nav.component.html',
    styleUrls: ['./mast-side-nav.component.css'],
    animations: [
        trigger('menuState', [
            state(
                'inactive',
                style({
                    'min-width': '60px',
                    'max-width': '60px',
                })
            ),
            state(
                'active',
                style({
                    'min-width': '260px',
                    'max-width': '260px',
                })
            ),
            state(
                'unauth',
                style({
                    'min-width': 'unset',
                    'max-width': 'unset',
                })
            ),
            transition('inactive => active', [
                animate('100ms', keyframes([style({ 'min-width': '60px' }), style({ 'min-width': '190px' }), style({ 'min-width': '240px' })])),
            ]),
            transition('active => inactive', [
                animate('100ms', keyframes([style({ 'min-width': '240px' }), style({ 'min-width': '190px' }), style({ 'min-width': '60px' })])),
            ]),
            transition('* => unauth', animate('100ms ease-out')),
            transition('unauth => *', animate('100ms ease-out')),
        ]),
        trigger('displayState', [
            state(
                'inactive',
                style({
                    width: '100%',
                    'padding-left': '60px',
                    'min-width': '680px',
                })
            ),
            state(
                'active',
                style({
                    width: '100%',
                    'min-width': '480px',
                    'padding-left': '260px',
                })
            ),
            state(
                'unauth',
                style({
                    width: '100%',
                    'max-width': 'unset',
                    'padding-left': 'unset',
                })
            ),
            transition('inactive => active', animate('100ms ease-in')),
            transition('active => inactive', animate('100ms ease-out')),
            transition('* => unauth', animate('100ms ease-out')),
            transition('unauth => *', animate('100ms ease-out')),
        ]),
    ],
})
export class MastSideNavComponent implements OnInit, OnDestroy, AfterViewInit {
    public static minScreenSize = 1410;
    public contextMenuDisplay = true;
    public showMenuText = true;
    public disableResize = false;
    public contextMenuName: string;
    public contextMenuUrl: string;
    public userCleared: Observable<boolean>;
    public termsSatisfied: Observable<boolean>;
    public mainViewerHeight: Observable<number>;
    public updateCount = 0;

    /**
     * This indicates whether the filter search was completed successfully.
     * If not, nothing should be enabled.
     */
    public isActive = false;

    /**
     * After a user-invoked search, the default context component should be activated and highlighted.
     */
    private navigateToDefault = false;
    private searchComplete = false;
    private searchCompletedSubscription: Subscription;
    private currentApplicationContextItemSubscription: Subscription;
    private mainViewerHeight$ = new BehaviorSubject<number>(null);
    private currentSearchCriteriaValues$: SearchCriteriaValues;
    private storedProcessedUrlLinks$: { [key: string]: string } = {};

    /**
     * Contains the each navigation item to be displayed.
     */
    public navigationItems: Array<NavigationItem> = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private lookupContextService: LookupContextService,
        private appState: Store<AppState>,
        private elementRef: ElementRef,
        @Inject(WindowInjectionToken) private window: Window
    ) {
        this.mainViewerHeight = this.mainViewerHeight$.asObservable().pipe(filter((x) => x !== null));
        this.termsSatisfied = this.appState.pipe(
            select((x) => x.termsOfUseState),
            map((x) => x.isComplete && x.userHasConsented)
        );
        this.userCleared = this.appState
            .select((x) => x)
            .pipe(
                filter((x) => x.authenticationState.isComplete && x.authorizationState.isComplete),
                map((x) => x.authenticationState.isAuthenticated && x.authorizationState.isAuthorized),
                tap((x) => {
                    this.calculateMainViewerHeight();
                })
            );
    }

    public ngOnInit(): void {
        this.searchCompletedSubscription = this.lookupContextService.searchComplete
            .pipe(skipWhile((x) => !x || x.searchStatus === SearchStatus.Idle))
            .subscribe((searchCriteria) => {
                this.currentSearchCriteriaValues$ = searchCriteria;
                this.isActive = searchCriteria.searchStatus === SearchStatus.SearchSuccessful;
                this.navigateToDefault = true;
                this.searchComplete = true;
                this.calculateMainViewerHeight();
                this.updateCount++;
                this.storedProcessedUrlLinks$ = {};
            });

        this.currentApplicationContextItemSubscription = this.lookupContextService.currentApplicationContextItem
            .pipe(filter((x) => x !== null && x !== undefined))
            .subscribe((applicationContextItem) => {
                this.navigationItems = applicationContextItem.categories;
                this.contextMenuUrl = applicationContextItem.url;
                this.contextMenuName = applicationContextItem.name;
                if (applicationContextItem.name === 'Tools' || applicationContextItem.name === 'ConsumerPurchase') {
                    this.isActive = true;
                } else {
                    this.isActive = this.searchComplete;
                }
                this.calculateMainViewerHeight();
                this.updateCount++;
                this.storedProcessedUrlLinks$ = {};
            });
    }

    public ngAfterViewInit(): void {
        if (this.window) {
            this.setViewState(this.window.innerWidth);
        }
    }

    public ngOnDestroy(): void {
        if (this.searchCompletedSubscription) {
            this.searchCompletedSubscription.unsubscribe();
        }

        if (this.currentApplicationContextItemSubscription) {
            this.currentApplicationContextItemSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: any): void {
        if (event.target) {
            // This is to ensure that the window doesn't get too small and
            // obscure all of the side menu text.
            this.setViewState(event.target.innerWidth);
            this.calculateMainViewerHeight();
        }
    }

    public menuChanged(): void {
        this.contextMenuDisplay = !this.contextMenuDisplay;
    }

    public animationStarted(): void {
        if (!this.contextMenuDisplay) {
            this.showMenuText = false;
        }
    }

    public animationDone(): void {
        if (this.contextMenuDisplay) {
            this.showMenuText = true;
        }
    }

    public isSubjectVisible(navigationItem: NavigationItem): boolean {
        const isVisibleValue = navigationItem.isVisible;
        if (isVisibleValue === undefined || isVisibleValue === null) {
            return true;
        }

        if (typeof isVisibleValue === 'function') {
            return isVisibleValue(this.lookupContextService ? this.lookupContextService.searchCriteria : null);
        }

        return isVisibleValue;
    }

    public processUrlLink(navigationItem: NavigationItem): string {
        const href = navigationItem.href;
        let currentEntry = this.storedProcessedUrlLinks$[navigationItem.title];
        if (currentEntry) {
            return currentEntry;
        }

        if (href && typeof href === 'function') {
            currentEntry = href(this.currentSearchCriteriaValues$);
            this.storedProcessedUrlLinks$[navigationItem.title] = currentEntry;
            return currentEntry;
        }

        return href as string;
    }

    public processUrlRequest(navigationItem: NavigationItem): void {
        if (navigationItem) {
            const url = this.processUrlLink(navigationItem);
            this.window.open(url, '_blank');
        }
    }

    /**
     * Transforms an abstract menu context link to the an active one based on the search results.
     * @param route The abstract menu context link.
     */
    private standardize(route: string) {
        if (route && this.isActive) {
            route = this.lookupContextService.transformLink(route);
        } else {
            route = 'disabled';
        }

        return route;
    }

    /**
     * Setting the menu display state for the browser's resolution.
     * @param width
     */
    private setViewState(width: number) {
        if (width < MastSideNavComponent.minScreenSize) {
            this.contextMenuDisplay = false;
        } else {
            this.contextMenuDisplay = true;
        }
    }

    private calculateMainViewerHeight(): void {
        const height = this.window.innerHeight;
        const hostElem = this.elementRef.nativeElement;

        // We want to start from above this component.
        const navBarHeight = this.findNodeHeight(hostElem.parentNode, 'mast-nav-bar');
        if (navBarHeight > 0) {
            this.mainViewerHeight$.next(height - navBarHeight);
        }
    }

    private findNodeHeight(domElement: any, elementName: string): number {
        let result: number = null;
        if (domElement) {
            if (domElement.localName === elementName) {
                if (domElement.clientHeight === 0) {
                    return domElement.firstElementChild.clientHeight;
                }

                return domElement.clientHeight;
            } else if (domElement.children) {
                const children = domElement.children;
                const length = children.length;
                for (let index = 0; index < length && result === null; index++) {
                    const childNode = children[index];
                    result = this.findNodeHeight(childNode, elementName);
                }
            }
        }

        return result;
    }
}
