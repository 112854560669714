import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LogService } from '../../../../shared-services/log.service';
import { DrillDownIsHiddenFunction } from '../../models/drill-down-is-hidden-function';
import { GenericDefaultViewerDirective } from '../../models/generic-default-viewer';
import { DrillDownObjectSchema } from '../../models/schemas/drill-down-object-schema';
import { DrillDownTabSchema } from '../../models/schemas/drill-down-tab-schema';
import { TabDataRetrievalState } from '../../models/tab-data-retrieval-state';
import { DrillDownDeferredDataProviderService } from '../../service/drill-down-deferred-data-provider.service';
import { DrillDownStateService } from '../../service/drill-down-state.service';

@Component({
    selector: 'mast-default-tabbed-viewer',
    templateUrl: './default-tabbed-viewer.component.html',
    styleUrls: ['./default-tabbed-viewer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultTabbedViewerComponent extends GenericDefaultViewerDirective<Object, DrillDownObjectSchema> implements OnChanges {
    public defaultTab: TabDataRetrievalState;
    public activeTab: TabDataRetrievalState;
    public tabsToDisplay: Array<TabDataRetrievalState>;
    public activeTabIndex = 0;

    private sanitizedMessage: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(
        'OLS sections will be deprecated from MAST on 8/15/2021. ' +
            'If you have any questions or concerns please reach out to <a href="mailto:masthelp@microsoft.com?subject=MAST OLS deprecation"> MASTHelp </a>'
    );
    constructor(
        private drillDownDeferredDataProviderService: DrillDownDeferredDataProviderService,
        private logService: LogService,
        private drillDownStateService: DrillDownStateService,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {
        super();
    }

    @Input()
    data: Object;

    @Input()
    public tab: string;
    public ngOnChanges(changes: SimpleChanges): void {
        let isDataChange = false;
        if (changes['data']) {
            isDataChange = true;
            this.currentData = this.data;
            if (!this.currentLineage) {
                this.currentLineage = [{ value: this.currentData, schema: this.currentSchema }];
            }
        }

        if ((isDataChange && this.currentData) || (changes['schema'] && this.currentSchema)) {
            this.tabsToDisplay = new Array<TabDataRetrievalState>();
            const primaryTab = {} as DrillDownTabSchema;
            primaryTab.name = this.currentSchema.tabName ? this.currentSchema.tabName : this.name;
            primaryTab.dataSchema = this.currentSchema;
            primaryTab.isHidden = this.currentSchema.hidePrimaryTab;
            this.defaultTab = this.drillDownDeferredDataProviderService.getTabDataRetrievalState(primaryTab, this.currentConfig, this.currentData);
            this.tabsToDisplay.push(this.defaultTab);
            this.prepareTabs();

            // To be hooked up to a progress bar at a later time.
            this.defaultTab.invokeDataRetrievalProcess();
        }

        if (changes['tab']) {
            this.setTabPosition();
        }
    }

    public selectChange(event: MatTabChangeEvent): void {
        this.activeTabIndex = event.index;
        const schemaByTab = this.tabsToDisplay.find((x) => x.tabSchema.name === event.tab.textLabel);
        if (schemaByTab) {
            this.appInsightLog(schemaByTab.tabSchema.name);
            this.drillDownStateService.setTabNameForCurrentState(schemaByTab.tabSchema.name);
            const serviceExecution = schemaByTab.invokeDataRetrievalProcess();
            if (serviceExecution) {
                serviceExecution.subscribe((x) => {
                    this.ref.markForCheck();
                });
            }
        }
    }

    public isHidden(tabSchema: DrillDownTabSchema): boolean {
        let result = false;

        if (typeof tabSchema.isHidden === 'boolean') {
            result = tabSchema.isHidden;
        } else if (typeof tabSchema.isHidden === 'function') {
            const isHiddenProvider: DrillDownIsHiddenFunction = tabSchema.isHidden;
            let dataSchema = tabSchema.dataSchema;
            if (!dataSchema && tabSchema.deferredDataProviderSchema) {
                dataSchema = tabSchema.deferredDataProviderSchema.schema;
            }
            result = isHiddenProvider(this.currentData, dataSchema, this.config);
        }

        return result;
    }

    private setTabPosition(): void {
        if (this.tab && this.tabsToDisplay) {
            const visibleTabs = this.tabsToDisplay.filter((x) => !this.isHidden(x.tabSchema));
            const tabIndex = visibleTabs.findIndex((x) => x.tabSchema.name === this.tab);
            if (tabIndex > -1) {
                this.activeTabIndex = tabIndex;
                const schemaByTab = visibleTabs[tabIndex];

                const serviceExecution = schemaByTab.invokeDataRetrievalProcess();
                if (serviceExecution) {
                    serviceExecution.subscribe((x) => {
                        this.ref.markForCheck();
                    });
                }

                // Log the default tab when landing to the page.
                this.appInsightLog(schemaByTab.tabSchema.name);
                return;
            }
        }

        this.activeTabIndex = 0;
        this.drillDownStateService.setTabNameForCurrentState(this.currentSchema.tabName);
    }

    private prepareTabs() {
        const currentSchema = this.currentSchema;
        if (currentSchema.otherTabs) {
            currentSchema.otherTabs.forEach((tab) => {
                if (!tab.dataSchema && tab.dataFieldName) {
                    const upperCaseFieldName = tab.dataFieldName.toUpperCase();
                    const propertySchema = this.currentSchema.propertySchemas.find((x) => {
                        return x.name && x.name.toUpperCase() === upperCaseFieldName;
                    });

                    if (propertySchema) {
                        tab.dataSchema = propertySchema.schema;
                    }
                }

                const currentTabState = this.drillDownDeferredDataProviderService.getTabDataRetrievalState(tab, this.currentConfig, this.currentData);
                this.tabsToDisplay.push(currentTabState);

                if (this.isHidden(this.defaultTab.tabSchema)) {
                    this.defaultTab = currentTabState;
                }
            });
        }
    }

    private appInsightLog(content) {
        const section = this.currentSchema.section;
        const view = this.currentSchema.view;
        let contentName = `Tab ${content}`;
        if (section && view) {
            contentName = `[${section} ${view}] ` + contentName;
        }
        this.logService.logEvent('Tab Clicked', {
            areaName: 'DefaultTabbedViewer',
            contentName: contentName,
        });
    }
}
