<div class="card mast-json-viewer-panel">
    <div class="card-header mast-json-viewer-header">
        <nav class="navbar navbar-expand-sm navbar-dark text-white common-menu-banner">
            <span class="navbar-brand navbar-brand-combo">JSON Viewer</span>
            <div class="ml-auto">
                <a (click)="fullScreen()" title="Full Screen"><span class="fullscreen icon-gap icon-hover pointer"></span></a>
                <a (click)="toggle()" title="Close"><span class="close-menu icon-gap icon-hover pointer"></span></a>
            </div>
        </nav>
    </div>
    <div class="card-body mast-json-viewer-panel-body">
        <mast-json-editor-wrapper [ngModel]="currentValue | async" [options]="jsonViewerOptions"></mast-json-editor-wrapper>
    </div>
    <button class="btn btn-outline-primary mr-auto export-button" (click)="exportJson()">Export</button>
</div>
