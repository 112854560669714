<div [formGroup]="form">
    <mat-form-field class="mast-textarea">
        <textarea
            matInput
            [formControlName]="controlName"
            [placeholder]="label"
            [required]="required"
            (blur)="trim()"
            (keydown.enter)="trim()"
            (keydown.shift.enter)="trim()"
            data-bi-dnt="true"
            rows="4"
        ></textarea>
        <mat-error>
            {{ errorMessage }}
        </mat-error>
    </mat-form-field>
</div>
