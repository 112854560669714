import { Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { SearchCriteriaValues, SearchStatus } from '../../../models/search-criteria-values';
import { LookupService } from '../../../shared-services/lookup/lookup.service';
import { applyParameterReplacement, finalizeLink } from '../../../utils/lookup-tools';
import { formatStringToGuid } from '../../../utils/utils';
import { ConsumerRootSearchAction, ConsumerRootSearchResetAction } from '../consumer-root/consumer-root-search.action';
import { selectConsumerAccountProfileState, selectConsumerRootState } from '../consumer.selector';
import { ConsumerStates } from '../consumer.states';

export const ConsumerLookupServiceToken = new InjectionToken<LookupService>('ConsumerLookupServiceToken');

@Injectable()
export class ConsumerLookupService extends LookupService {
    public static readonly consumerRootIdProperty = 'puid';
    public static readonly msahwIdProperty = 'msahw';
    public static readonly jidIdProperty = 'jid';
    private static readonly xuidIdProperty = 'xuid';
    private static readonly gamerTagIdProperty = 'gamertag';
    private static readonly genericIdProperty = 'id';
    private static readonly msaMethod = 'getmsa';
    private static readonly hexPuidMethod = 'getmsafrompuidhex';
    private static readonly gamerTagMethod = 'getmsafromgamertag';
    private static readonly xuidMethod = 'getmsafromxuid';
    private static readonly msahwMethod = 'getmsahw';
    private static readonly jidMethod = 'getjid';

    private static readonly environmentProperty = 'environment';
    private static readonly methodProperty = 'method';

    private consumerRoot: any;
    private consumerRootObservable: Observable<any>;
    private enterpriseObservable: Observable<any>;

    constructor(private store: Store<ConsumerStates>) {
        super();
        this.consumerRootObservable = this.store.select(selectConsumerRootState);
        this.enterpriseObservable = this.store.select(selectConsumerAccountProfileState);
    }

    public reset(): void {
        this.store.dispatch(new ConsumerRootSearchResetAction());
    }

    /**
     * This will transform abstract active links to its more definitve form.
     * @param link The abastract link.
     */
    public convertLink(link: string, searchCriteria: SearchCriteriaValues): string {
        let newLink = link;
        if (searchCriteria) {
            for (const propertyName in searchCriteria) {
                if (searchCriteria.hasOwnProperty(propertyName)) {
                    let linkPropertyName = propertyName;
                    if (
                        propertyName === ConsumerLookupService.consumerRootIdProperty ||
                        propertyName === ConsumerLookupService.xuidIdProperty ||
                        propertyName === ConsumerLookupService.gamerTagIdProperty ||
                        propertyName === ConsumerLookupService.msahwIdProperty ||
                        propertyName === ConsumerLookupService.jidIdProperty
                    ) {
                        linkPropertyName = ConsumerLookupService.genericIdProperty;
                    }

                    newLink = applyParameterReplacement(newLink, propertyName, linkPropertyName, searchCriteria[propertyName]);
                }
            }

            newLink = finalizeLink(newLink);
        }

        return newLink;
    }

    /**
     * Performs the actual search for Consumer filter lookups.
     */
    public performLookup(searchCriteria: SearchCriteriaValues): Observable<SearchCriteriaValues> {
        if (searchCriteria) {
            const searchXuidXboxId = this.getStandardizedParameter(searchCriteria, ConsumerLookupService.xuidIdProperty);
            const searchGamerTagXboxId = this.getStandardizedParameter(searchCriteria, ConsumerLookupService.gamerTagIdProperty);
            const searchConsumerRootId = this.getStandardizedParameter(searchCriteria, ConsumerLookupService.consumerRootIdProperty);
            const searchMSAHWId = this.getStandardizedParameter(searchCriteria, ConsumerLookupService.msahwIdProperty);
            const searchJIDId = this.getStandardizedParameter(searchCriteria, ConsumerLookupService.jidIdProperty);

            const environmentSearch = searchCriteria.environment as string;
            let method = searchCriteria.method;
            method = method ? method.toLowerCase() : null;
            if (method === ConsumerLookupService.msaMethod || method === ConsumerLookupService.hexPuidMethod) {
                let adjustedInput = searchConsumerRootId + '';

                const isPuidInput = this.IsPuidInput(adjustedInput);
                const isEmailInput = this.IsEmailInput(adjustedInput);
                const isHexInput = method === ConsumerLookupService.hexPuidMethod;

                if (isPuidInput || isEmailInput || isHexInput) {
                    adjustedInput = adjustedInput.trim();

                    // If the string has a p: in front of it, trim it off
                    if (!this.IsEmailInput(adjustedInput)) {
                        const regEx = /^p:(\w+)/i;
                        const regExResult = regEx.exec(adjustedInput);

                        if (regExResult != null && regExResult.length > 1) {
                            adjustedInput = regExResult[1];
                        }
                    }

                    if (isHexInput) {
                        adjustedInput = this.ConvertHexToDecimal(adjustedInput).toString();
                    }
                }

                this.store.dispatch(new ConsumerRootSearchAction(environmentSearch, adjustedInput, method));
                return this.consumerRootObservable.pipe(
                    skipWhile((searchState) => !searchState.searchCompleted),
                    map((searchState) => searchState.results),
                    map((consumerRootItem: any) => {
                        if (consumerRootItem && consumerRootItem.Accounts.Account.HRESULT === '0x0') {
                            this.consumerRoot = consumerRootItem;
                            searchCriteria[ConsumerLookupService.consumerRootIdProperty] = this.ConvertHexToDecimal(consumerRootItem.Accounts.Account.NetID);
                            searchCriteria.searchStatus = SearchStatus.SearchSuccessful;
                        } else {
                            searchCriteria.searchStatus = SearchStatus.SearchFailed;
                        }

                        return searchCriteria;
                    })
                );
            } else if (method === ConsumerLookupService.xuidMethod || method === ConsumerLookupService.gamerTagMethod) {
                const adjustedIp = method === ConsumerLookupService.xuidMethod ? searchXuidXboxId + '' : searchGamerTagXboxId + '';

                this.store.dispatch(new ConsumerRootSearchAction(environmentSearch, adjustedIp, method));
                return this.consumerRootObservable.pipe(
                    skipWhile((searchState) => !searchState.searchCompleted),
                    map((searchState) => searchState.results),
                    map((consumerRootItem: any) => {
                        if (consumerRootItem) {
                            this.consumerRoot = consumerRootItem;
                            searchCriteria[ConsumerLookupService.consumerRootIdProperty] = consumerRootItem.Puid;
                            searchCriteria[ConsumerLookupService.xuidIdProperty] = consumerRootItem.Xuid;
                            searchCriteria[ConsumerLookupService.gamerTagIdProperty] = consumerRootItem.GamerTag;
                            searchCriteria.searchStatus = SearchStatus.SearchSuccessful;
                        } else {
                            searchCriteria.searchStatus = SearchStatus.SearchFailed;
                        }

                        return searchCriteria;
                    })
                );
            } else if (method === ConsumerLookupService.msahwMethod) {
                return this.consumerRootObservable.pipe(
                    map((consumerRootItem: any) => {
                        searchCriteria[ConsumerLookupService.consumerRootIdProperty] = 'msahw:' + searchMSAHWId;
                        searchCriteria[ConsumerLookupService.msahwIdProperty] = 'msahw:' + searchMSAHWId;
                        searchCriteria.searchStatus = SearchStatus.SearchSuccessful;
                        return searchCriteria;
                    })
                );
            } else if (method === ConsumerLookupService.jidMethod) {
                return this.consumerRootObservable.pipe(
                    map((consumerRootItem: any) => {
                        searchCriteria[ConsumerLookupService.jidIdProperty] = 'jid:' + searchJIDId;
                        searchCriteria[ConsumerLookupService.consumerRootIdProperty] = 'jid:' + searchJIDId;
                        searchCriteria.searchStatus = SearchStatus.SearchSuccessful;
                        return searchCriteria;
                    })
                );
            }
        }

        searchCriteria = new SearchCriteriaValues();
        searchCriteria.searchStatus = SearchStatus.SearchFailed;
        return of(searchCriteria);
    }

    public IsEmailInput(input) {
        const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        return emailRegex.test(input);
    }

    public IsPuidInput(input) {
        const numberRegex = /^(p:)?[0-9]+$/;
        return numberRegex.test(input);
    }

    public IsOnlyNumericInput(input) {
        const numberRegex = /^\d+$/;
        return numberRegex.test(input);
    }

    // returns true if string is definitely hexadecimal and not decimal
    public IsDefinitelyHex(input) {
        if (this.IsPuidInput(input)) {
            return false;
        }
        const hexRegex = /(^(p:)?(0x)?[0-9a-f]+$)/i;
        return hexRegex.test(input);
    }

    public ConvertHexToDecimal(input) {
        return parseInt(input, 16);
    }

    /**
     * Creates a SearchCriteriaValues based on URL/Router parameters.
     * @param params URL/Router parameters.
     */
    public produceSearchCriteriaValues(snapshot: ActivatedRouteSnapshot): SearchCriteriaValues {
        const params = snapshot.params;
        const searchCriteriaValues: SearchCriteriaValues = new SearchCriteriaValues();
        if (params[ConsumerLookupService.environmentProperty]) {
            searchCriteriaValues.environment = params[ConsumerLookupService.environmentProperty];
        }

        let method: string = params[ConsumerLookupService.methodProperty];
        searchCriteriaValues.method = method;
        if (method) {
            method = method.toLowerCase();
            if (method === ConsumerLookupService.msaMethod) {
                searchCriteriaValues[ConsumerLookupService.consumerRootIdProperty] = params[ConsumerLookupService.genericIdProperty];
                searchCriteriaValues.wasGeneratedFromParams = true;
            } else if (method === ConsumerLookupService.gamerTagIdProperty) {
                searchCriteriaValues[ConsumerLookupService.gamerTagIdProperty] = params[ConsumerLookupService.genericIdProperty];
                searchCriteriaValues.wasGeneratedFromParams = true;
            } else if (method === ConsumerLookupService.xuidIdProperty) {
                searchCriteriaValues[ConsumerLookupService.xuidIdProperty] = params[ConsumerLookupService.genericIdProperty];
                searchCriteriaValues.wasGeneratedFromParams = true;
            } else if (method === ConsumerLookupService.msahwMethod) {
                searchCriteriaValues[ConsumerLookupService.msahwIdProperty] = params[ConsumerLookupService.genericIdProperty];
                searchCriteriaValues.wasGeneratedFromParams = true;
            } else if (method === ConsumerLookupService.jidIdProperty) {
                searchCriteriaValues[ConsumerLookupService.jidIdProperty] = params[ConsumerLookupService.genericIdProperty];
                searchCriteriaValues.wasGeneratedFromParams = true;
            } else {
                throw new ErrorEvent(`Unsupported method: ${method}`);
            }
        } else {
            searchCriteriaValues[ConsumerLookupService.consumerRootIdProperty] = params[ConsumerLookupService.consumerRootIdProperty];
            searchCriteriaValues.wasGeneratedFromParams = true;
            searchCriteriaValues.method = ConsumerLookupService.msaMethod;
        }

        return searchCriteriaValues;
    }

    /**
     * Gets a standardized (e.g. trimed) value for any property declared.
     * @param searchCriteria The search criteria object.
     * @param propertyName The name of the property to standardize.
     */
    private getStandardizedParameter(searchCriteria: SearchCriteriaValues, propertyName: string): string {
        const id: string = searchCriteria[propertyName] as string;
        if (id) {
            searchCriteria[propertyName] = formatStringToGuid(id);
        }
        return id;
    }
}
