import { Action } from '@ngrx/store';

import * as SM from '../../../models/state-manager/state-manager-search.actions';
import { MastResult } from '../../../models/dal/mast-result';
import { UsersRolesParameters } from '../../models/user-and-roles-result';

export const SEARCH = '[UsersRoless] Search';
export const REFRESH = '[UsersRoless] Refresh';
export const RESET = '[UsersRoless] Reset';
export const SEARCH_SUCCESSFUL = '[UsersRoless] Successful';
export const SEARCH_FAILED = '[UsersRoless] Failed';

export class UsersRolesSearchActions implements SM.StateManagerSearchAction<UsersRolesParameters> {
    readonly type = SEARCH;
    constructor(public parameters: UsersRolesParameters) {}
}

export class UsersRolesRefreshActions implements SM.StateManagerSearchRefreshAction {
    readonly type = REFRESH;

    constructor() {}
}

export class UsersRolesResetActions implements SM.StateManagerSearchResetAction {
    readonly type = RESET;

    constructor() {}
}

export class UsersRolesSearchSuccessfulAction implements SM.StateManagerSuccessfulAction<MastResult> {
    readonly type = SEARCH_SUCCESSFUL;
    constructor(public result: MastResult) {}
}

export class UsersRolesSearchFailedAction implements SM.StateManagerSearchFailedAction {
    readonly type = SEARCH_FAILED;
    constructor(public errorCode: string, public errorMessage) {}
}
