<div [formGroup]="form">
    <div [formArrayName]="formArrayName" class="row" *ngFor="let control of items.controls; let i = index">
        <div class="col">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { parent: getItem(i) }"></ng-container>
        </div>
        <div class="col">
            <button *ngIf="i > 0" mat-icon-button (click)="removeItem(i)">
                <span class="close-menu"></span>
            </button>
        </div>
    </div>
    <button mat-raised-button (click)="addItem()" type="button">Add +</button>
</div>
