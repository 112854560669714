import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormFieldBaseDirective } from '../form-field-base/form-field-base';
@Component({
    selector: 'mast-form-textarea',
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.css'],
})
export class FormTextareaComponent extends FormFieldBaseDirective {
    @Input()
    public splitOn: string;

    constructor() {
        super();
    }

    trim() {
        const control: AbstractControl = this.form.get(this.controlName);
        if (control.value) {
            let newValue = '';
            if (this.splitOn) {
                newValue = this.trimList(control.value);
            } else {
                newValue = control.value.trim();
            }
            control.setValue(newValue);
        }
    }

    private trimList(value: string): string {
        const newValue = value
            .split(this.splitOn)
            .filter((item) => item !== '' && item !== ' ')
            .map((item) => item.trim())
            .join(this.splitOn);

        return newValue;
    }
}
