import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConsumerLookupService, ConsumerLookupServiceToken } from '../consumer/services/lookup/consumer-lookup.service';
import { EnterpriseLookupService, EnterpriseLookupServiceToken } from '../enterprise/services/lookup/enterprise-lookup.service';
import { NoopLookupService } from '../shared-services/lookup/noop-lookup.service';
import { NoopLookupServiceToken } from '../tools/tools-application-context-item';

@NgModule({
    imports: [CommonModule],
    providers: [
        { provide: EnterpriseLookupServiceToken, useClass: EnterpriseLookupService },
        { provide: ConsumerLookupServiceToken, useClass: ConsumerLookupService },
        { provide: NoopLookupServiceToken, useClass: NoopLookupService },
    ],
})
export class LookupModule {}
