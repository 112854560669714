<nav class="navbar navbar-expand-xl navbar-dark bg-dark" aria-label="Mast main navigation bar">
    <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        data-bi-area="MastNavBar"
        data-bi-name="ExpandNavBar"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <a
        class="navbar-brand navbar-brand-combo"
        routerLink="/"
        routerLinkActive="active"
        data-toggle="tooltip"
        title="Your one stop shop for debugging commercial transations."
        data-bi-area="MastNavBar"
        data-bi-name="Mast_Home"
    >
        <img src="./assets/MastLogo.png" class="brand-logo" alt="MAST logo, a white sailing boat on a blue background" />
        <div class="brand-text">MAST</div>
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ng-container *ngIf="allRequirementsSatisfied | async">
            <ul class="navbar-nav mr-auto col-auto">
                <li class="nav-item" *ngFor="let contextItem of contextItems">
                    <ng-container *ngIf="isNavbarItemVisible(contextItem) | async" [ngSwitch]="contextItem.isRedirect">
                        <a class="nav-link app-context-link" [href]="contextItem.url" *ngSwitchCase="true" data-bi-area="MastNavBar">{{ contextItem.name }}</a>
                        <a
                            class="nav-link app-context-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            [routerLink]="contextItem.url"
                            data-bi-area="MastNavBar"
                            *ngSwitchDefault
                            >{{ contextItem.name }}</a
                        >
                    </ng-container>
                </li>
            </ul>
            <mast-search class="container" [applicationContext]="currentContext"></mast-search>
            <ul class="navbar-nav mr-auto col-auto">
                <li class="nav-item">
                    <a class="nav-link" (click)="actuateSettings()" title="Settings" id="settings" data-bi-area="MastNavBar">
                        <span class="setting-menu icon-hover"></span><span class="badge badge-pill badge-success">{{ settingsService.environment }}</span>
                    </a>
                </li>
                <li class="nav-item col-auto">
                    <a class="nav-link" (click)="actuateDebugger()" title="Debug" id="debugger" data-bi-area="MastNavBar">
                        <span class="debug-menu icon-hover"></span
                        ><span
                            class="badge badge-pill pointer hover"
                            [class.badge-danger]="debuggingService.errorCount > 0"
                            [class.badge-dark]="debuggingService.errorCount == 0"
                            *ngIf="debuggingService"
                            >{{ debuggingService.errorCount }}</span
                        >
                    </a>
                </li>
            </ul>
        </ng-container>
        <div class="navbar-text col-auto">
            {{ userGreetings | async }}
        </div>
    </div>
</nav>
