<div>
    <div class="modal-header debug-banner text-white" style="text-align: center">
        <h3 class="modal-title">Debugging</h3>
    </div>
    <div class="modal-body">
        <div id="accordionOuter" class="debug-viewer" role="tablist">
            <mast-debug-external-request
                *ngFor="let request of pagedExternalRequests; let i = index"
                [request]="request"
                [index]="i"
                accordionName="accordionOuter"
            ></mast-debug-external-request>
        </div>
        <ng-container *ngIf="debuggingService && debuggingService.logs">
            <ngb-pagination
                [collectionSize]="debuggingService.logs.length"
                [(page)]="currentPage"
                [pageSize]="pageSize"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="true"
                [boundaryLinks]="false"
                (pageChange)="pageChange()"
            ></ngb-pagination>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close')">Close</button>
    </div>
</div>
