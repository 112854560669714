import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../app.states';
import { LogService } from '../../shared-services/log.service';
import { AuthenticationCompleteAction, AuthenticationSearchAction, INITIALIZE, SEARCH } from './authentication.actions';
import { AuthenticationService } from './authentication.service';
import { AuthenticationState } from './authentication.state';

@Injectable()
export class AuthenticationEffects {
    constructor(private actions$: Actions, private appState: Store<AppState>, private authService: AuthenticationService, private logService: LogService) {}

    @Effect()
    search$: Observable<Action> = this.actions$.pipe(
        ofType(SEARCH),
        map((action: AuthenticationSearchAction) => {
            this.authService.msalLogin();
            return new AuthenticationCompleteAction(null, null, false);
        })
    );

    // the entry of the login process.
    // if the user is authenticated, fires AuthenticationCompletedAction
    // if the user is not authenticated, fires AuthenticationSearchAction -> search effect will trigger login function.
    @Effect()
    login$: Observable<Action> = this.actions$.pipe(
        ofType(INITIALIZE),
        withLatestFrom(this.appState),
        switchMap(([action, state]) => {
            if (state.authenticationState.isComplete && state.authenticationState.isAuthenticated) {
                return of(state);
            }
            const msalUserInfo = this.authService.setActiveAccount();
            if (msalUserInfo && msalUserInfo.username) {
                this.logService.appInsights.setAuthenticatedUserContext(msalUserInfo.username);
                return of(msalUserInfo);
            }
            return of(null);
        }),
        map((userInfo: any) => {
            if (!userInfo) {
                return new AuthenticationSearchAction();
            } else if ('profile' in userInfo) {
                return new AuthenticationCompleteAction(userInfo.profile ? userInfo.profile.name : 'Unknown', userInfo.userName, true);
            } else {
                const authState = userInfo as AuthenticationState;
                return new AuthenticationCompleteAction(authState.name, authState.username, true);
            }
        })
    );
}
