import * as _ from 'lodash-es';
import { DrillDownPropertySchema } from '../models/schemas/drill-down-property-schema';

/**
 * This is a utility class for converting objects into property schemas and
 * tranforming property names into something easily readable.
 */
export class TransformationUtils {
    /** Regular expression used to match words within property names by
     * `transformPropertyName`.  This matches:
     *   - upper-case acronyms (example: "MAST"),
     *   - capitalized words (example: "Transformation"), and
     *   - lower-case words (example: "expression").
     *
     * Please see the tests for `transformPropertyName` for more examples.
     */
    static PROPERTY_NAME_WORD_REGEXP = /[A-Z][^-_ a-z]+(?![-_ a-z])|[A-Z][^-_ A-Z]+|[^-_ A-Z]+/g;

    /**
     * Generates property schemas to represent all elements of an array.
     * @param {Array<Object>} data The array to be examined.
     * @returns {Array<T>} Schema of all of the properties reviewed in an array of objects.
     */
    public static generateSchemaForArrayObjects(data: Array<Object>): Array<DrillDownPropertySchema> {
        let results = new Array<DrillDownPropertySchema>();

        if (data) {
            const holder: any = {};
            let isFirst = true;
            data.forEach((dataElement: any) => {
                if (dataElement !== null && dataElement !== undefined && typeof dataElement === 'object') {
                    for (const key in dataElement) {
                        if (dataElement.hasOwnProperty(key)) {
                            const propertyValue = dataElement[key];
                            if (!holder[key]) {
                                const newField = {} as DrillDownPropertySchema;
                                isFirst = this.processPropertySchema(newField, propertyValue, key, isFirst);
                                results.push(newField);
                                holder[key] = newField;
                            }
                        }
                    }
                }
            });
        }

        if (results.length === 0) {
            results = null;
        }

        return results;
    }

    /**
     * Changes camel/pascal case to a human readable format.
     * @param {string} originalValue The original property name.
     * @returns {string} A human readable version of the property name.
     */
    public static transformPropertyName(originalValue: string): string {
        // instead of introducing a conditional in the loop below, we'll simply start out with
        // an empty separator that we reassign.
        let sep = '';
        let output = '';
        let match: RegExpExecArray;

        while ((match = TransformationUtils.PROPERTY_NAME_WORD_REGEXP.exec(originalValue)) !== null) {
            const word = match[0];
            output += sep + word[0].toUpperCase() + word.slice(1);
            sep = ' ';
        }

        return output;
    }

    /**
     * Aguments all of the property schemas that were provided by the user and may be missing vital metadata.
     * @param data The value of the object which is represented by the property schema items. It can be an array or an
     *             object.
     * @param propertiesProvided The user provided property schemas.
     */
    public static completePropertySchemas(data: any, propertiesProvided: Array<DrillDownPropertySchema>): void {
        if (propertiesProvided) {
            if (data) {
                if (data instanceof Array) {
                    if (data.length) {
                        data.forEach((dataElement) => {
                            this.augmentPropertySchemaForASingleObject(dataElement, propertiesProvided);
                        });
                    } else {
                        propertiesProvided
                            .filter((x) => x && !x.title)
                            .forEach((x) => {
                                x.title = TransformationUtils.transformPropertyName(x.name);
                            });
                    }
                } else {
                    this.augmentPropertySchemaForASingleObject(data, propertiesProvided);
                }
            } else {
                this.augmentPropertySchemaForASingleObject(data, propertiesProvided);
            }
        }
    }

    /**
     * Used to merge explicitly provided property schemas with an object's overall schema values.
     * This is used to 'fill in the holes in an object's metadata'.
     * @param data An instance of the data.  Its properties metadata will be extracted from it.
     * @param fieldsProvided The metadata provided by the user.
     */
    public static mergePropertySchemas(data: Array<any>, fieldsProvided: Array<DrillDownPropertySchema>): Array<DrillDownPropertySchema> {
        const results = fieldsProvided ? fieldsProvided.slice() : new Array<DrillDownPropertySchema>();
        const schemaFields = TransformationUtils.generateSchemaForArrayObjects(data);
        if (schemaFields && schemaFields.length > 0) {
            const hashSet = {};
            if (fieldsProvided) {
                fieldsProvided
                    .filter((x) => x)
                    .forEach((propertySchema) => {
                        hashSet[propertySchema.name] = propertySchema;
                    });
            }

            const identifiers = results.filter((x) => x.isIdentifier || x.isHumanReadableId);
            const identifierField = identifiers.find((x) => x.isIdentifier);
            const humanReadableId = identifiers.find((x) => x.isHumanReadableId);

            schemaFields.forEach((propertySchema) => {
                const originalSchema = hashSet[propertySchema.name];
                if (!originalSchema) {
                    if (propertySchema.isIdentifier) {
                        if (identifierField) {
                            propertySchema.isIdentifier = false;
                        }
                    }

                    if (propertySchema.isHumanReadableId) {
                        if (humanReadableId) {
                            propertySchema.isHumanReadableId = false;
                        }
                    }

                    hashSet[propertySchema.name] = propertySchema;
                    results.push(propertySchema);
                } else {
                    if (!originalSchema.title) {
                        originalSchema.title = propertySchema.title;
                    }

                    if (!('isBoolean' in originalSchema)) {
                        originalSchema.isBoolean = propertySchema.isBoolean;
                    }

                    if (!('isPrimitive' in originalSchema)) {
                        originalSchema.isPrimitive = propertySchema.isPrimitive;
                    }

                    if (!('isIdentifier' in originalSchema) && !identifierField) {
                        originalSchema.isIdentifier = propertySchema.isIdentifier;
                    }

                    if (!('isHumanReadableId' in originalSchema) && !humanReadableId) {
                        originalSchema.isHumanReadableId = propertySchema.isHumanReadableId;
                    }
                }
            });
        } else {
            this.augmentPropertySchemaForASingleObject(data, fieldsProvided);
        }

        return results;
    }

    /**
     * Aguments all of the property schemas that were provided by the user and may be missing vital metadata with a
     * single object's value.
     * @param dataElement The value of the object which is represented by the property schema items.
     * @param propertiesProvided The user provided property schemas.
     */
    private static augmentPropertySchemaForASingleObject(dataElement: any, propertiesProvided: Array<DrillDownPropertySchema>) {
        if (propertiesProvided && propertiesProvided.length > 0) {
            let idProperty: DrillDownPropertySchema;
            let humanReadableIdProperty: DrillDownPropertySchema;
            propertiesProvided.forEach((property) => {
                const valueOfProperty = dataElement != null ? _.get(dataElement, property.name) : null;
                this.processPropertySchema(property, valueOfProperty);
                if (property.isIdentifier) {
                    if (!idProperty) {
                        idProperty = property;
                    } else {
                        throw new Error(`This schema contains two primary identifiers: '${idProperty.name}' and '${property.name}'!`);
                    }
                }

                if (property.isHumanReadableId) {
                    if (!humanReadableIdProperty) {
                        humanReadableIdProperty = property;
                    } else {
                        throw new Error(`This schema contains two primary identifiers: '${humanReadableIdProperty.name}' and '${property.name}'!`);
                    }
                }
            });

            if (!humanReadableIdProperty) {
                if (!idProperty) {
                    // Finds the first primitive property and delegate it as the identifier.
                    const firstPrimitiveProperty = propertiesProvided.find((x) => x.isPrimitive);
                    if (!firstPrimitiveProperty) {
                        throw new Error('No identifiers were found in the schema provided!');
                    } else {
                        firstPrimitiveProperty.isHumanReadableId = true;
                        firstPrimitiveProperty.isIdentifier = true;
                    }
                } else {
                    idProperty.isHumanReadableId = true;
                }
            } else {
                if (!idProperty) {
                    humanReadableIdProperty.isIdentifier = true;
                }
            }
        }
    }

    /**
     * Augments the property schema.
     * @param propertySchema The Property Schema that may need additional metadata.
     * @param propertyValue The value of the property.
     * @param propertyName The name of the property, if not already provided.
     * @param isFirst Indicates if this is the first property encountered.
     */
    private static processPropertySchema(propertySchema: DrillDownPropertySchema, propertyValue: any, propertyName?: string, isFirst?: boolean): boolean {
        if (propertyName) {
            propertySchema.name = propertyName;
        }

        if (propertySchema.title == null) {
            if (!propertyName) {
                propertyName = propertySchema.name;
            }

            propertySchema.title = this.transformPropertyName(propertyName);
        }

        if (propertySchema.aggregationFunction) {
            propertySchema.isPrimitive = true;
        }

        if (propertyValue !== null && propertyValue !== undefined) {
            const propertyType = typeof propertyValue;
            if (propertySchema.isBoolean == null) {
                propertySchema.isBoolean = propertyType === 'boolean';
            }

            if (propertySchema.isPrimitive == null) {
                propertySchema.isPrimitive = propertySchema.isBoolean || propertyType === 'string' || propertyType === 'number';
            }

            if (isFirst) {
                if (propertySchema.isPrimitive && propertySchema.isHumanReadableId == null && propertySchema.isIdentifier == null) {
                    isFirst = false;
                    propertySchema.isHumanReadableId = true;
                    propertySchema.isIdentifier = true;
                }
            }
        }

        return isFirst;
    }
}
