import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MastJsonEditorWrapperComponent } from './mast-json-editor-wrapper.component';

@NgModule({
    imports: [CommonModule],
    declarations: [MastJsonEditorWrapperComponent],
    exports: [MastJsonEditorWrapperComponent],
})
export class MastJsonEditorWrapperModule {}
