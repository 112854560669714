import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../../shared-services/settings.service';
import { DrillDownConfig } from '../drill-down-viewer/models/drill-down-config';
import { DrillDownCustomMenu } from '../drill-down-viewer/models/drill-down-custom-menu';
import { DrillDownObjectSchema } from '../drill-down-viewer/models/schemas/drill-down-object-schema';

@Component({
    selector: 'mast-shared-product-navigation',
    templateUrl: './shared-product-navigation.component.html',
    styleUrls: ['./shared-product-navigation.component.css'],
})
export class SharedProductNavigationComponent implements OnInit, DrillDownCustomMenu {
    constructor(private settingsService$: SettingsService) {}

    @Input()
    public parentData: any;

    @Input()
    public data: any;

    @Input()
    public schema: DrillDownObjectSchema;

    @Input()
    public config: DrillDownConfig;

    public get productExplorerUrl(): string {
        return 'https://explorer-co-prodpme-app.azurewebsites.net/productexplorer';
    }

    public get pdpUrl(): string {
        const productId = this.data.ProductId;
        return `https://www.microsoft.com/store/apps/${productId}`;
    }

    public ngOnInit(): void {}
}
