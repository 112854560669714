import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import * as Lodash from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogService } from '../../../shared-services/log.service';
import { DrillDownConfig, DrillDownConfigDefaults } from '../models/drill-down-config';
import { DrillDownCustomMenu } from '../models/drill-down-custom-menu';
import { DrillDownNavState } from '../models/drill-down-nav-state';
import { DrillDownState } from '../models/drill-down-state.enum';
import { DrillDownViewer } from '../models/drill-down-viewer';
import { GenericDefaultViewerDirective } from '../models/generic-default-viewer';
import { NavStateInitializerResponse } from '../models/nav-state-initializer-response';
import { DrillDownArraySchema, DrillDownArraySchemaDefaults } from '../models/schemas/drill-down-array-schema';
import { DrillDownObjectSchema } from '../models/schemas/drill-down-object-schema';
import { DrillDownPropertySchema } from '../models/schemas/drill-down-property-schema';
import { DrillDownStateService } from '../service/drill-down-state.service';
import { TransformationUtils } from '../utils/transformation-utils';
import { DefaultObjectViewerComponent } from './default-object-viewer/default-object-viewer.component';

/**
 * This component is used for displaying complex data in a combo list/details format.
 */
@Component({
    selector: 'mast-drill-down-viewer',
    templateUrl: './drill-down-viewer.component.html',
    styleUrls: ['./drill-down-viewer.component.css'],
    // tslint:disable-next-line: use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('menuState', [
            state(
                'summary',
                style({
                    width: '100%',
                    'min-width': '100%',
                    'max-width': '100%',
                })
            ),
            state(
                'split',
                style({
                    width: '20%',
                    'min-width': '300px',
                    'max-width': '300px',
                })
            ),
            state(
                'detail',
                style({
                    width: '0px',
                    'min-width': '0px',
                    'max-width': '0px',
                })
            ),
            transition('summary => split', animate('100ms ease-in')),
            transition('split => summary', animate('100ms ease-in')),
            transition('split => detail', animate('100ms ease-in')),
            transition('detail => split', animate('100ms ease-in')),
        ]),
        trigger('detailViewerState', [
            state(
                'summary',
                style({
                    width: '0px',
                    'min-width': '0px',
                })
            ),
            state(
                'split',
                style({
                    width: '100%',
                    'min-width': '80px',
                    'max-width': '100%',
                })
            ),
            state(
                'detail',
                style({
                    'min-width': '100%',
                    width: '100%',
                    'max-width': '100%',
                })
            ),
            transition('summary => split', animate('100ms ease-in')),
            transition('split => summary', animate('100ms ease-in')),
            transition('split => detail', animate('100ms ease-in')),
            transition('detail => split', animate('100ms ease-in')),
        ]),
    ],
})
export class DrillDownViewerComponent implements OnInit, OnChanges, DrillDownViewer<DrillDownArraySchema> {
    //// Internal properties.
    private refinedFields = new Array<DrillDownPropertySchema>();
    private humanReadableIdField: DrillDownPropertySchema;
    private selectedDataElement: object;
    private displayablePropertyName = 'Home';
    private viewableComponent: Type<any> = DefaultObjectViewerComponent;
    private navState$: Subscription;
    private currentStateInternal: DrillDownState = DrillDownState.Summary;

    //// All angular visible fields.
    public currentSchema: DrillDownArraySchema;
    public drillDownStateOptions: typeof DrillDownState = DrillDownState;
    public currentDepth = 0;
    public idField: DrillDownPropertySchema;
    public dataElement: object;
    public displayableData = new Array<any>();
    public currentNavState: DrillDownNavState;
    public currentSelection: number[] = [];
    public hideSummaryPane = false;
    public primitiveTableFields = new BehaviorSubject<DrillDownPropertySchema[]>(this.refinedFields);
    public tabName: string;
    public isReady = false;
    public isOperational = true;
    public errorMessage = '';
    public buttonCount = 5;
    public info = true;
    public pagerType: 'numeric' | 'input' = 'numeric';
    public pageSizes = true;
    public previousNext = true;
    public currentPageSize = 10;
    public skip = 0;

    /**
     * Used as a template holder for any custom detail containers.
     */
    @ViewChild('detailView', { read: ViewContainerRef })
    public dynamicView: ViewContainerRef;

    /**
     * Used as a template holder for any custom detail containers.
     */
    @ViewChild('drillDownCustomMenu', { read: ViewContainerRef })
    public customMenu: ViewContainerRef;

    constructor(
        public navStateService: DrillDownStateService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private logService: LogService,
        private ref: ChangeDetectorRef
    ) {}

    /**
     * Sets the schema for the Drill Down Table.
     * Not Required
     */
    @Input()
    schema: DrillDownArraySchema;

    /**
     * Sets the data for the Drill Down Table.
     * Required
     */
    @Input()
    data: any[];

    /**
     * Set the custom component for displaying data selected by the user.
     * Not Required
     */
    @Input()
    detailsComponent: Type<any>;

    /**
     * Sets the name of the Drill Down Viewer.
     * Not Required
     */
    @Input()
    propertyName: string;

    /**
     * Sets the global configuration settings for the Drill Down Viewer.
     * Not Required
     */
    @Input()
    config: DrillDownConfig;

    /**
     * Sets the loading state of the primeNG table.
     * Only useful for lazy loading data or serverside pagination
     */
    @Input()
    tableLoading = false;

    /**
     * Chld template to insert to left side of table paginator
     */
    @Input()
    public paginatorLeft: any;

    /**
     * Chld template to insert to right side of table paginator
     */
    @Input()
    public paginatorRight: any;

    @Output()
    viewerStateChange: EventEmitter<DrillDownState> = new EventEmitter<DrillDownState>();

    public get currentState(): DrillDownState {
        return this.currentStateInternal;
    }

    public set currentState(value: DrillDownState) {
        if (this.currentStateInternal !== value) {
            this.currentStateInternal = value;
            this.viewerStateChange.emit(value);
        }
    }

    public filterFields = [];
    /**
     * Initializes the navStateService subscription, so to ensure all navigation requests are fulfilled.
     */
    ngOnInit() {
        this.navState$ = this.navStateService.currentNavState.subscribe((navState) => this.navigationStateChanged(navState));
    }

    /**
     * Processes any change requests from all external parental components.
     * @param {SimpleChanges} changes Indicates which externally facing property has been altered.
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.displayableData = this.data;
        }

        if (changes['propertyName']) {
            if (this.currentNavState && this.currentNavState.propertyName !== this.propertyName) {
                this.currentNavState.propertyName = this.propertyName;
            }

            this.displayablePropertyName = this.propertyName;
        }

        if (changes['schema']) {
            this.currentSchema = { ...DrillDownArraySchemaDefaults, ...this.schema };
        }

        if (changes['config']) {
            this.config = { ...DrillDownConfigDefaults, ...this.config };
        }

        if (this.displayableData) {
            this.refinedFields = this.generateFields(this.displayableData, this.currentSchema);
            this.primitiveTableFields.next(this.refinedFields.filter((x) => x.isPrimitive));
            this.primitiveTableFields.value.forEach((f) => {
                this.filterFields.push(f.name);
            });
            this.findIdProperty();
            if (this.refinedFields) {
                if (!this.currentNavState || (this.currentNavState && this.currentNavState.originalMenu !== this.data)) {
                    this.isOperational = true;
                    this.isReady = false;
                    this.navStateService
                        .init(this.displayableData, this.currentSchema, this.config, this.propertyName)
                        .pipe(
                            catchError((ex) => {
                                this.isOperational = false;
                                this.isReady = false;
                                return { isValid: false, errorMessage: ex as string } as any;
                            })
                        )
                        .subscribe((operationalStatus: any) => {
                            if ('isValid' in operationalStatus) {
                                const result = operationalStatus as NavStateInitializerResponse;
                                this.isOperational = result.isValid;
                                this.isReady = result.isValid;

                                if (!this.isOperational) {
                                    this.errorMessage = result.errorMessage;
                                }
                            } else {
                                this.isOperational = false;
                                this.isReady = false;
                                this.errorMessage = 'Unexpected error';
                            }

                            this.ref.markForCheck();
                        });
                }
            }
        }
    }

    /**
     * Get's the current view state for the DrillDownViewer.
     */
    public get viewState(): string {
        switch (this.currentState) {
            case DrillDownState.ShowingParentList:
                this.info = false;
                this.buttonCount = 0;
                return 'split';
            case DrillDownState.HidingParentList:
                this.info = false;
                this.buttonCount = 0;
                return 'detail';
            default:
            case DrillDownState.Summary:
                this.info = true;
                this.buttonCount = 5;
                return 'summary';
        }
    }

    /**
     * This is invoked when a user selects an item on the navigation grid.
     * @param event The representation of the grid row selected.
     */
    public selectionMade(event): void {
        if (this.displayableData && this.displayableData.length > 0) {
            const dataRetrieved = event.data;
            if (dataRetrieved) {
                this.logService.logEvent('CL', {
                    areaName: 'DrillDownViewer',
                    contentName: this.displayablePropertyName + ' Selected',
                });
                const currentElement = dataRetrieved;
                this.dataElement = currentElement;
                this.selectedDataElement = this.dataElement;
                this.currentState = this.config.showParentList ? DrillDownState.ShowingParentList : DrillDownState.HidingParentList;

                // Ensures that we only change out a selected state and not a view root state by mistake.
                if (this.currentNavState && this.currentNavState.dataSelected) {
                    this.navStateService.pop();
                }

                let elementSchema: DrillDownObjectSchema = null;
                let currentName = this.displayablePropertyName;
                let tabName: string = null;
                if (this.currentSchema) {
                    elementSchema = this.currentSchema.elementSchema;
                    if (elementSchema) {
                        if (elementSchema.hidePrimaryTab) {
                            // Make sure to populate the tabName of the first tab in otherTabs
                            // since the primary tab is hidden
                            tabName = elementSchema.otherTabs[0].name;
                        } else {
                            // Otherwise just use the primary tab's name
                            tabName = elementSchema.tabName;
                        }
                        currentName = elementSchema.name;
                    }
                }

                this.navStateService.setNewNavState(
                    this.selectedDataElement,
                    currentName,
                    this.displayableData,
                    this.idField,
                    elementSchema,
                    this.currentSchema,
                    this.currentDepth,
                    tabName
                );
            } else {
                this.dataElement = null;
                this.currentState = DrillDownState.Summary;
            }
        }
    }

    /**
     * Used to alter the Drill Down Viewer's visibility state.
     */
    public toggleVisibilityState(): void {
        if (this.currentState === DrillDownState.ShowingParentList) {
            this.currentState = DrillDownState.HidingParentList;
            this.config.showParentList = false;
        } else if (this.currentState === DrillDownState.HidingParentList) {
            this.currentState = DrillDownState.ShowingParentList;
            this.config.showParentList = true;
        }
    }

    public animationStarted(event: AnimationEvent): void {
        if (this.currentState !== DrillDownState.HidingParentList) {
            this.hideSummaryPane = false;
        }
    }

    public animationDone(event: AnimationEvent): void {
        if (this.currentState === DrillDownState.HidingParentList) {
            this.hideSummaryPane = true;
        }
    }

    /**
     * Generates schema fields, if some or all are missing in the schema provided.
     * @param data Data element to be displayed.
     * @param schema The schema for the array/list to be rendered.
     */
    public generateFields(data: any, schema: DrillDownArraySchema): DrillDownPropertySchema[] {
        let propertySchemasForObj = new Array<DrillDownPropertySchema>();
        if (schema) {
            const objectSchema = schema as DrillDownArraySchema;
            if (objectSchema && objectSchema.propertiesToShow) {
                propertySchemasForObj = propertySchemasForObj.concat(objectSchema.propertiesToShow);
            }
        }

        if ((!this.config || !this.config.showOnlyIfInSchema) && (!schema || !schema.showOnlyPropertiesIfDeclared)) {
            propertySchemasForObj = TransformationUtils.mergePropertySchemas(data, propertySchemasForObj);
        } else {
            TransformationUtils.completePropertySchemas(data, propertySchemasForObj);
        }

        return propertySchemasForObj;
    }

    protected dataStateChange(): void {
        this.ref.markForCheck();
    }

    /**
     * This will set a view container, either a custom container, or an instantiated DrillDownObjectViewer.
     * @param data
     */
    private initDetailedContainer(data: any): void {
        if (this.detailsComponent) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(this.detailsComponent);
            this.dynamicView.clear();
            const dynamicViewRef = this.dynamicView.createComponent(factory);
            const detailsContainer = dynamicViewRef.instance as GenericDefaultViewerDirective<any, DrillDownObjectSchema>;
            if (detailsContainer) {
                detailsContainer.data = data;
                detailsContainer.config = this.config;
                detailsContainer.schema = this.currentSchema ? this.currentSchema.elementSchema : null;
                detailsContainer.depth = this.currentDepth;
            }

            dynamicViewRef.changeDetectorRef.detectChanges();
        }
    }

    /**
     * This will set a custom menu..
     * @param data
     */
    private initCustomMenuContainer(data: any, customMenuComponent: Type<DrillDownCustomMenu>): void {
        if (customMenuComponent) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(customMenuComponent);
            this.customMenu.clear();
            const customMenuViewRef = this.customMenu.createComponent(factory);
            const detailsContainer = customMenuViewRef.instance as DrillDownCustomMenu;
            // TODO: This line here is why the incorrect 'No Data Found for' is showing up
            // ComponentFactoryResolver is deprecated and used to trigger rendering that section of the template
            if (detailsContainer) {
                detailsContainer.data = data;
                detailsContainer.config = this.config;
                detailsContainer.schema = this.currentSchema ? this.currentSchema.elementSchema : null;
            }

            customMenuViewRef.changeDetectorRef.detectChanges();
        }
    }

    /**
     * Finds the Id field.
     */
    private findIdProperty(): void {
        if (this.refinedFields) {
            this.humanReadableIdField = this.refinedFields.find((x) => x.isHumanReadableId);
            this.idField = this.refinedFields.find((x) => x.isIdentifier);
        }
    }

    /**
     * This processes a Drill Down Navigation State change.
     * @param navState
     */
    private navigationStateChanged(navState: DrillDownNavState) {
        if (navState && navState.dataSelected) {
            this.currentNavState = navState;
            this.dataElement = navState.dataSelected;
            this.currentSchema = navState.originalMenuSchema;
            if (navState.originalMenu !== this.displayableData) {
                this.displayablePropertyName = navState.propertyName;
                this.displayableData = navState.originalMenu;
                this.refinedFields = this.generateFields(this.displayableData, navState.originalMenuSchema);
                this.primitiveTableFields.next(this.refinedFields.filter((x) => x.isPrimitive));
                this.findIdProperty();
                this.applyFocus();
            }

            if (navState.identifierSchema) {
                this.currentSelection = [Lodash.get(this.dataElement, navState.identifierSchema.name)];
            }

            this.initDetailedContainer(navState.dataSelected);
            if (this.currentSchema) {
                this.initCustomMenuContainer(this.dataElement, this.currentSchema.customMenuComponent);
            }

            if (this.currentState === DrillDownState.Summary) {
                this.currentState = this.config.showParentList ? DrillDownState.ShowingParentList : DrillDownState.HidingParentList;
            }

            this.tabName = navState.tabName;
        } else {
            this.currentState = DrillDownState.Summary;
            this.dataElement = null;
            this.currentSelection = [];
            if (navState) {
                this.currentNavState = navState;
                this.currentSchema = navState.originalMenuSchema;
                this.displayablePropertyName = navState.propertyName;
                this.displayableData = navState.originalMenu;
                this.refinedFields = this.generateFields(this.displayableData, navState.originalMenuSchema);
                this.primitiveTableFields.next(this.refinedFields.filter((x) => x.isPrimitive));
                this.tabName = navState.tabName;
                this.findIdProperty();
                this.applyFocus();
            } else {
                this.tabName = null;
            }
        }
        this.primitiveTableFields.value.forEach((f) => {
            this.filterFields.push(f.name);
        });
        this.ref.markForCheck();
    }

    private applyFocus(): boolean {
        if (this.currentSchema && this.currentSchema.focusOnOnlyElement && this.displayableData.length === 1) {
            this.selectionMade({
                data: this.displayableData[0],
                deselectedRows: [],
                ctrlKey: false,
            });
            return true;
        }

        return false;
    }

    public comboAttribute(attr: string, row: any) {
        const index = attr.indexOf('.');
        const pref = attr.substring(0, index);
        const post = attr.substring(index + 1);
        return row[pref][post];
    }
}
