<ol class="breadcrumb common-menu-banner">
    <li
        class="breadcrumb-item"
        *ngFor="let navState of navigationStates | async"
        [class.active]="navState === currentNavState"
        (click)="switchDataElement(navState)"
        [attr.title]="navState.propertyName"
    >
        {{ getDisplayableNavName(navState) }}
    </li>
</ol>
