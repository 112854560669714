import { RootPath } from '../app-routing/root-paths';
import { ApplicationContextItem } from '../models/application-context-item';
import { SearchCriteriaValues } from '../models/search-criteria-values';
import { ConsumerSubpath } from './consumer-subpaths';
import { ConsumerLookupService, ConsumerLookupServiceToken } from './services/lookup/consumer-lookup.service';

export const consumerApplicationContextItems: ApplicationContextItem = {
    name: 'Consumer',
    isRedirect: false,
    lookupService: ConsumerLookupServiceToken,
    url: `/${RootPath.Consumer}`,
    categories: [
        {
            title: 'Account Profile',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.AccountProfile}`,
            routerLinkActive: 'active',
            searchLandingPage: true,
            iconClass: 'consumer-accountprofile',
            isVisible: isCategoryVisibleDefault,
            description: 'View Account Profile.',
        },
        {
            title: 'Family',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Family}`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-relationships',
            isVisible: isCategoryVisibleDefault,
            description: 'View Family.',
        },
        {
            title: 'Orders',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Orders}`,
            routerLinkActive: 'active',
            iconClass: 'consumer-orders',
            isVisible: true,
            description: 'View Orders.',
        },
        {
            title: 'Entitlements',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Entitlements}`,
            routerLinkActive: 'active',
            iconClass: 'consumer-entitlements',
            isVisible: invisibleToJID,
            description: 'View Entitlements.',
        },
        {
            title: 'Subscriptions',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Subscriptions}`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-account-profile',
            isVisible: isCategoryVisibleDefault,
            description: 'View Subscriptions.',
        },
        {
            title: 'Devices',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Devices}`,
            routerLinkActive: 'active',
            iconClass: 'consumer-devices',
            isVisible: isCategoryVisibleDefault,
            description: 'View Devices.',
        },

        {
            title: 'Payment Instruments',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.PaymentInstruments}`,
            routerLinkActive: 'active',
            iconClass: 'consumer-paymentinstruments',
            isVisible: isCategoryVisibleDefault,
            description: 'View PaymentInstruments.',
        },
        {
            title: 'Flights',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Flights}`,
            routerLinkActive: 'active',
            iconClass: 'enterprise-account-profile',
            isVisible: isCategoryVisibleDefault,
            description: 'View Flights.',
        },
        // ENTERPRISE DECOMISSION
        // {
        //     title: 'User Logs',
        //     href: getUserCodexUrl,
        //     iconClass: 'codex',
        //     isVisible: isMSAHWVisible,
        //     searchLandingPage: false,
        //     description: 'Codex User Logs. ',
        // },
    ],
    primarySearchTypes: [
        {
            name: 'MSA',
            service: 'ConsumerRootService',
            method: 'getMSA',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.AccountProfile}`,
            identifiers: [
                {
                    name: 'MSA',
                    value: 'puid',
                    description: 'MSA or PUID (decimal)',
                },
            ],
        },
        {
            name: 'PUID (Hex)',
            service: 'ConsumerRootService',
            method: 'getMSAfromPuidHex',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.AccountProfile}`,
            identifiers: [
                {
                    name: 'PUID (Hex)',
                    value: 'puid',
                    description: 'PUID (hexadecimal)',
                },
            ],
        },
        {
            name: 'XUID',
            service: 'ConsumerRootService',
            method: 'getMSAfromXuid',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.AccountProfile}`,
            identifiers: [
                {
                    name: 'XUID',
                    value: 'xuid',
                    description: 'XUID',
                },
            ],
        },
        {
            name: 'JID',
            service: 'ConsumerRootService',
            method: 'getjid',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Orders}`,
            identifiers: [
                {
                    name: 'JID',
                    value: 'jid',
                    description: 'email or JID',
                },
            ],
        },
        {
            name: 'GamerTag',
            service: 'ConsumerRootService',
            method: 'getMSAfromGamerTag',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.AccountProfile}`,
            identifiers: [
                {
                    name: 'GamerTag',
                    value: 'gamertag',
                    description: 'GamerTag',
                },
            ],
        },
        {
            name: 'MSA HW ID',
            service: 'ConsumerRootService',
            method: 'getmsahw',
            routerLink: `/${RootPath.Consumer}/${ConsumerSubpath.Orders}`,
            identifiers: [
                {
                    name: 'MSA HWID',
                    value: 'msahw',
                    description: 'MSA HW ID',
                },
            ],
        },
    ],
};
function isSearchMSAW(searchCriteria: SearchCriteriaValues): boolean {
    return !!(
        searchCriteria &&
        searchCriteria[ConsumerLookupService.msahwIdProperty] &&
        searchCriteria[ConsumerLookupService.msahwIdProperty] === searchCriteria[ConsumerLookupService.msahwIdProperty]
    );
}

function isSearchJID(searchCriteria: SearchCriteriaValues): boolean {
    return !!(
        searchCriteria &&
        searchCriteria[ConsumerLookupService.jidIdProperty] &&
        searchCriteria[ConsumerLookupService.jidIdProperty] === searchCriteria[ConsumerLookupService.jidIdProperty]
    );
}

function isCategoryVisibleDefault(searchCriteria: SearchCriteriaValues): boolean {
    const jid = isSearchJID(searchCriteria);
    const isMSAHW = isSearchMSAW(searchCriteria);
    return !(jid || isMSAHW);
}

function invisibleToJID(searchCriteria: SearchCriteriaValues): boolean {
    const jid = isSearchJID(searchCriteria);
    return !jid;
}

export function getUserCodexUrl(searchCriteria: SearchCriteriaValues): string {
    const userId = searchCriteria[ConsumerLookupService.consumerRootIdProperty];
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 2);
    const endDateStr = endDate.toISOString().substring(0, 10);
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 60);
    const startDateStr = startDate.toISOString().substring(0, 10);
    return `https://codex.microsoft.com/vault/cv/UserId/summaries?SearchId=${userId}&SearchStartDate=${startDateStr}&SearchEndDate=${endDateStr}`;
}
