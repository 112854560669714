export const markets = [
    'US',
    'AE',
    'AR',
    'AT',
    'AU',
    'BE',
    'BR',
    'CA',
    'CH',
    'CL',
    'CN',
    'CO',
    'CZ',
    'DE',
    'DK',
    'EG',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HK',
    'HU',
    'ID',
    'IE',
    'IL',
    'IN',
    'IT',
    'JP',
    'KR',
    'MX',
    'NL',
    'NO',
    'NZ',
    'PL',
    'PT',
    'RU',
    'SA',
    'SE',
    'SG',
    'SK',
    'TR',
    'TW',
    'ZA',
    'NEUTRAL',
];
export const languages = [
    'EN-US',
    'AR',
    'AR-SA',
    'CA',
    'CA-ES',
    'CS',
    'CS-CZ',
    'DE',
    'DE-DE',
    'EN',
    'EN-021',
    'EN-GB',
    'EN-US',
    'ES',
    'ES-ES',
    'ES-MX',
    'FI-FI',
    'FR',
    'FR-CA',
    'FR-R',
    'HI',
    'HI-IN',
    'IT',
    'IT-IT',
    'JA-JP',
    'JP',
    'KO',
    'KO-KR',
    'NL',
    'NL-NL',
    'PL',
    'PL-PL',
    'PT',
    'PT-BR',
    'RU',
    'RU-RU',
    'TH',
    'TH-TH',
    'TR',
    'TR-TR',
    'VI',
    'VI-VI',
    'ZH-CN',
    'ZH-TW',
    'NEUTRAL',
];
export const productFamilies = [
    'All',
    'Apps',
    'Avatars',
    'Azure',
    'Barcelona',
    'Bundles',
    'Csv',
    'Devices',
    'Edu',
    'Fees',
    'Games',
    'Ngvl',
    'OperatingSystem',
    'Passes',
    'Software',
];
export const productActionFilters = ['', 'Details', 'Purchase', 'Fulfill', 'License', 'Redeem', 'Curate', 'Renew'];
export const getOrderStorageDocumentRequest = 'GetOrderStorageDocument';
export const verifyAccessToConsumerPurchaseRequest = 'verifyAccessToConsumerPurchaseSection';
// All the versions copied from cp.admin portal
export const billingApiVersions = [
    '2019-10-15',
    '2019-02-28',
    // '2018-06-30',
    // '2018-03-30',
    // '2017-12-30',
    // '2017-06-30',
    // '2017-03-30',
    // '2016-11-30',
    // '2016-06-30',
    // '2015-12-30',
];
export const billingApiVersionDefault = billingApiVersions[1];
export const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const guidInvalidAndExample = `GUID is not valid.  Should be like 'abcdef12-3456-7890-abcd-ef1234567890'.`;
export const fieldsTemplates = ['', 'Purchase'];
