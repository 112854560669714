<div [formGroup]="form">
    <mat-form-field>
        <input
            matInput
            [formControlName]="controlName"
            [matDatepicker]="datepicker"
            [min]="min"
            [max]="max"
            [placeholder]="label"
            [attr.aria-label]="label"
            [required]="required"
        />
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
    </mat-form-field>
</div>
