<div>
    <div class="modal-header settings-banner text-white" style="text-align: center">
        <h3 class="modal-title">Settings</h3>
    </div>
    <div class="modal-body">
        <div class="container" *ngIf="environments != null">
            <div class="form-group">
                <label for="environment" class="font-weight-bold">Environment</label>
                <select id="environment" class="form-control" [(ngModel)]="currentEnvironment">
                    <option *ngFor="let env of environments" [ngValue]="env">{{ env.env }}-{{ env.description }}</option>
                </select>
            </div>
            <div class="card" *ngIf="currentEnvironment">
                <div class="card-body">
                    <div class="row" *ngFor="let service of currentEnvironment.services">
                        <div class="col-md-4">
                            <strong>{{ service.name }}</strong>
                        </div>
                        <div class="col-md-7">{{ service.url }}</div>
                        <div class="col-md-1">{{ service.version }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close')">Close</button>
    </div>
</div>
