import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderStatusComponent } from './loader-status.component';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [LoaderStatusComponent],
    exports: [LoaderStatusComponent],
})
export class LoaderStatusModule {}
