import { Location } from '@angular/common';
import { Params } from '@angular/router';
import * as Lodash from 'lodash-es';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LogService } from '../../../shared-services/log.service';
import { DrillDownConfig } from '../models/drill-down-config';
import { DrillDownFieldPathElement } from '../models/drill-down-field-path-element';
import { DrillDownNavState } from '../models/drill-down-nav-state';
import { DrillDownArraySchema } from '../models/schemas/drill-down-array-schema';
import { DrillDownObjectSchema } from '../models/schemas/drill-down-object-schema';
import { DrillDownPropertySchema } from '../models/schemas/drill-down-property-schema';
import { TransformationUtils } from '../utils/transformation-utils';
import { DrillDownDeferredDataProviderService } from './drill-down-deferred-data-provider.service';
import { adjustNavState, setFieldPath, setTabName } from './drill-down-state-service-functions';

export class DrillDownStateServiceInitializer {
    private results: Subject<Array<DrillDownNavState>>;
    private dataStates: Array<DrillDownNavState>;
    public errorMessage: string;

    constructor(
        private data: any,
        private schema: DrillDownArraySchema | DrillDownObjectSchema,
        private config: DrillDownConfig,
        private name: string,
        private params: Params,
        private deferredDataProviderService: DrillDownDeferredDataProviderService,
        private logService: LogService,
        private location: Location
    ) {}

    public processRequest(): Observable<Array<DrillDownNavState>> {
        this.results = new Subject<Array<DrillDownNavState>>();
        return Observable.create((observer) => {
            this.dataStates = new Array<DrillDownNavState>();
            if (!this.params || Object.keys(this.params).length === 0) {
                let arraySchema: DrillDownArraySchema = null;
                let objectSchema: DrillDownObjectSchema = null;
                if (this.schema) {
                    if ('propertiesToShow' in this.schema) {
                        arraySchema = this.schema as DrillDownArraySchema;
                        objectSchema = arraySchema ? arraySchema.elementSchema : null;
                    } else {
                        objectSchema = this.schema as DrillDownObjectSchema;
                        arraySchema = {
                            showOnlyPropertiesIfDeclared: false,
                            propertiesToShow: [],
                            elementSchema: objectSchema,
                        };
                    }
                }

                adjustNavState(
                    this.dataStates,
                    null,
                    this.name,
                    this.data,
                    null,
                    objectSchema,
                    arraySchema,
                    0,
                    null,
                    null,
                    this.config ? this.config.globalVariables : null
                );
                this.completeInit();
            } else {
                if (this.data instanceof Array) {
                    const currentSchema = this.schema as DrillDownArraySchema;
                    this.hydrateArray(this.data, this.params, currentSchema, this.config);
                } else {
                    const currentSchema = this.schema as DrillDownObjectSchema;
                    this.hydrateObject(this.data, this.params, currentSchema, this.config);
                }
            }

            observer.next(true);
            observer.complete();
        }).pipe(
            mergeMap((x) => {
                if (x) {
                    // This is to ensure resolve any potential race conditions.
                    if (this.results.closed || this.results.isStopped) {
                        return of(this.dataStates);
                    } else {
                        return this.results.asObservable();
                    }
                } else {
                    return of([] as Array<DrillDownNavState>);
                }
            })
        );
    }

    private hydrateArray(data: Array<any>, params: Params, schema: DrillDownArraySchema, config: DrillDownConfig, level: number = 0): void {
        if (
            Object.keys(params).filter((x) => {
                if (x) {
                    const name = x.toLowerCase();
                    return name.endsWith('.name');
                }

                return false;
            }).length > 0
        ) {
            const state: { name: string; level: number } = this.getLevelName(params, level);
            if (!state) {
                this.completeInit();
                return;
            }

            const name = state.name;
            level = state.level;

            if (this.containsMetadataForLevel(params, level)) {
                if (!schema) {
                    const tmpSchemaOfProperties = TransformationUtils.generateSchemaForArrayObjects(data);
                    schema = {
                        propertiesToShow: tmpSchemaOfProperties,
                        elementSchema: {
                            propertySchemas: tmpSchemaOfProperties,
                        },
                    };
                }

                const elementSchema = schema.elementSchema as DrillDownObjectSchema;
                let propertySchemas: Array<DrillDownPropertySchema> = elementSchema && elementSchema.propertySchemas ? elementSchema.propertySchemas : [];
                let drillDownDataValue = data;
                const id = params[`Lvl${level}.id`];
                let idProperty: DrillDownPropertySchema = null;
                if (id !== undefined) {
                    propertySchemas = TransformationUtils.mergePropertySchemas(data, propertySchemas);
                    idProperty = propertySchemas.find((x) => x.isIdentifier);
                    /* tslint:disable:triple-equals */
                    // This is to ensure that numbers cast as strings are considered equivalent.
                    drillDownDataValue = data.find((x) => Lodash.get(x, idProperty.name) == id);
                    /* tslint:enable:triple-equals */
                    delete params[`Lvl${level}.id`];

                    if (drillDownDataValue === undefined || drillDownDataValue === null) {
                        this.errorMessage = `Unable to find entry for a ${name} where ${idProperty.name} = ${id}.`;
                        // Drill down failed to initialize and user won't see data.
                        // Usually we need to define our someMastPage.Settings.ts better for whatever failed to map properly.
                        const error = new Error(`${this.errorMessage}; path: ${this.location.path()}`);
                        error.name = 'DrillDownStateServiceInitializerFailed';
                        this.logService.logException(error);
                        this.completeInit();
                        return;
                    }
                }

                if (drillDownDataValue !== data) {
                    this.hydrateObject(drillDownDataValue, params, elementSchema, config, name, data, idProperty, schema, level);
                } else {
                    this.hydrateArray(data, params, schema, config, level + 1);
                }
            } else {
                adjustNavState(this.dataStates, null, name, data, null, null, schema, 0, null, null, this.config ? this.config.globalVariables : null);
                this.hydrateArray(data, params, schema, config, level + 1);
            }
        } else {
            this.completeInit();
        }
    }

    private containsAnyElementsOfVariableName(params: Params, name: string): boolean {
        const variableName = `.${name}`;
        return Object.keys(params).some((element) => element.endsWith(variableName));
    }

    private containsMetadataForLevel(params: Params, level: number): boolean {
        const results = false;
        if (params[`Lvl${level}.name`] || params[`Lvl${level}.id`] || params[`Lvl${level}.tab`]) {
            return true;
        }

        const fieldPath = params[`Lvl${level}.fieldPath`];
        if (fieldPath && fieldPath.trim() !== '') {
            return true;
        } else {
            // To ensure that unnecessary field path query fields are removed.
            delete params[`Lvl${level}.fieldPath`];
        }

        return results;
    }

    private hydrateObject(
        data: Object,
        params: Params,
        schema: DrillDownObjectSchema,
        config: DrillDownConfig,
        propertyNameFromParent?: string,
        parentValue?: any,
        parentIdPropertySchema?: DrillDownPropertySchema,
        parentSchema?: DrillDownArraySchema,
        level: number = 0
    ) {
        adjustNavState(
            this.dataStates,
            data,
            propertyNameFromParent,
            parentValue,
            parentIdPropertySchema,
            schema,
            parentSchema as DrillDownArraySchema,
            0,
            null,
            null,
            this.config ? this.config.globalVariables : null
        );

        if (Object.keys(params).length === 0) {
            this.completeInit();
            return;
        }

        if (schema && schema.deferredDataSchema) {
            const deferredDataProvider = this.deferredDataProviderService.getDeferredDataProviderState(schema.deferredDataSchema, config, data);
            deferredDataProvider.data
                .pipe(
                    catchError((ex) => {
                        this.errorMessage = 'Request to service failed  Please review Debug panel for details.';
                        this.completeInit();
                        return null;
                    })
                )
                .subscribe((x) => {
                    this.hydrateObjectAttributes(
                        x,
                        params,
                        schema.deferredDataSchema.schema ? (schema.deferredDataSchema.schema as DrillDownObjectSchema) : schema,
                        config,
                        propertyNameFromParent,
                        parentValue,
                        parentIdPropertySchema,
                        parentSchema,
                        level
                    );
                });

            deferredDataProvider.invokeDataRetrievalProcess();
        } else {
            this.hydrateObjectAttributes(data, params, schema, config, propertyNameFromParent, parentValue, parentIdPropertySchema, parentSchema, level);
        }
    }

    private hydrateObjectAttributes(
        data: Object,
        params: Params,
        schema: DrillDownObjectSchema,
        config: DrillDownConfig,
        propertyNameFromParent?: string,
        parentValue?: any,
        parentIdPropertySchema?: DrillDownPropertySchema,
        parentSchema?: DrillDownArraySchema,
        level: number = 0
    ): void {
        if (!propertyNameFromParent) {
            const state = this.getLevelName(params, level);
            if (!state) {
                this.completeInit();
                return;
            }
        }

        if (params && Object.keys(params).length > 0) {
            if (!schema) {
                const tmpSchemaOfProperties = TransformationUtils.generateSchemaForArrayObjects([data]);
                schema = {
                    propertySchemas: tmpSchemaOfProperties,
                };
            }

            const tabName = params[`Lvl${level}.tab`];
            if (tabName) {
                this.hydrateTabObject(data, tabName, params, schema, config, level);
            } else {
                this.hydrateObjectProperties(data, params, schema, config, level);
            }
        }
    }

    private getLevelName(params: Params, level: number): { name: string; level: number } {
        let name: string = null;
        do {
            if (!this.containsAnyElementsOfVariableName(params, 'name') && this.containsMetadataForLevel(params, level)) {
                return;
            }

            name = params[`Lvl${level}.name`];
            if (!name) {
                level++;
            } else {
                delete params[`Lvl${level}.name`];
            }
        } while (name === null || name === undefined);

        return { name: name, level: level };
    }

    private hydrateTabObject(data: Object, tabName: string, params: Params, schema: DrillDownObjectSchema, config: DrillDownConfig, level: number = 0): void {
        delete params[`Lvl${level}.tab`];
        const tabSchema = schema ? schema.otherTabs.find((x) => x.name === tabName) : null;
        if (tabSchema) {
            setTabName(this.dataStates, tabName);
            const tabDataRetrievalState = this.deferredDataProviderService.getTabDataRetrievalState(tabSchema, config, data);
            tabDataRetrievalState.data
                .pipe(
                    catchError((x) => {
                        this.errorMessage = 'Request to service failed  Please review Debug panel for details.';
                        this.completeInit();
                        throw x;
                    })
                )
                .subscribe((x) => {
                    if (x) {
                        if (x instanceof Array) {
                            // Any fieldPath information is s for arrays.
                            delete params[`Lvl${level}.fieldPath`];
                            let arraySchema: DrillDownArraySchema = tabSchema.dataSchema as DrillDownArraySchema;
                            if (!arraySchema) {
                                if (tabSchema.dataFieldName) {
                                    const propertySchema = schema.propertySchemas.find((propSchema) => propSchema.name === tabSchema.dataFieldName);
                                    if (propertySchema) {
                                        arraySchema = propertySchema.schema as DrillDownArraySchema;
                                    }
                                }
                            }

                            this.hydrateArray(x, params, arraySchema, config, level + 1);
                        } else {
                            this.hydrateObjectProperties(x, params, tabSchema.dataSchema as DrillDownObjectSchema, config, level);
                        }
                    }
                });

            tabDataRetrievalState.invokeDataRetrievalProcess();
        } else {
            this.hydrateObjectProperties(data, params, schema, config, level);
        }
    }

    private hydrateObjectProperties(data: Object, params: Params, schema: DrillDownObjectSchema, config: DrillDownConfig, level: number = 0): void {
        const flatField: string = params[`Lvl${level}.fieldPath`];

        if (flatField !== undefined) {
            const fields = flatField
                .split(',')
                .filter((x) => x)
                .map((x) => x.trim());
            delete params[`Lvl${level}.fieldPath`];
            const fieldPath = new Array<DrillDownFieldPathElement>();
            this.drillDownHydrateObjectProperties(data, params, fields, schema, config, fieldPath, level);
        } else {
            if (Object.keys(params).length !== 0) {
                this.errorMessage = 'Unable to fully parse this query.  It maybe malformed or incomplete.';
            }

            this.completeInit();
        }
    }

    private drillDownHydrateObjectProperties(
        data: Object,
        params: Params,
        fieldsToProcess: Array<string>,
        schema: DrillDownObjectSchema | DrillDownArraySchema,
        config: DrillDownConfig,
        fieldPath: Array<DrillDownFieldPathElement>,
        level: number = 0
    ): void {
        const hasMoreParams = Object.keys(params).length > 0;
        if (hasMoreParams && fieldsToProcess.length > 0) {
            if (!data) {
                this.errorMessage = `Requested data wasn't found.`;
                this.completeInit();
            }

            const objectSchema = schema as DrillDownObjectSchema;
            const propertySchemas: Array<DrillDownPropertySchema> = objectSchema && objectSchema.propertySchemas ? objectSchema.propertySchemas : [];
            TransformationUtils.completePropertySchemas(data, propertySchemas);
            const fieldName = fieldsToProcess.shift();
            const currentData = Lodash.get(data, fieldName);
            const currentSchema = propertySchemas.find((x) => x.name === fieldName);
            let objectSchemaOfProperty: DrillDownObjectSchema | DrillDownArraySchema = null;
            if (currentSchema) {
                objectSchemaOfProperty = currentSchema.schema;
            }

            const fieldElement: DrillDownFieldPathElement = {
                value: currentData,
                schema: objectSchemaOfProperty,
                parentValue: data,
                parentSchema: schema,
                propertyName: fieldName,
            };

            fieldPath.push(fieldElement);

            setFieldPath(this.dataStates, fieldPath);
            this.drillDownHydrateObjectProperties(currentData, params, fieldsToProcess, objectSchemaOfProperty, config, fieldPath, level);
        } else {
            if ((schema && 'propertiesToShow' in schema) || (data && data instanceof Array)) {
                this.hydrateArray(data as Array<any>, params, schema as DrillDownArraySchema, config, level + 1);
            } else {
                this.hydrateObject(data, params, schema as DrillDownObjectSchema, config, null, null, null, null, level + 1);
            }
        }
    }

    private completeInit(): void {
        this.results.next(this.dataStates);
        this.results.complete();
    }
}
