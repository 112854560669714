import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DrillDownViewerComponent } from './drill-down-viewer/drill-down-viewer/drill-down-viewer.component';
import { DrillDownModule } from './drill-down-viewer/drill-down.module';
import { FooterComponent } from './footer/footer.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormRepeaterComponent } from './form-repeater/form-repeater.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { LoaderStatusComponent } from './loader-status/loader-status.component';
import { LoaderStatusModule } from './loader-status/loader-status.module';
import { MastJsonEditorWrapperModule } from './mast-json-editor-wrapper/mast-json-editor-wrapper.module';
import { NetworkLockdownBannerComponent } from './network-lockdown-banner/network-lockdown-banner.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { SearchFailedBannerComponent } from './search-failed-banner/search-failed-banner.component';
import { SharedHeaderComponent } from './shared-header/shared-header.component';
import { SharedJsonViewerPaneComponent } from './shared-json-viewer-pane/shared-json-viewer-pane.component';
import { SharedProductNavigationComponent } from './shared-product-navigation/shared-product-navigation.component';
import { SharedSubjectPageComponent } from './shared-subject-page/shared-subject-page.component';
import { UnauthBannerComponent } from './unauth-banner/unauth-banner.component';

@NgModule({
    imports: [
        CommonModule,
        MatSidenavModule,
        MatDividerModule,
        DrillDownModule,
        LoaderStatusModule,
        FormsModule,
        ReactiveFormsModule,
        MastJsonEditorWrapperModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        MatDatepickerModule,
        MatButtonModule,
    ],
    declarations: [
        SharedHeaderComponent,
        SharedSubjectPageComponent,
        SharedJsonViewerPaneComponent,
        SharedProductNavigationComponent,
        FormInputComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        UnauthBannerComponent,
        SearchFailedBannerComponent,
        FormTextareaComponent,
        FormRepeaterComponent,
        FooterComponent,
        NotificationBannerComponent,
        NetworkLockdownBannerComponent,
    ],
    exports: [
        SharedHeaderComponent,
        DrillDownViewerComponent,
        LoaderStatusComponent,
        SharedSubjectPageComponent,
        SharedProductNavigationComponent,
        FormInputComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        FormTextareaComponent,
        FormRepeaterComponent,
        UnauthBannerComponent,
        SearchFailedBannerComponent,
        FooterComponent,
        NotificationBannerComponent,
        NetworkLockdownBannerComponent,
    ],
    entryComponents: [SharedProductNavigationComponent],
})
export class SharedComponentsModule {}
