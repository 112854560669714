import { Injectable, Injector } from '@angular/core';
import { DeferredDataProviderState } from '../models/deferred-data-provider-state';
import { DrillDownConfig } from '../models/drill-down-config';
import { DrillDownDeferredDataProviderSchema } from '../models/schemas/drill-down-deferred-data-provider-schema';
import { DrillDownTabSchema } from '../models/schemas/drill-down-tab-schema';
import { TabDataRetrievalState } from '../models/tab-data-retrieval-state';

@Injectable()
export class DrillDownDeferredDataProviderService {
    private dataProviders: Array<DeferredDataProviderState>;
    constructor(private injector: Injector) {}

    public init(): void {
        this.dataProviders = new Array<DeferredDataProviderState>();
    }

    public remove(deferredDataProviderSchema: DrillDownDeferredDataProviderSchema): void {
        const index = this.dataProviders.findIndex((x) => this.equivalentDeferredDataProviderSchema(deferredDataProviderSchema, x.deferredDataProviderSchema));
        if (index > -1) {
            this.dataProviders.splice(index, 1);
        }
    }

    public getDeferredDataProviderState(
        deferredDataProviderSchema: DrillDownDeferredDataProviderSchema,
        config: DrillDownConfig,
        parentData: any
    ): DeferredDataProviderState {
        let state = this.getGenericDeferredDataProviderState<DeferredDataProviderState>(
            deferredDataProviderSchema,
            config,
            null,
            parentData,
            DeferredDataProviderState
        );

        if (!state) {
            state = new DeferredDataProviderState(deferredDataProviderSchema, config, null, parentData, this.injector);
            this.dataProviders.push(state);
        }

        return state;
    }

    public getTabDataRetrievalState(tabSchema: DrillDownTabSchema, config: DrillDownConfig, parentData: any): TabDataRetrievalState {
        const deferredDataProviderSchema = tabSchema.deferredDataProviderSchema;
        let state: TabDataRetrievalState = null;
        if (deferredDataProviderSchema) {
            state = this.getGenericDeferredDataProviderState<TabDataRetrievalState>(
                deferredDataProviderSchema,
                config,
                tabSchema.dataFieldName,
                parentData,
                TabDataRetrievalState
            );

            if (!state) {
                state = new TabDataRetrievalState(tabSchema, config, parentData, this.injector);
                this.dataProviders.push(state);
            }
        } else {
            // This is for static data states.
            state = new TabDataRetrievalState(tabSchema, config, parentData);
        }

        return state;
    }

    private getGenericDeferredDataProviderState<T extends DeferredDataProviderState>(
        deferredDataProviderSchema: DrillDownDeferredDataProviderSchema,
        config: DrillDownConfig,
        fieldName: string,
        parentData: any,
        tConstructor: { new (): T }
    ): T {
        let result: T = null;
        if (deferredDataProviderSchema) {
            /* tslint:disable:triple-equals */
            result = this.dataProviders
                .filter(
                    (x) =>
                        this.equivalentDeferredDataProviderSchema(x.deferredDataProviderSchema, deferredDataProviderSchema) &&
                        x.parentData == parentData &&
                        x.fieldName === fieldName &&
                        x.config.globalVariables === config.globalVariables
                )
                .map((x) => {
                    if (x instanceof tConstructor) {
                        return x as T;
                    }

                    return null;
                })
                .find((x) => x !== null);
            /* tslint:enable:triple-equals */
        }

        return result;
    }

    private equivalentDeferredDataProviderSchema(item1: DrillDownDeferredDataProviderSchema, item2: DrillDownDeferredDataProviderSchema): boolean {
        const result = true;

        if (item1.dataProvider !== item2.dataProvider) {
            return false;
        }

        if (item1.dataProviderFunctionName !== item2.dataProviderFunctionName) {
            return false;
        }

        if (item1.parameterProperties !== item2.parameterProperties) {
            const item1Properties = item1.parameterProperties;
            const item2Properties = item2.parameterProperties;
            if (!item1Properties || !item2Properties) {
                return false;
            }

            if (item1Properties.length !== item2Properties.length) {
                return false;
            }

            for (let index = 0; index < item1Properties.length; index++) {
                const item1Property = item1Properties[index];
                const item2Property = item2Properties[index];
                if (item1Property.propertyName !== item2Property.propertyName) {
                    return false;
                }
            }
        }
        return result;
    }
}
