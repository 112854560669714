import { InjectionToken } from '@angular/core';
import { RootPath } from '../app-routing/root-paths';
import { ApplicationContextItem } from '../models/application-context-item';
import { NoopLookupService } from '../shared-services/lookup/noop-lookup.service';
import { ConsumerPurchaseSubpath } from './consumerpurchase-subpaths';

export const NoopLookupServiceToken = new InjectionToken<NoopLookupService>('NoopLookupService');

export const consumerPurchaseApplicationContextItems: ApplicationContextItem = {
    name: 'ConsumerPurchase',
    isRedirect: false,
    lookupService: 'No Search bar please',
    url: `/${RootPath.ConsumerPurchase}`,
    categories: [
        {
            title: 'Order Storage Document',
            routerLink: `/${RootPath.ConsumerPurchase}/${ConsumerPurchaseSubpath.OrderStorage}`,
            routerLinkActive: 'active',
            isVisible: true,
            iconClass: 'consumer-purchase-orders',
            description: 'Get Order Storage Document',
        },
        {
            title: 'Order Storage Events',
            routerLink: `/${RootPath.ConsumerPurchase}/${ConsumerPurchaseSubpath.OrderEvents}`,
            routerLinkActive: 'active',
            isVisible: true,
            iconClass: 'consumer-purchase-orderevents',
            description: 'Get Order Storage Events',
        },
        {
            title: 'Billing Records',
            routerLink: `/${RootPath.ConsumerPurchase}/${ConsumerPurchaseSubpath.BillingRecords}`,
            routerLinkActive: 'active',
            isVisible: true,
            iconClass: 'consumer-purchase-billingrecords',
            description: 'Get Billing Records',
        },
        {
            title: 'Promo Code Details',
            routerLink: `/${RootPath.ConsumerPurchase}/${ConsumerPurchaseSubpath.PromoCode}`,
            routerLinkActive: 'active',
            isVisible: true,
            iconClass: 'consumer-purchase-promocode',
            description: 'Get Promo Code Details',
        },
        {
            title: 'RequestTelemetry Logs',
            routerLink: `/${RootPath.ConsumerPurchase}/${ConsumerPurchaseSubpath.RequestTelemetryLogs}`,
            routerLinkActive: 'active',
            isVisible: true,
            iconClass: 'consumer-purchase-kusto',
            description: 'Get Purchase RequestTelemetry Logs',
        },
    ],
};
