import { Injector } from '@angular/core/core';
import { DeferredDataProviderState } from './deferred-data-provider-state';
import { DrillDownConfig } from './drill-down-config';
import { DrillDownArraySchema } from './schemas/drill-down-array-schema';
import { DrillDownTabSchema } from './schemas/drill-down-tab-schema';

/**
 * Represents the data stored for a tabbed pane.  This is exclusively used by the DefaultTabbedViewer.
 */
export class TabDataRetrievalState extends DeferredDataProviderState {
    public isDisabled = false;
    constructor(public tabSchema?: DrillDownTabSchema, public config?: DrillDownConfig, public parentData?: any, protected injector?: Injector) {
        super(tabSchema.deferredDataProviderSchema, config, tabSchema.dataFieldName, parentData, injector);
        if (tabSchema.dataSchema) {
            this.isArray = this.instanceOfDrillDownArraySchema(tabSchema.dataSchema);
        }
        this.tryDisableTab();
    }

    private instanceOfDrillDownArraySchema(schema: any): schema is DrillDownArraySchema {
        return 'elementSchema' in schema && 'propertiesToShow' in schema;
    }

    private tryDisableTab() {
        if (this.tabSchema.deferredDataProviderSchema && this.tabSchema.deferredDataProviderSchema.parameterProperties) {
            const parameterMap = this.tabSchema.deferredDataProviderSchema.parameterProperties;

            parameterMap.forEach((parameter) => {
                const propertyValue = this.getPropertyValueForParameter(parameter);
                if ((propertyValue === null || propertyValue === undefined || propertyValue === '') && parameter.defaultValue === undefined) {
                    this.isDisabled = true;
                }
            });
        }
    }
}
