import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.states';
import { MastResult } from '../../../models/dal/mast-result';
import { getOrderStorageDocumentRequest, verifyAccessToConsumerPurchaseRequest } from '../../../shared/constants';

/**
 * This represent an external request to our partner services (e.g. MSA, MCAPI Commerce Root,..etc.)
 */
@Component({
    selector: 'mast-debug-external-request',
    templateUrl: './debug-external-request.component.html',
    styleUrls: ['./debug-external-request.component.css'],
})
export class DebugExternalRequestComponent {
    public isConsumerPurchaseAuthorized = false;
    constructor(private authStore$: Store<AppState>) {
        /**
         * check if the user has Consumer Purchase access.
         */
        this.authStore$.subscribe((x) => {
            this.isConsumerPurchaseAuthorized = x.authorizationState.isConsumerPurchaseAuthorized;
        });
    }

    @Input()
    public request: MastResult;

    @Input()
    public accordionName: string;

    /**
     * The ordinal number in respect to the list of requests made.
     */
    @Input()
    public index: number;

    public isRequestVisible({ operationName }: MastResult): boolean {
        const isNonConsumerPurchaseRequest: boolean =
            operationName !== getOrderStorageDocumentRequest && operationName !== verifyAccessToConsumerPurchaseRequest;
        return isNonConsumerPurchaseRequest || this.isConsumerPurchaseAuthorized;
    }
}
