import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { ApplicationContextItem } from '../../models/application-context-item';
import { SearchCriteriaValues, SearchStatus } from '../../models/search-criteria-values';
import { SettingsService } from '../settings.service';
import { LookupContextService } from './lookup-context/lookup-context.service';

/**
 * This class is used to ensure that a page component entrance is secured via authetication.
 */
@Injectable()
export class LookupGuard implements CanActivate {
    constructor(private lookupContextService: LookupContextService, private settingsService: SettingsService, private router: Router) {}

    /**
     * Gets the current authentication status.
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const searchStatusEnum: typeof SearchStatus = SearchStatus;
        return this.lookupContextService.searchComplete.pipe(
            filter((criteria) => criteria && criteria.searchStatus !== searchStatusEnum.SearchInProgress),
            first(),
            mergeMap<SearchCriteriaValues, Observable<boolean>>((x: SearchCriteriaValues) => {
                if (x.searchStatus !== searchStatusEnum.Idle) {
                    return of(x.searchStatus === searchStatusEnum.SearchSuccessful);
                } else {
                    const currentAppContext = this.findApplicationContext(route);

                    if (currentAppContext) {
                        const searchObservable = this.lookupContextService.init(currentAppContext, route);
                        if (searchObservable) {
                            return searchObservable.pipe(
                                filter((criteria) => criteria && criteria.searchStatus !== searchStatusEnum.SearchInProgress),
                                map((criteria: SearchCriteriaValues) => {
                                    return criteria.searchStatus === searchStatusEnum.SearchSuccessful;
                                }),
                                tap((searchState) => {
                                    if (!searchState) {
                                        this.router.navigate(['/']);
                                    }
                                }),
                                first()
                            );
                        }
                    }

                    return of(false);
                }
            })
        );
    }

    private findApplicationContext(activatedRoute: ActivatedRouteSnapshot): ApplicationContextItem {
        const serviceComponent = activatedRoute.component;
        if (serviceComponent) {
            const applicationContextItems = this.settingsService.getAllRegisteredApplicationContextItems();
            const refinedApplicationContextItems = applicationContextItems.filter((x) => x !== null);

            if (activatedRoute.routeConfig.path) {
                return this.findApplicationContextInActivatedRoute(activatedRoute, refinedApplicationContextItems);
            }
        }
        return null;
    }

    private findApplicationContextInActivatedRoute(
        activatedRoute: ActivatedRouteSnapshot,
        refinedApplicationContextItems: Array<ApplicationContextItem>
    ): ApplicationContextItem {
        let result: ApplicationContextItem = null;
        if (activatedRoute) {
            const startingPath = `/${activatedRoute.routeConfig.path}`;
            result = refinedApplicationContextItems.find((x) => x.url === startingPath);
            if (!result) {
                result = this.findApplicationContextInActivatedRoute(activatedRoute.parent, refinedApplicationContextItems);
            }
        }

        return result;
    }
}
