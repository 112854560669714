import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { DrillDownNavState } from '../../../models/drill-down-nav-state';
import { DrillDownStateService } from '../../../service/drill-down-state.service';
import { DrillDownPropertySchema } from '../../../models/schemas/drill-down-property-schema';

@Component({
    selector: 'mast-drill-down-navigation-bar',
    templateUrl: './drill-down-navigation-bar.component.html',
    styleUrls: ['./drill-down-navigation-bar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrillDownNavigationBarComponent implements OnInit, OnDestroy {
    private navigationStatesSubject = new BehaviorSubject<ReadonlyArray<DrillDownNavState>>([]);
    private navigateionStateChangeSubscription: Subscription;
    public navigationStates = this.navigationStatesSubject.asObservable();
    public currentNavState: DrillDownNavState;

    constructor(private navStateService: DrillDownStateService, private ref: ChangeDetectorRef) {}

    @Input()
    public hideFirstElement = false;

    @Input()
    public displayByIdentifier = false;

    public ngOnInit() {
        this.navigationStatesSubject.next(this.navStateService.allNavStates);
        this.navigateionStateChangeSubscription = this.navStateService.currentNavState.subscribe((navState) => {
            if (this.navStateService && this.navStateService.allNavStates) {
                const states = this.navStateService.allNavStates.slice(this.hideFirstElement ? 1 : 0);

                this.navigationStatesSubject.next(states);
                this.currentNavState = navState;
                this.ref.markForCheck();
            }
        });
    }

    public ngOnDestroy() {
        this.navigateionStateChangeSubscription.unsubscribe();
    }

    /**
     * Sets the Drill Down Viewer's data view state.
     * @param {DrillDownNavState} state Contains the necessary context information for rendering the viewer.
     */
    public switchDataElement(state: DrillDownNavState): void {
        this.navStateService.setExistingNavState(state);
    }

    /**
     * Generates a name for each navigation option.
     * @param {DrillDownNavState} nav Navigation option
     * @returns A Human readable name.
     */
    public getDisplayableNavName(nav: DrillDownNavState): string {
        if (nav) {
            if (this.displayByIdentifier) {
                if (nav.dataSelected) {
                    let idSchema = nav.identifierSchema;
                    if (!idSchema || !idSchema.isHumanReadableId) {
                        if (nav.dataSelected) {
                            const objectSchema = nav.objectSchema;
                            if (objectSchema && objectSchema.propertySchemas) {
                                const humanReadableId = objectSchema.propertySchemas.find((x) => x.isHumanReadableId);
                                if (humanReadableId) {
                                    idSchema = humanReadableId;
                                }
                            }
                        }
                    }

                    if (idSchema) {
                        const value = nav.dataSelected[idSchema.name];
                        return value ? this.truncate(value) : nav.propertyName;
                    }
                }
            }

            return nav.propertyName;
        }

        return 'N/A';
    }

    public truncate(original: string, length: number = 20): string {
        if (original && original.length > length) {
            return `${original.substring(0, length)}...`;
        } else {
            return original !== null && original !== undefined ? original : '[No Name]';
        }
    }
}
