import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { TermsOfUseUserConsentedAction } from './services/terms-of-use.actions';

@Component({
    selector: 'mast-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.css'],
})
export class TermsOfUseComponent implements OnInit {
    constructor(private appState: Store<AppState>) {}

    ngOnInit() {}

    public acceptTermsOfUse() {
        this.appState.dispatch(new TermsOfUseUserConsentedAction());
    }
}
