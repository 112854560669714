import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnterpriseTenantAndOrgIdMastResultState } from '../models/enterprise-tenant-and-org-id-state.parameters';
import { EnterpriseTenantMastResultState } from '../models/enterprise-tenant-state.parameters';
import { UsersRolesMastResultState } from '../models/user-and-roles-result';
import { EnterpriseStates } from './enterprise.states';

export const enterpriseFeatureSelector = createFeatureSelector<EnterpriseStates>('enterprise');
/**
 * These are the search states for each subfeature.
 */
export const selectAccountProfileState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.accountProfileSearch);
export const selectOrganizationIdState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.organizationIdSearch);
export const selectAccountRegistrationDetailsState = createSelector(
    enterpriseFeatureSelector,
    (state: EnterpriseStates) => state.accountRegistrationDetailsSearch
);
export const selectUsersRolesState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.usersRolesSearch);
export const selectCatalogsState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.catalogsSearch);
export const selectInventoryState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.inventorySearch);
export const selectMDMState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.mdmSearch);
export const selectEntitlementsState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.entitlementsSearch);
export const selectOrdersState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.ordersSearch);
export const selectPrivateCatalogsState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.privateCatalogsSearch);
export const selectSecurityGroupsState = createSelector(enterpriseFeatureSelector, (state: EnterpriseStates) => state.securityGroups);

/**
 * These are the search results for each subfeature.
 */
export const selectAccountProfileStateResults = createSelector(selectAccountProfileState, (state: EnterpriseTenantMastResultState) => state.results);
export const selectOrganizationIdStateResults = createSelector(selectOrganizationIdState, (state: EnterpriseTenantAndOrgIdMastResultState) => state.results);
export const selectAccountRegistrationDetailsStateResults = createSelector(
    selectAccountRegistrationDetailsState,
    (state: EnterpriseTenantMastResultState) => state.results
);
export const selectUsersRolesStateResults = createSelector(selectUsersRolesState, (state: UsersRolesMastResultState) => state.results);
export const selectCatalogsStateResults = createSelector(selectCatalogsState, (state: EnterpriseTenantMastResultState) => state.results);
export const selectInventoryStateResults = createSelector(selectInventoryState, (state: EnterpriseTenantMastResultState) => state.results);
export const selectMDMStateResults = createSelector(selectMDMState, (state: EnterpriseTenantMastResultState) => state.results);
export const selectEntitlementsStateResults = createSelector(selectEntitlementsState, (state: EnterpriseTenantAndOrgIdMastResultState) => state.results);
export const selectOrdersStateResults = createSelector(selectOrdersState, (state: EnterpriseTenantAndOrgIdMastResultState) => state.results);
export const selectPrivateCatalogsStateResults = createSelector(selectPrivateCatalogsState, (state: EnterpriseTenantMastResultState) => state.results);
export const selectSecurityGroupsStateResults = createSelector(selectSecurityGroupsState, (state: EnterpriseTenantMastResultState) => state.results);

/**
 * These are the search successful indicators for each subfeature.
 */
export const selectAccountProfileStateSearchSuccessful = createSelector(
    selectAccountProfileState,
    (state: EnterpriseTenantMastResultState) => state.searchSuccessful
);
export const selectOrganizationIdStateSearchSuccessful = createSelector(
    selectOrganizationIdState,
    (state: EnterpriseTenantAndOrgIdMastResultState) => state.searchSuccessful
);
export const selectAccountRegistrationDetailsSearchSuccessful = createSelector(
    selectAccountRegistrationDetailsState,
    (state: EnterpriseTenantMastResultState) => state.searchSuccessful
);
export const selectUsersRolesStateSearchSuccessful = createSelector(selectUsersRolesState, (state: UsersRolesMastResultState) => state.searchSuccessful);
export const selectCatalogsStateSearchSuccessful = createSelector(selectCatalogsState, (state: EnterpriseTenantMastResultState) => state.searchSuccessful);
export const selectInventoryStateSearchSuccessful = createSelector(selectInventoryState, (state: EnterpriseTenantMastResultState) => state.searchSuccessful);
export const selectMDMStateSearchSuccessful = createSelector(selectMDMState, (state: EnterpriseTenantMastResultState) => state.searchSuccessful);
export const selectEntitlementsStateSearchSuccessful = createSelector(
    selectEntitlementsState,
    (state: EnterpriseTenantAndOrgIdMastResultState) => state.searchSuccessful
);
export const selectOrdersStateSearchSuccessful = createSelector(selectOrdersState, (state: EnterpriseTenantAndOrgIdMastResultState) => state.searchSuccessful);
export const selectPrivateCatalogsStateSearchSuccessful = createSelector(
    selectPrivateCatalogsState,
    (state: EnterpriseTenantMastResultState) => state.searchSuccessful
);
export const selectSecurityGroupsStateSearchSuccessful = createSelector(
    selectSecurityGroupsState,
    (state: EnterpriseTenantMastResultState) => state.searchSuccessful
);

/**
 * These are the search completed indicators for each subfeature.
 */
export const selectAccountProfileStateSearchCompleted = createSelector(
    selectAccountProfileState,
    (state: EnterpriseTenantMastResultState) => state.searchCompleted
);
export const selectOrganizationIdStateSearchCompleted = createSelector(
    selectOrganizationIdState,
    (state: EnterpriseTenantAndOrgIdMastResultState) => state.searchCompleted
);
export const selectAccountRegistrationDetailsSearchCompleted = createSelector(
    selectAccountRegistrationDetailsState,
    (state: EnterpriseTenantMastResultState) => state.searchCompleted
);
export const selectUserRolesStateSearchCompleted = createSelector(selectUsersRolesState, (state: UsersRolesMastResultState) => state.searchCompleted);
export const selectCatalogsStateSearchSearchCompleted = createSelector(selectCatalogsState, (state: EnterpriseTenantMastResultState) => state.searchCompleted);
export const selectInventoryStateSearchCompleted = createSelector(selectInventoryState, (state: EnterpriseTenantMastResultState) => state.searchCompleted);
export const selectMDMStateSearchCompleted = createSelector(selectMDMState, (state: EnterpriseTenantMastResultState) => state.searchCompleted);
export const selectEntitlementsStateSearchCompleted = createSelector(
    selectEntitlementsState,
    (state: EnterpriseTenantAndOrgIdMastResultState) => state.searchCompleted
);
export const selectOrdersStateSearchCompleted = createSelector(selectOrdersState, (state: EnterpriseTenantAndOrgIdMastResultState) => state.searchCompleted);
export const selectPrivateCatalogsStateSearchCompleted = createSelector(
    selectPrivateCatalogsState,
    (state: EnterpriseTenantMastResultState) => state.searchCompleted
);
export const selectSecurityGroupsStateSearchCompleted = createSelector(
    selectSecurityGroupsState,
    (state: EnterpriseTenantMastResultState) => state.searchCompleted
);
