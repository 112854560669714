import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { LoaderStatusModule } from '../loader-status/loader-status.module';
import { DefaultArrayViewerComponent } from './drill-down-viewer/default-array-viewer/default-array-viewer.component';
import { DefaultHeaderComponent } from './drill-down-viewer/default-header/default-header.component';
import { DrillDownNavigationBarComponent } from './drill-down-viewer/default-header/drill-down-navigation-bar/drill-down-navigation-bar.component';
import { DefaultObjectViewerComponent } from './drill-down-viewer/default-object-viewer/default-object-viewer.component';
import { DefaultPropertyViewerComponent } from './drill-down-viewer/default-property-viewer/default-property-viewer.component';
import { DefaultTabbedViewerComponent } from './drill-down-viewer/default-tabbed-viewer/default-tabbed-viewer.component';
import { DrillDownViewerComponent } from './drill-down-viewer/drill-down-viewer.component';
import { WindowInjectionTokenForDD } from './models/window-proxy';
import { DrillDownDeferredDataProviderService } from './service/drill-down-deferred-data-provider.service';
import { DrillDownStateService } from './service/drill-down-state.service';
import { TabVisibilityPipe } from './utils/tab-visibility.pipe';

export function windowFactory(): any {
    return window;
}

@NgModule({
    imports: [CommonModule, FormsModule, MatTabsModule, LoaderStatusModule, MatExpansionModule, TableModule, ButtonModule, DropdownModule, InputTextModule],
    declarations: [
        DrillDownViewerComponent,
        DefaultObjectViewerComponent,
        DefaultPropertyViewerComponent,
        DefaultArrayViewerComponent,
        DefaultTabbedViewerComponent,
        DrillDownNavigationBarComponent,
        DefaultHeaderComponent,
        TabVisibilityPipe,
    ],
    providers: [DrillDownStateService, DrillDownDeferredDataProviderService, { provide: WindowInjectionTokenForDD, useFactory: windowFactory }],
    exports: [
        DrillDownViewerComponent,
        DefaultObjectViewerComponent,
        DefaultPropertyViewerComponent,
        DefaultArrayViewerComponent,
        DefaultTabbedViewerComponent,
        DrillDownNavigationBarComponent,
    ],
    entryComponents: [DefaultObjectViewerComponent, DefaultPropertyViewerComponent, DefaultArrayViewerComponent],
})
export class DrillDownModule {}
