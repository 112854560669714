import { DrillDownConfigBag } from './drill-down-config-bag';

/**
 * The metadata of the global configuration for the DrillDownViewer and its default Viewers.
 */
export interface DrillDownConfig {
    /**
     * Indicates that any and all properties are to be hidden from any of viewers if their respective values are null.
     */
    hideAllIfNull?: boolean;

    /**
     * This indicates that only explicitly defined properties are to be displayed.
     */
    showOnlyIfInSchema: boolean;

    /**
     * The maximum depth that the DrillDownViewer is allowed to display at any given time.
     */
    maxDepth?: number;

    /**
     * Contains all of the user defined variables. They must be prefixed with $$ in order to be used by deferred data
     * providers and actions.
     */
    globalVariables?: DrillDownConfigBag;

    /**
     * If set to true, the URL query path will be updated during any drilldown or tab context change.
     */
    enableUrlTracking?: boolean;

    /**
     * If set to true, the generic header for the DrillDown component will be displayed.
     */
    showDrillDownHeader?: boolean;

    /**
     * Controls whether the default DrillDownState is ShowingParentList (true) or HidingParentList (false).
     */
    showParentList?: boolean;
}

/**
 * Default values for the boolean flags in a DrillDownConfig.
 */
export const DrillDownConfigDefaults: DrillDownConfig = {
    hideAllIfNull: false,
    showOnlyIfInSchema: false,
    enableUrlTracking: false,
    showDrillDownHeader: true,
    showParentList: false,
};
