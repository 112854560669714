import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import { SettingsService } from '../shared-services/settings.service';
import { AuthenticationComponent } from './authentication.component';
import { AuthenticationGuard } from './authentication.guard';
import { AuthenticationService } from './services/authentication.service';

export const appRoutes: Routes = [
    { path: 'id_token', component: AuthenticationComponent, canActivate: [AuthenticationGuard], data: { title: 'ID Token' } },
    { path: 'access_token', component: AuthenticationComponent, data: { title: 'Access Token' } },
];

export function loggerCallback(logLevel: LogLevel, message: string) {
    if (message.indexOf('msal:loginSuccess') > -1) {
        // After login success, hard redirect and reload the original url if there is one
        // If not, the user will be directed to consumer home page.
        const loadingUrl = localStorage.getItem(`redirectUrl`) || '/#/consumer';
        window.location.replace(loadingUrl);
        localStorage.removeItem(`redirectUrl`);
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.clientId, // MAST MSAL UI ClientID
            redirectUri: '/',
            postLogoutRedirectUri: '/',
            authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/',
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(settingsService: SettingsService): MsalInterceptorConfiguration {
    // MSAL Interceptor Configuration
    // Adding the bearer token to all API requests
    const protectedResourceMap = new Map([[`${settingsService.mastApiServiceUrl}/api/*`, [`api://2bfbc20d-b5e0-4cd8-9bee-93723cd821d7/user_impersonation`]]]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

@NgModule({
    imports: [CommonModule, RouterModule.forChild(appRoutes), HttpClientModule, MsalModule],
    declarations: [AuthenticationComponent],
    providers: [
        AuthenticationGuard,
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [SettingsService],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
})
export class AuthenticationModule {}
