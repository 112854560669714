import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { generateCommonHeaderConfiguration, generateWebRequestOptions } from '../../utils/service-tools';

@Injectable()
export class TermsOfUseService {
    constructor(private http: HttpClient) {}

    public readTermsOfUse(): Observable<string> {
        return this.http.get<string>(`/api/TermsOfUse/ReadTermsOfUse`, {
            headers: generateCommonHeaderConfiguration('readTermsOfUse', true, false),
        });
    }

    public writeTermsOfUse(): Observable<string> {
        return this.http.get<string>('/api/TermsOfUse/WriteTermsOfUse', {
            headers: generateCommonHeaderConfiguration('writeTermsOfUse', true, false),
        });
    }

    public verifyTermsOfUseEncryptedString(encryptedValue: string): Observable<boolean> {
        const parameterValues = {
            encryptedValue: encryptedValue,
        };
        return this.http.get<boolean>(
            '/api/TermsOfUse/VerifyTermsOfUseEncryptedString',
            generateWebRequestOptions('verifyTermsOfUseEncryptedString', parameterValues, true, false)
        );
    }
}
