<div class="card">
    <div
        class="card-header"
        role="tab"
        id="headingResponse{{ outerIndex }}_{{ innerIndex }}"
        [class.bg-danger]="hasErrored"
        [class.bg-success]="!hasErrored"
        data-toggle="collapse"
        attr.data-target="#collapseInnerResponse{{ outerIndex }}_{{ innerIndex }}"
    >
        <h5 class="mb-0">
            <a
                class="text-white"
                data-toggle="collapse"
                href="#collapseInnerResponse{{ outerIndex }}_{{ innerIndex }}"
                aria-expanded="true"
                attr.aria-controls="collapseInnerResponse{{ outerIndex }}_{{ innerIndex }}"
            >
                Response
            </a>
        </h5>
    </div>
    <div
        id="collapseInnerResponse{{ outerIndex }}_{{ innerIndex }}"
        class="collapse"
        role="tabpanel"
        attr.aria-labelledby="headingResponse{{ outerIndex }}_{{ innerIndex }}"
        attr.data-parent="#{{ accordionName }}"
    >
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Received At:</label>
            <span class="form-text">{{ responseTrace.ReceivedAt }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Status:</label>
            <span class="form-text">{{ responseTrace.Status }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Content Length:</label>
            <span class="form-text">{{ responseTrace.ContentLength }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Body:</label>
            <div class="card card-body jsonviewer">
                <mast-json-editor-wrapper [ngModel]="body" [options]="jsonViewerOptions"></mast-json-editor-wrapper>
            </div>
        </div>
    </div>
</div>
