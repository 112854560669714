import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Jsll from '@dna/jsll';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { MastExternalRequest } from '../../models/dal/mast-external-request';
import { MastRequestTrace } from '../../models/dal/mast-request-trace';
import { MastResponseTrace } from '../../models/dal/mast-response-trace';
import { MastResult } from '../../models/dal/mast-result';
import { DebuggingService } from '../debugging.service';
import { JsllService } from '../jsll.service';

@Injectable()
export class BaseService {
    private serviceCallHolder: { [cv: string]: Date } = {};
    constructor(protected authService: AuthenticationService, protected debuggingService: DebuggingService, protected jsllService?: JsllService) {}

    /**
     * Produces the headers with authorization bearer tokens.
     */
    protected produceHeaders(cv: Jsll.ICv): Observable<HttpHeaders> {
        const cvValue = cv.increment();
        this.serviceCallHolder[cvValue] = new Date();
        return this.authService.getMastWebApiServiceToken().pipe(
            map((token) => {
                const headers: HttpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: `Bearer ${token}`,
                    'MS-CV': cvValue,
                });

                return headers;
            })
        );
    }

    protected processMastResults(
        response: HttpResponse<string> | HttpErrorResponse,
        operationName: string,
        cV: string = 'N/A',
        methodType: string = 'GET'
    ): MastResult {
        let results: MastResult = null;
        const endDate = new Date();
        let contentType: string;
        if (response && response.ok) {
            const successfulResponse = response as HttpResponse<string>;
            try {
                const item = JSON.parse(successfulResponse.body);
                results = item as MastResult;
                results.hasErrored = false;
                contentType = successfulResponse.headers.get('content-type');
                if (results && results.ExternalRequests) {
                    results.ExternalRequests.forEach((request) => {
                        if (!request.ResponseTrace || !request.ResponseTrace.Status) {
                            return;
                        }

                        if (!request.Data && request.ResponseTrace.Status !== 'OK' && request.ResponseTrace.Status !== 'NoContent') {
                            results.hasErrored = true;
                            if (!request.HasErrored) {
                                request.HasErrored = true;
                            }
                        }
                    });
                }
            } catch (e) {
                results = {} as MastResult;
                contentType = successfulResponse.headers.get('content-type');
                if (contentType && contentType.startsWith('application/json')) {
                    results.Data = JSON.parse(successfulResponse.body);
                } else {
                    results.Data = successfulResponse.body;
                }
            }
        } else {
            const errorResponse = response as HttpErrorResponse;
            results = {} as MastResult;
            results.ExternalRequests = [];
            const localResponse = {} as MastResponseTrace;
            localResponse.Status = errorResponse.statusText;
            localResponse.Body = errorResponse.error;
            const localRequest = {} as MastRequestTrace;
            localRequest.Uri = 'N/A';
            const externalRequest = {} as MastExternalRequest;
            externalRequest.ResponseTrace = localResponse;
            externalRequest.RequestTrace = localRequest;
            externalRequest.HasErrored = true;
            results.ExternalRequests.push(externalRequest);
            results.errorMessage = errorResponse.message;
            if (errorResponse.error) {
                contentType = errorResponse.headers.get('content-type');
                if (contentType && contentType.startsWith('application/json')) {
                    results.Data = JSON.parse(errorResponse.error);
                } else {
                    results.Data = null;
                }
            }

            results.hasErrored = true;
        }

        results.responseCode = response.status;
        results.operationName = operationName;
        results.cV = cV;
        this.debuggingService.add(results);

        return results;
    }
}
