import { MastResult } from '../../../models/dal/mast-result';
import * as SM from '../../../models/state-manager/state-manager-search.actions';
import { EnterpriseTenantStateParameters } from '../../models/enterprise-tenant-state.parameters';

export const SEARCH = '[EnterpriseAccountRegistrationProfile] Search';
export const REFRESH = '[EnterpriseAccountRegistrationProfile] Refresh';
export const RESET = '[EnterpriseAccountRegistrationProfile] Reset';
export const SUCCESSFUL = '[EnterpriseAccountRegistrationProfile] Successful';
export const FAILED = '[EnterpriseAccountRegistrationProfile] Failed';

export class AccountRegistrationProfileSearchAction implements SM.StateManagerSearchAction<EnterpriseTenantStateParameters> {
    readonly type = SEARCH;
    constructor(public parameters: EnterpriseTenantStateParameters) {}
}

export class AccountRegistrationProfileSearchRefreshAction implements SM.StateManagerSearchRefreshAction {
    readonly type = REFRESH;
    constructor() {}
}

export class AccountRegistrationProfileSearchResetAction implements SM.StateManagerSearchResetAction {
    readonly type = RESET;
    constructor() {}
}

export class AccountRegistrationProfileSuccessfulAction implements SM.StateManagerSuccessfulAction<MastResult> {
    readonly type = SUCCESSFUL;
    constructor(public result: MastResult) {}
}

export class AccountRegistrationProfileFailedAction implements SM.StateManagerSearchFailedAction {
    readonly type = FAILED;
    constructor(public errorCode: string, public errorMessage) {}
}
