import { Injectable } from '@angular/core';
import { ApplicationInsights, IPageViewTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    public appInsights: ApplicationInsights;
    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableAutoRouteTracking: true, // option to log all route changes
                disableFetchTracking: false,
                autoTrackPageVisitTime: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                // enableCorsCorrelation: true // Enable this will cause MAST authentication fail. Need to investigate.
            },
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
            if (item.baseData) {
                // remove `id_token` and `access_token` from refUri
                if (item.baseData.refUri) {
                    const idTokenIndex = (item.baseData.refUri as string).indexOf('_token=');
                    if (idTokenIndex > -1) {
                        item.baseData.refUri = (item.baseData.refUri as string).substring(0, idTokenIndex + 7);
                    }
                }
                // remove Auth token from dependency tracking
                if (item.baseData.properties && item.baseData.properties.requestHeaders && item.baseData.properties.requestHeaders.Authorization) {
                    item.baseData.properties.requestHeaders.Authorization = '';
                }
            }
        });
    }

    logPageView(pageLogPros: IPageViewTelemetry) {
        // option to call manually
        this.appInsights.trackPageView(pageLogPros);
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
