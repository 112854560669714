<div class="card" *ngIf="isRequestVisible(request)">
    <div
        class="card-header"
        role="tab"
        id="headingOuter{{ index }}"
        [class.bg-danger]="request.hasErrored"
        [class.bg-success]="!request.hasErrored"
        data-toggle="collapse"
        attr.data-target="#collapseOuter{{ index }}"
    >
        <h5 class="mb-0">
            <a class="text-white" data-toggle="collapse" href="#collapseOuter{{ index }}" aria-expanded="true" attr.aria-controls="collapseOuter{{ index }}">
                {{ request.operationName }}
            </a>
        </h5>
    </div>
    <div id="collapseOuter{{ index }}" class="collapse" role="tabpanel" attr.aria-labelledby="headingOuter{{ index }}" attr.data-parent="#{{ accordionName }}">
        <div class="card-body">
            <div class="form-group">
                <label class="form-control-label font-weight-bold" for="correlation-vector">Correlation Vector</label>
                <span class="form-text">{{ request.cV }}</span>
            </div>
            <ng-container *ngFor="let requestItem of request.ExternalRequests; let j = index">
                <div id="accordionInner{{ j }}" role="tablist">
                    <mast-debug-request-unit
                        [requestTrace]="requestItem.RequestTrace"
                        [outerIndex]="index"
                        [innerIndex]="j"
                        [hasErrored]="requestItem.HasErrored"
                        accordionName="accordionInner{{ j }}"
                    ></mast-debug-request-unit>
                    <mast-debug-response-unit
                        [responseTrace]="requestItem.ResponseTrace"
                        [outerIndex]="index"
                        [innerIndex]="j"
                        [hasErrored]="requestItem.HasErrored"
                        accordionName="accordionInner{{ j }}"
                    ></mast-debug-response-unit>
                </div>
            </ng-container>
        </div>
    </div>
</div>
