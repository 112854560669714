<div id="default-array-viewer" class="card">
    <div *ngIf="isLoading; then whileLoading; else notLoading"></div>
    <ng-template #whileLoading>
        <mast-loader-status [isActive]="isLoading"></mast-loader-status>
    </ng-template>
    <ng-template #notLoading>
        <div class="card-body">
            <ng-template #drillDownCustomMenuAc></ng-template>
            <div *ngIf="shouldShowData(); then showDataArea; else noData"></div>
            <ng-template #showDataArea>
                <div *ngIf="hasPrimitiveElements; then primitive; else nonPrimitive"></div>
                <ng-template #primitive>
                    <div class="list-group-item" *ngFor="let element of currentData">
                        <div class="card">{{ element }}</div>
                        <hr />
                    </div>
                </ng-template>
                <ng-template #nonPrimitive>
                    <p-table
                        id="array-view-grid"
                        [value]="currentData"
                        [scrollable]="true"
                        #dt
                        dataKey="id"
                        [globalFilterFields]="filterFields"
                        [paginator]="true"
                        [resizableColumns]="true"
                        [columnResizeMode]="'expand'"
                        [rows]="25"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        selectionMode="single"
                        (onRowSelect)="selectionMade($event)"
                        styleClass="p-datatable-gridlines"
                    >
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th
                                    *ngFor="let col of noneHiddenProperties"
                                    pResizableColumn
                                    class="table-header"
                                    pSortableColumn="{{ col.name }}"
                                    style="width: 250px"
                                >
                                    {{ col.title }}
                                    <p-sortIcon field="{{ col.name }}"></p-sortIcon>
                                </th>
                            </tr>
                            <tr class="ptable-row">
                                <th *ngFor="let col of noneHiddenProperties">
                                    <ng-template *ngIf="col.isBoolean; then booleanFilter; else normalFilter"></ng-template>
                                    <ng-template #normalFilter>
                                        <p-columnFilter
                                            type="text"
                                            [field]="col.name"
                                            [matchMode]="'contains'"
                                            [showMenu]="false"
                                            placeholder="Filter {{ col.title }}"
                                        >
                                        </p-columnFilter>
                                    </ng-template>
                                    <ng-template #booleanFilter>
                                        <p-columnFilter [field]="col.name" matchMode="equals" [showMenu]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown
                                                    [ngModel]="value"
                                                    [options]="[
                                                        { label: 'True', value: true },
                                                        { label: 'False', value: false }
                                                    ]"
                                                    placeholder="Filter {{ col.title }}"
                                                    appendTo="body"
                                                    (onChange)="filter($event.value)"
                                                    [showClear]="true"
                                                    optionLabel="label"
                                                >
                                                    <ng-template let-option pTemplate="item">
                                                        <span>{{ option.label }}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </ng-template>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                            <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex">
                                <td *ngFor="let col of noneHiddenProperties" primengridcell style="white-space: normal" class="ui-resizable-column">
                                    {{ col.aggregationFunction 
                                        ? col.aggregationFunction(row)
                                        : col.name.indexOf('.') > 0
                                            ? comboAttribute(col.name,row)
                                            : row[col.name] }}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="columns?.length">No records found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </ng-template>
            <ng-template #noData>
                <span class="drill-down-no-data">No data found for {{ name }}</span>
            </ng-template>
        </div>
    </ng-template>
</div>
