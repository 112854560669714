import { AbstractControl, FormGroup } from '../../../node_modules/@angular/forms';

// Marks each control in the given form as touched.
export function markControlsAsTouched(form: FormGroup) {
    Object.values(form.controls).forEach((control: AbstractControl) => control.markAsTouched());
}

// Formats the string to guid format whenever applicable(tenant id/OrgId with noA. dashes)
export function formatStringToGuid(stringToFormat: string): string {
    // Skip if the input is of domain name
    if (stringToFormat.indexOf('-') >= 0 || stringToFormat.indexOf('.') >= 0) {
        return stringToFormat;
    }

    const regex = /([A-Za-z0-9]{8})([A-Za-z0-9]{4})([A-Za-z0-9]{4})([A-Za-z0-9]{4})([A-Za-z0-9]{12})/g;
    const parts = regex.exec(stringToFormat.toString());

    if (parts && parts.length === 6) {
        // Skip the first part as it always returns the complete original string
        parts.splice(0, 1);

        const formattedTenantId = parts.join('-');
        return formattedTenantId;
    } else {
        return stringToFormat;
    }
}

// Formats the given ID to the format expected in URLs.
export function formatIdForUrl(id: string): string {
    return id.replace('msa:', '').replace('tnt:', '');
}

export function extractErrorMessage(error: any): string {
    if (error instanceof Error) {
        return error.message;
    } else {
        return JSON.stringify(error, null, '\t');
    }
}

// abc:abc123xyz OR abc:SOMEGUID
export const purchaseUserIdRegex = /^([a-zA-Z]+:[a-zA-Z0-9]+)$|^([a-zA-Z]+:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/;
export const msaWithoutPrefix = /^[0-9]+$/; // check for string of only numbers
