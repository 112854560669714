/**
 * Represents search criteria for a filter lookup.
 */
export class SearchCriteriaValues {
    public searchStatus: SearchStatus;
    public environment: string;
    public wasGeneratedFromParams = false;
    public userInvoked = false;
    public method: string;

    [key: string]: string | boolean | SearchStatus;
}

export enum SearchStatus {
    Idle,
    SearchInProgress,
    SearchSuccessful,
    SearchFailed,
}
