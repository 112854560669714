import { Pipe, PipeTransform } from '@angular/core';
import { TabDataRetrievalState } from '../models/tab-data-retrieval-state';
import { DrillDownIsHiddenFunction } from '../models/drill-down-is-hidden-function';
import { DrillDownTabSchema } from '../models/schemas/drill-down-tab-schema';

/**
 * This is remove all tabs that were meant to be hidden.
 */
@Pipe({
    name: 'tabVisibility',
})
export class TabVisibilityPipe implements PipeTransform {
    public transform(value: Array<TabDataRetrievalState>, args?: any): Array<TabDataRetrievalState> {
        if (value) {
            return value.filter((x) => this.isVisible(x.tabSchema, x.parentData));
        }

        return null;
    }

    private isVisible(tabSchema: DrillDownTabSchema, currentData: any): boolean {
        let result = true;

        if (typeof tabSchema.isHidden === 'boolean') {
            result = !tabSchema.isHidden;
        } else if (typeof tabSchema.isHidden === 'function') {
            const isHiddenProvider: DrillDownIsHiddenFunction = tabSchema.isHidden;
            result = !isHiddenProvider(currentData);
        }

        return result;
    }
}
