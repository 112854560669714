import {
    AllAutorizationActions,
    CONSUMER_ACCESS_FAILURE,
    CONSUMER_ACCESS_SUCCESS,
    CONSUMER_PURCHASE_ACCESS_FAILURE,
    CONSUMER_PURCHASE_ACCESS_SUCCESS,
    ENTERPRISE_ACCESS_FAILURE,
    ENTERPRISE_ACCESS_SUCCESS,
    FAILURE,
    GET_CONSUMER_ACCESS,
    GET_CONSUMER_PURCHASE_ACCESS,
    GET_ENTERPRISE_ACCESS,
    GET_RBAC,
    SEARCH,
    SUCCESS,
} from './authorization.actions';
import { AuthorizationState } from './authorization.state';

const initialState: AuthorizationState = {
    isComplete: false,
    isAuthorized: false,

    missingRequirements: [],
    errorMessage: null,

    isEnterpriseAuthComplete: false,
    isEnterpriseAuthInProgress: false,
    isEnterpriseAuthorized: false,

    isConsumerAuthComplete: false,
    isConsumerAuthInProgress: false,
    isConsumerAuthorized: false,

    isConsumerPurchaseAuthComplete: false,
    isConsumerPurchaseAuthInProgress: false,
    isConsumerPurchaseAuthorized: false,
};

export function reducer(state: AuthorizationState, action: AllAutorizationActions): AuthorizationState {
    if (!state) {
        state = initialState;
    }

    switch (action.type) {
        case SEARCH:
            return {
                ...state,
                isComplete: state.isAuthorized,
                isAuthorized: state.isAuthorized && state.isComplete,
                missingRequirements: [],
                errorMessage: null,
            };
        case SUCCESS:
            return {
                ...state,
                isComplete: true,
                isAuthorized: true,
                missingRequirements: [],
                errorMessage: null,
            };
        case FAILURE:
            return {
                ...state,
                isComplete: true,
                missingRequirements: action.missingRequirements,
                errorMessage: action.errorMessage,
                isAuthorized: false,
            };
        case GET_RBAC:
            return { ...state };
        case GET_ENTERPRISE_ACCESS:
            return { ...state, isEnterpriseAuthInProgress: true };
        case ENTERPRISE_ACCESS_FAILURE:
            return {
                ...state,
                isEnterpriseAuthComplete: true,
                isEnterpriseAuthInProgress: false,
                isEnterpriseAuthorized: false,
            };
        case ENTERPRISE_ACCESS_SUCCESS:
            return {
                ...state,
                isEnterpriseAuthComplete: true,
                isEnterpriseAuthInProgress: false,
                isEnterpriseAuthorized: true,
            };
        case GET_CONSUMER_ACCESS:
            return { ...state, isConsumerAuthInProgress: true };
        case CONSUMER_ACCESS_FAILURE:
            return {
                ...state,
                isConsumerAuthComplete: true,
                isConsumerAuthInProgress: false,
                isConsumerAuthorized: false,
            };
        case CONSUMER_ACCESS_SUCCESS:
            return {
                ...state,
                isConsumerAuthComplete: true,
                isConsumerAuthInProgress: false,
                isConsumerAuthorized: true,
            };
        case CONSUMER_PURCHASE_ACCESS_FAILURE:
            return {
                ...state,
                isConsumerPurchaseAuthComplete: true,
                isConsumerAuthInProgress: false,
                isConsumerPurchaseAuthorized: false,
            };
        case CONSUMER_PURCHASE_ACCESS_SUCCESS:
            return {
                ...state,
                isConsumerPurchaseAuthComplete: true,
                isConsumerAuthInProgress: false,
                isConsumerPurchaseAuthorized: true,
            };
        case GET_CONSUMER_PURCHASE_ACCESS: {
            return { ...state, isConsumerPurchaseAuthInProgress: true };
        }
        default:
            return state;
    }
}
