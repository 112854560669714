import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { EnvironmentServiceDetails } from '../models/environment-service-details';

@Injectable()
export class RegisteredServicesService {
    constructor(private http: HttpClient) {}

    /**
     * Retrieves all the services by environment metadata.
     */
    public getAllServicesByEnvironment(): Observable<Array<EnvironmentServiceDetails>> {
        return this.http.get<Array<EnvironmentServiceDetails>>('./config/service-details.json');
    }
}
