import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { environment } from '../environments/environment';
import { applicationContextItems } from './app-context-items';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { reducers } from './app.reducers';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthenticationEffects } from './authentication/services/authentication.effects';
import { AuthorizationServiceModule } from './authorization/authorization-service.module';
import { AuthorizationEffects } from './authorization/services/authorization.effects';
import { LookupModule } from './lookup/lookup.module';
import { DebugModule } from './mast-nav-bar/debug-assist/debug.module';
import { MastNavBarComponent } from './mast-nav-bar/mast-nav-bar.component';
import { SearchComponent } from './mast-nav-bar/search/search.component';
import { SettingsComponent } from './mast-nav-bar/settings/settings.component';
import { MastSideNavComponent } from './mast-side-nav/mast-side-nav.component';
import { WindowInjectionToken } from './models/window-proxy';
import { LoaderStatusModule } from './shared-components/loader-status/loader-status.module';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { appContextItemsToken } from './shared-services/app-tokens';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { TermsOfUseEffects } from './terms-of-use/services/terms-of-use.effects';
import { TermsOfUseModule } from './terms-of-use/terms-of-use.module';

export function windowFactory(): any {
    return window;
}

@NgModule({
    declarations: [AppComponent, SearchComponent, SettingsComponent, MastSideNavComponent, MastNavBarComponent],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        PrettyJsonModule,
        BrowserAnimationsModule,
        NgbModule,
        SharedServicesModule,
        DebugModule,
        AppRoutingModule,
        AuthenticationModule,
        AuthorizationServiceModule,
        TermsOfUseModule,
        MatSelectModule,
        MatInputModule,
        LookupModule,
        LoaderStatusModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([AuthenticationEffects, AuthorizationEffects, TermsOfUseEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        SharedComponentsModule,
    ],
    providers: [Title, { provide: appContextItemsToken, useValue: applicationContextItems }, { provide: WindowInjectionToken, useFactory: windowFactory }],
    bootstrap: [AppComponent, MsalRedirectComponent],
    entryComponents: [SettingsComponent],
})
export class AppModule {}
