import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppState } from '../app.states';
import { AuthenticationState } from '../authentication/services/authentication.state';
import { ApplicationContextItem } from '../models/application-context-item';
import { DebuggingService } from '../shared-services/debugging.service';
import { LogService } from '../shared-services/log.service';
import { LookupContextService } from '../shared-services/lookup/lookup-context/lookup-context.service';
import { SettingsService } from '../shared-services/settings.service';
import { DebugComponent } from './debug-assist/debug.component';
import { SettingsComponent } from './settings/settings.component';

// the css for this is in styles.css since it renders at a top leve.
const mastModalOptions: NgbModalOptions = { windowClass: 'mast-modal-window' };

@Component({
    selector: 'mast-nav-bar',
    templateUrl: './mast-nav-bar.component.html',
    styleUrls: ['./mast-nav-bar.component.css'],
})
export class MastNavBarComponent implements OnInit {
    public currentContext: ApplicationContextItem;
    public contextItems: ApplicationContextItem[];
    public allRequirementsSatisfied: Observable<boolean>;
    public userGreetings: Observable<string>;
    private snapshot: ActivatedRouteSnapshot;
    private authenticationState: Observable<AuthenticationState>;

    constructor(
        private authStore: Store<AppState>,
        public settingsService: SettingsService,
        private activatedRoute: ActivatedRoute,
        private lookupContextService: LookupContextService,
        private logService: LogService,
        public debuggingService: DebuggingService,
        private injector: Injector,
        private router: Router,
        private ngbModal: NgbModal
    ) {
        this.allRequirementsSatisfied = this.authStore.pipe(
            select((x) => x),
            filter((x) => x.authenticationState.isComplete && x.authorizationState.isComplete && x.termsOfUseState.isComplete),
            map((x) => x.authenticationState.isAuthenticated && x.authorizationState.isAuthorized && x.termsOfUseState.userHasConsented)
        );
        this.userGreetings = this.authStore.pipe(
            select((result) => result.authenticationState),
            map((x) => x.username)
        );
    }

    /**
     * Validates visibility of navbar menu
     * @param appContextItem The requested Application Context.
     */
    public isNavbarItemVisible(appContextItem: ApplicationContextItem): Observable<boolean> {
        if (appContextItem.name === 'ConsumerPurchase') {
            return this.authStore.pipe(
                select((x) => x),
                map((x) => x.authorizationState.isConsumerPurchaseAuthorized)
            );
        }
        return of(true);
    }

    public ngOnInit(): void {
        this.contextItems = this.settingsService.getAllRegisteredApplicationContextItems();
        this.setContextByParameters();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setContextByParameters();
            }
        });
    }

    /**
     * Set the desired Application Context.
     * @param appContextItem The requested Application Context.
     */
    public setContextMenu(appContextItem: ApplicationContextItem): void {
        this.currentContext = appContextItem;
        this.router.navigate([appContextItem.url]);
    }

    /**
     * Actuates the Settings pane.
     */
    public actuateSettings(): void {
        this.logService.logEvent('Setting Icon Clicked');
        const options: NgbModalOptions = mastModalOptions;
        options.size = 'lg';
        this.ngbModal.open(SettingsComponent, options);
    }

    /**
     * Actuates the Debug pane.
     */
    public actuateDebugger(): void {
        this.logService.logEvent('Debug Icon Clicked');
        const options: NgbModalOptions = mastModalOptions;
        options.size = 'lg';
        this.ngbModal.open(DebugComponent, options);
    }

    /**
     * This sets the context menu and parameters for the initial page request.
     */
    private setContextByParameters() {
        const finalDestination = this.activatedRoute.firstChild;
        if (finalDestination && this.contextItems) {
            this.snapshot = finalDestination.snapshot;

            if (this.snapshot.routeConfig.path) {
                const startingPath = `/${this.snapshot.routeConfig.path}`;
                this.currentContext = this.contextItems.find((x) => x.url === startingPath);
            }
        }
    }
}
