<div class="card">
    <div
        class="card-header"
        role="tab"
        id="headingRequest{{ outerIndex }}_{{ innerIndex }}"
        [class.bg-danger]="hasErrored"
        [class.bg-success]="!hasErrored"
        data-toggle="collapse"
        attr.data-target="#collapseOuterRequest{{ outerIndex }}_{{ innerIndex }}"
    >
        <h5 class="mb-0">
            <a
                class="text-white"
                data-toggle="collapse"
                href="#collapseOuterRequest{{ outerIndex }}_{{ innerIndex }}"
                aria-expanded="true"
                attr.aria-controls="collapseOuterRequest{{ outerIndex }}_{{ innerIndex }}"
            >
                Request
            </a>
        </h5>
    </div>
    <div
        id="collapseOuterRequest{{ outerIndex }}_{{ innerIndex }}"
        class="collapse"
        role="tabpanel"
        attr.aria-labelledby="headingRequest{{ outerIndex }}_{{ innerIndex }}"
        attr.data-parent="#{{ accordionName }}"
    >
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Issued At:</label>
            <span class="form-text">{{ requestTrace.IssuedAt }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Verb:</label>
            <span class="form-text">{{ requestTrace.Verb }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">URL:</label>
            <span class="form-text">{{ requestTrace.Uri }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Headers:</label>
            <div class="card card-body">
                <mast-json-editor-wrapper [ngModel]="headers ? headers : requestTrace.Headers" [options]="jsonViewerOptions"></mast-json-editor-wrapper>
            </div>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Content Length:</label>
            <span class="form-text">{{ requestTrace.ContentLength }}</span>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold">Body:</label>
            <div class="card card-body">
                <mast-json-editor-wrapper [ngModel]="body ? body : requestTrace.Body" [options]="jsonViewerOptions"></mast-json-editor-wrapper>
            </div>
        </div>
    </div>
</div>
