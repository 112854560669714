import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver-es';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MastJsonEditorWrapperOptions } from '../../models/mast-json-editor-wrapper/mast-json-editor-wrapper-options';
import { DrillDownNavState } from '../drill-down-viewer/models/drill-down-nav-state';
import { DrillDownDeferredDataProviderService } from '../drill-down-viewer/service/drill-down-deferred-data-provider.service';
import { DrillDownStateService } from '../drill-down-viewer/service/drill-down-state.service';

@Component({
    selector: 'mast-shared-json-viewer-pane',
    templateUrl: './shared-json-viewer-pane.component.html',
    styleUrls: ['./shared-json-viewer-pane.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedJsonViewerPaneComponent implements OnInit {
    public currentValue: Observable<any>;
    private currentValueSnapshot$: any;
    public jsonViewerOptions: MastJsonEditorWrapperOptions = {
        mode: 'view',
        modes: ['code', 'view'], // allowed modes
        indentation: 4,
        escapeUnicode: true,
        onEditable: (node) => false,
        autocomplete: { getOptions: (text, path, input, editor) => null },
    };

    @Output()
    public closeEvent = new EventEmitter<void>();

    @Output()
    public fullScreenEvent = new EventEmitter<void>();

    constructor(
        private ref$: ChangeDetectorRef,
        private drillDownStateService$: DrillDownStateService,
        private drillDownDeferredDataProviderService$: DrillDownDeferredDataProviderService
    ) {
        this.currentValue = drillDownStateService$.currentNavState.pipe(
            filter((x) => x !== null && x !== undefined),
            switchMap((x) => this.processDataChangeRequest(x)),
            tap((x) => {
                this.currentValueSnapshot$ = x;
            })
        );
    }

    public ngOnInit(): void {
        this.ref$.markForCheck();
    }

    public toggle(): void {
        this.closeEvent.emit();
    }

    public fullScreen(): void {
        this.fullScreenEvent.emit();
    }

    public exportJson(): void {
        if (this.currentValueSnapshot$) {
            const data = JSON.stringify(this.currentValueSnapshot$);
            const blob = new Blob([data], { type: 'application/json' });
            saveAs(blob, 'jsonview.json');
        }
    }

    private processDataChangeRequest(navState: DrillDownNavState): Observable<any> {
        if (!navState.tabName) {
            if (navState.dataSelected) {
                return of(navState.dataSelected);
            } else {
                return of(navState.originalMenu);
            }
        } else {
            if (this.drillDownDeferredDataProviderService$) {
                const currentTabSchema = navState.objectSchema.otherTabs.find((y) => y.name === navState.tabName);
                if (currentTabSchema) {
                    const state = this.drillDownDeferredDataProviderService$.getTabDataRetrievalState(
                        currentTabSchema,
                        this.drillDownStateService$.config,
                        navState.dataSelected
                    );

                    if (state) {
                        /**
                         * This is a hack
                         * We will need to work on a better solution for retrieving data from the TabDataRetrievalState.
                         */
                        if (currentTabSchema.dataFieldName) {
                            // This is necessary due to the fact that data from a field isn't stored in a cache.
                            return state.invokeDataRetrievalProcess().pipe(switchMap(() => state.data));
                        } else {
                            return state.data;
                        }
                    }
                }
            }
        }

        return of(null);
    }
}
