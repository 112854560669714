import { Directive, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransformationUtils } from '../drill-down-viewer/utils/transformation-utils';

/**
 * Base class for other MAST form components
 */
@Directive()
export abstract class FormFieldBaseDirective implements OnInit {
    @Input()
    form: FormGroup;
    @Input()
    controlName: string;
    @Input()
    label?: string;
    @Input()
    errorMessage?: string;
    @Input()
    required?: boolean;

    constructor() {}

    ngOnInit() {
        if (typeof this.label === 'undefined') {
            this.label = TransformationUtils.transformPropertyName(this.controlName);
        }
        if (typeof this.errorMessage === 'undefined') {
            // TODO: this is garbage.  use the ngforms stuff
            this.errorMessage = 'This is a required field';
        }
        if (typeof this.required === 'undefined') {
            this.required = true; // this is also bad
        }
    }
}
