import { Inject, Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApplicationContextItem } from '../models/application-context-item';
import { WindowInjectionToken } from '../models/window-proxy';
import { appContextItemsToken } from './app-tokens';

/**
 * MAST UI applicaiton settings configurations
 */
@Injectable()
export class SettingsService {
    private currentEnvironment = 'Prod';
    private environmentSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentEnvironment);
    public $environmentSubject: Observable<string> = this.environmentSubject.asObservable();

    constructor(private cookieService: CookieService, private injector: Injector, @Inject(WindowInjectionToken) private window: Window) {}

    public get mastApiServiceUrl(): string {
        return environment.webApiService;
    }

    public get appId(): string {
        return environment.appId;
    }

    public set environment(value: string) {
        this.currentEnvironment = value;
        this.environmentSubject.next(value);
    }

    public get environment(): string {
        return this.currentEnvironment;
    }

    /**
     * Gets all registered application context items for MAST.
     */
    public getAllRegisteredApplicationContextItems(): ApplicationContextItem[] {
        return this.injector.get(appContextItemsToken) as ApplicationContextItem[];
    }

    /**
     * Attempts to retrieve values stored within the browser's local and session storage units.
     * @param key {string} The storage key.
     * @param defaultValue {string} The defaultValue, if there were no values found in either storage centers.
     * @returns The value found within either the Local or Session storage unit.  If nothing was found,
     * then the value returned is the user provided default value.
     */
    public getItemFromApplicationStorage(key: string, defaultValue?: string): string {
        let results: string = this.window.localStorage.getItem(key);
        if (!results) {
            results = this.window.sessionStorage.getItem(key);
        }

        if (!results) {
            return defaultValue;
        }

        return results;
    }

    /**
     * Sets a value to a cookie with a specific key.
     * @param key The key
     * @param value The value
     * @param daysValid The amount of days from the present to set the cookie.
     */
    public setItemsToCookie(key: string, value: string, daysValid?: number): void {
        if (key) {
            const cookieOptions = {};
            if (daysValid) {
                const date = new Date();
                date.setDate(date.getDate() + daysValid);
                cookieOptions['expires'] = date;
            }

            this.cookieService.put(key, value, cookieOptions);
        }
    }

    /**
     * Gets a value from a cookie based on a specific key.
     * @param key The cookie.
     */
    public getItemsFromCookie(key: string): string {
        if (key) {
            return this.cookieService.get(key);
        }

        return null;
    }
}
