import { InjectionToken } from '@angular/core';
import { ApplicationContextItem } from '../models/application-context-item';
import { LookupService } from '../shared-services/lookup/lookup.service';

export const NoopLookupServiceToken = new InjectionToken<LookupService>('NoopLookupService');

export const toolsApplicationContextItems: ApplicationContextItem = {
    name: 'Tools',
    isRedirect: false,
    lookupService: NoopLookupServiceToken,
    url: '/tools',
    categories: [
        {
            title: '5X5 Lookup',
            routerLink: '/tools/tokenlookup',
            routerLinkActive: 'active',
            iconClass: 'tools-5X5lookup',
            description: 'Lookup 5 X 5 token.',
        },
        {
            title: 'Device Imei Lookup',
            routerLink: '/tools/deviceimeilookup',
            routerLinkActive: 'active',
            iconClass: 'consumer-devices',
            description: 'Lookup Device Imei.',
        },
        {
            title: 'Fulfillment Lookup',
            routerLink: '/tools/fulfillmentlookup',
            routerLinkActive: 'active',
            iconClass: 'tools-fulfillmentlookup',
            description: 'Lookup Fulfillment Id.',
        },
        {
            title: 'Inventory Lookup',
            routerLink: '/tools/inventorylookup',
            routerLinkActive: 'active',
            iconClass: 'tools-inventorylookup',
            description: 'Lookup Omni Inventory.',
        },
        {
            title: 'Product Lookup',
            routerLink: '/tools/productlookup',
            routerLinkActive: 'active',
            iconClass: 'tools-productlookup',
            searchLandingPage: true,
            description: 'Lookup Product Name/PSA.',
        },
    ],
};
