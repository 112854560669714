import { Params } from '@angular/router';
import * as Lodash from 'lodash-es';
import { DrillDownConfigBag } from '../models/drill-down-config-bag';
import { DrillDownFieldPathElement } from '../models/drill-down-field-path-element';
import { DrillDownNavState } from '../models/drill-down-nav-state';
import { DrillDownArraySchema } from '../models/schemas/drill-down-array-schema';
import { DrillDownObjectSchema } from '../models/schemas/drill-down-object-schema';
import { DrillDownPropertySchema } from '../models/schemas/drill-down-property-schema';

/**
 * Sets the fieldPath for the current Drill Down Nav State
 * @param dataStates The Nav State list
 * @param fieldPath The lineage path.
 */
export function setFieldPath(dataStates: Array<DrillDownNavState>, fieldPath: Array<DrillDownFieldPathElement>): boolean {
    const stateLength = dataStates.length;
    if (stateLength > 0) {
        const state = dataStates[stateLength - 1];
        if (state) {
            state.fieldPath = fieldPath;
            return true;
        }
    }

    return false;
}

/**
 * Sets the tab name for the current Drill Down Nav State
 * @param dataStates The Nav State list
 * @param tabName The tab name.
 */
export function setTabName(dataStates: Array<DrillDownNavState>, tabName: string): boolean {
    const length = dataStates.length;
    if (length > 0) {
        const state = dataStates[length - 1];
        if (state) {
            state.tabName = tabName;
            return true;
        }
    }

    return false;
}

export function adjustNavState(
    dataStates: Array<DrillDownNavState>,
    dataSelected: any,
    propertyName: string,
    menuValues: Array<any>,
    identifierSchema?: DrillDownPropertySchema,
    objectSchema?: DrillDownObjectSchema,
    menuSchema?: DrillDownArraySchema,
    depth?: number,
    tabName?: string,
    fieldPath?: Array<DrillDownFieldPathElement>,
    configBag?: DrillDownConfigBag
): DrillDownNavState {
    let navState: DrillDownNavState = null;
    if (dataStates) {
        const index = dataSelected ? dataStates.findIndex((x) => x.dataSelected === dataSelected) : -1;
        if (index > -1) {
            navState = dataStates[index];
            if (dataStates.length - 1 > index) {
                dataStates.splice(index + 1);
            }

            navState.fieldPath = []; // The field path isn't applicable here.
        } else {
            let id: string = null;
            if (identifierSchema && dataSelected) {
                if (!identifierSchema.aggregationFunction) {
                    id = Lodash.get(dataSelected, identifierSchema.name);
                } else {
                    id = identifierSchema.aggregationFunction(dataSelected, configBag);
                }
            }

            navState = {
                dataSelected: dataSelected,
                propertyName: propertyName,
                id: id,
                originalMenu: menuValues,
                identifierSchema: identifierSchema,
                depth: depth,
                objectSchema: objectSchema,
                originalMenuSchema: menuSchema,
                tabName: tabName,
                fieldPath: [],
            } as DrillDownNavState;
            dataStates.push(navState);
        }
    } else {
        navState = { propertyName: null, id: null, dataSelected: null, depth: 0, originalMenu: [] };
    }

    return navState;
}

/**
 * Determines if the two Params bags are equivalent or not.
 * @param params1
 * @param params2
 * @returns True - If the two params are equivalent.
 */
export function isEquivalentParams(params1: Params, params2: Params): boolean {
    if (isEmptyParams(params1)) {
        if (isEmptyParams(params2)) {
            return true;
        }
        return false;
    } else {
        if (isEmptyParams(params2)) {
            return false;
        }
    }

    const params1Keys = Object.keys(params1);
    const params2Keys = Object.keys(params2);
    if (params1Keys.length === params2Keys.length) {
        /* tslint:disable:triple-equals */
        return params1Keys.every((key) => Lodash.get(params1, key) == Lodash.get(params2, key));
        /* tslint:enable:triple-equals */
    }

    return false;
}

/**
 * Determines if the Params bag is empty.
 * @param params
 * @returns True - If empty.
 */
export function isEmptyParams(params: Params): boolean {
    return !params || Object.keys(params).length === 0;
}
