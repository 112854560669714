import { Component, Input } from '@angular/core';
import { RoleInfo } from '../../authorization/services/authorization.service';

@Component({
    selector: 'mast-unauth-banner',
    templateUrl: './unauth-banner.component.html',
    styleUrls: ['./unauth-banner.component.css'],
})
export class UnauthBannerComponent {
    @Input()
    public showUnauthBanner: boolean;

    @Input()
    public roleInfo: RoleInfo;

    constructor() {}
}
