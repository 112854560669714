import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MastExternalRequest } from '../models/dal/mast-external-request';
import { MastRequestTrace } from '../models/dal/mast-request-trace';
import { MastResponseTrace } from '../models/dal/mast-response-trace';
import { MastResult } from '../models/dal/mast-result';
import { DebuggingService } from './debugging.service';
import { JsllService } from './jsll.service';

@Injectable()
export class DebuggingInterceptor implements HttpInterceptor {
    constructor(private jsllService: JsllService, private debuggingService: DebuggingService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = req.headers;
        const startDate = new Date();
        const method = req.method;
        const cV = req.headers.get('MS-CV');
        const operationName = req.headers.get('$opname');
        const expectMastResult = req.headers.get('$expectmastresult');
        if (operationName) {
            const newRequest = req.clone({ headers: req.headers.delete('$opname').delete('$expectmastresult') });
            return next.handle(newRequest).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error instanceof HttpErrorResponse) {
                        this.processHttpErrorResponse(error, startDate, operationName, cV, method);
                    }

                    return _throw(error);
                }),
                tap((event) => {
                    if (event instanceof HttpResponse) {
                        this.processMastResults(event, startDate, operationName, cV, method, expectMastResult === 'true');
                    }
                })
            );
        } else {
            return next.handle(req);
        }
    }

    protected processHttpErrorResponse(
        errorResponse: HttpErrorResponse,
        startDate: Date,
        operationName: string,
        cV: string = 'N/A',
        methodType: string = 'GET'
    ) {
        let results: MastResult = null;
        const endDate = new Date();
        let contentType: string;
        results = {} as MastResult;
        results.ExternalRequests = [];
        const localResponse = {} as MastResponseTrace;
        localResponse.Status = errorResponse.statusText;
        localResponse.Body = errorResponse.error;
        if (errorResponse.headers.has('Content-Length')) {
            localResponse.ContentLength = Number.parseInt(errorResponse.headers.get('Content-Length'), 10);
        }

        if (errorResponse.headers.has('Date')) {
            localResponse.ReceivedAt = new Date(errorResponse.headers.get('Date'));
        }

        const localRequest = {} as MastRequestTrace;
        localRequest.Uri = 'N/A';
        const externalRequest = {} as MastExternalRequest;
        externalRequest.ResponseTrace = localResponse;
        externalRequest.RequestTrace = localRequest;
        externalRequest.HasErrored = true;
        results.ExternalRequests.push(externalRequest);
        results.errorMessage = errorResponse.message;
        if (errorResponse.error) {
            contentType = errorResponse.headers.get('content-type');
            if (contentType && contentType.startsWith('application/json')) {
                results.Data = typeof errorResponse.error === 'string' ? JSON.parse(errorResponse.error) : errorResponse.error;
            } else {
                results.Data = null;
            }
        }

        results.hasErrored = true;
        results.responseCode = errorResponse.status;
        results.operationName = operationName;
        results.cV = cV;
        this.debuggingService.add(results);
    }

    protected processMastResults(
        response: HttpResponse<any>,
        startDate: Date,
        operationName: string,
        cV: string = 'N/A',
        methodType: string = 'GET',
        expectMastResult: boolean = false
    ): void {
        let results: MastResult = null;
        const endDate = new Date();
        let contentType: string;
        if (response) {
            const successfulResponse = response as HttpResponse<any>;
            try {
                if (expectMastResult) {
                    const item =
                        typeof successfulResponse.body === 'string' && expectMastResult ? JSON.parse(successfulResponse.body) : successfulResponse.body;
                    results = item as MastResult;
                    results.hasErrored = false;
                    contentType = successfulResponse.headers.get('content-type');
                    if (results && results.ExternalRequests) {
                        results.ExternalRequests.forEach((request) => {
                            if (!request.ResponseTrace || !request.ResponseTrace.Status) {
                                return;
                            }

                            if (!request.Data && request.ResponseTrace.Status !== 'OK' && request.ResponseTrace.Status !== 'NoContent') {
                                results.hasErrored = true;
                                if (!request.HasErrored) {
                                    request.HasErrored = true;
                                }
                            }
                        });
                    }
                } else {
                    results = {} as MastResult;
                    results.Data = successfulResponse.body;
                    results.hasErrored = response.status > 399;
                }
            } catch (e) {
                results = {} as MastResult;
                contentType = successfulResponse.headers.get('content-type');
                if (contentType && contentType.startsWith('application/json') && expectMastResult) {
                    results.Data = JSON.parse(successfulResponse.body);
                } else {
                    results.Data = successfulResponse.body;
                }
            }
        }

        results.responseCode = response.status;
        results.operationName = operationName;
        results.cV = cV;
        this.debuggingService.add(results);
    }

    private logTransaction(
        startDate: Date,
        endDate: Date,
        operationName: string,
        response: HttpErrorResponse | HttpResponse<any>,
        methodType: string,
        contentType: string,
        results: MastResult,
        cV: string
    ) {
        const duration = startDate ? endDate.getTime() - startDate.getTime() : null;
        this.jsllService.webRequestComplete({
            operationName: operationName,
            currentOperationName: operationName,
            latencyMs: duration,
            httpStatusCode: `${response.status}`,
            httpMethod: methodType,
            contentType: contentType,
            requestUri: response.url,
            isSuccess: !results.hasErrored,
            errorMessage: results.errorMessage,
            operationVersion: '1.0',
            recount: 0,
            cV: cV,
            serviceErrorCode: response.status,
            serviceName: 'Mast',
        });
    }
}
