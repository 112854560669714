import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const INITIALIZE = '[TermsOfUse] Initialize';
export const PROMPT_USER = '[TermsOfUse] Prompt User';
export const SEARCH = '[TermsOfUse] Search';
export const SUCCESS = '[TermsOfUse] Success';
export const USER_CONSENTED = '[TermsOfUse] User Consented';
export const FAILED = '[TermsOfUse] Failed';
export const AAD_FAILED = '[TermsOfUse] AAD Failed';

export type AllTermsOfUseActions =
    | TermsOfUseSearchAction
    | TermsOfUseSuccessAction
    | TermsOfUseFailedAction
    | TermsOfUseInitializeAction
    | TermsOfUseUserConsentedAction
    | TermsOfUsePromptUserAction
    | TermsOfUseAadAuthFailedAction;

export class TermsOfUseInitializeAction implements Action {
    public readonly type = INITIALIZE;

    constructor(public path: any[], public query: object, public extras?: NavigationExtras) {}
}

export class TermsOfUsePromptUserAction implements Action {
    public readonly type = PROMPT_USER;

    constructor(public key: string) {}
}

export class TermsOfUseFailedAction implements Action {
    public readonly type = FAILED;

    constructor() {}
}

export class TermsOfUseSearchAction implements Action {
    public readonly type = SEARCH;

    constructor(public key: string, public encryptedValue) {}
}

export class TermsOfUseSuccessAction implements Action {
    public readonly type = SUCCESS;

    constructor() {}
}

export class TermsOfUseUserConsentedAction implements Action {
    public readonly type = USER_CONSENTED;

    constructor() {}
}

export class TermsOfUseAadAuthFailedAction implements Action {
    public readonly type = AAD_FAILED;

    constructor() {}
}
