import { Injectable } from '@angular/core';
import { MastResult } from '../models/dal/mast-result';
import { verifyAccessToConsumerPurchaseRequest } from '../shared/constants';

@Injectable()
export class DebuggingService {
    private static maxLength = 100;
    private errorCount = 0;
    private interactionLogs: Array<MastResult> = [];

    /**
     * Adds a MastResult entry to debugging queue.
     * @param results A MastResult entry to be prepended.
     */
    public add(results: MastResult) {
        if (results) {
            // Do not increment error count for access check for consumer purchase
            if (results.hasErrored && results.operationName !== verifyAccessToConsumerPurchaseRequest) {
                this.errorCount++;
            }

            this.interactionLogs.unshift(results);
        }

        if (this.interactionLogs.length > DebuggingService.maxLength) {
            const deletedElements = this.interactionLogs.splice(DebuggingService.maxLength, this.interactionLogs.length - DebuggingService.maxLength);
            if (deletedElements) {
                deletedElements.forEach((request) => {
                    if (request.hasErrored) {
                        this.errorCount--;
                    }
                });
            }
        }
    }

    /**
     * Retrieves the request log entries.
     * @returns {MastExternalRequest} The request log entries.
     */
    public get logs(): Array<MastResult> {
        return this.interactionLogs;
    }

    public get errorsFound(): number {
        return this.errorCount;
    }

    /**
     * Clears the request log entries.
     */
    public clear(): void {
        this.interactionLogs = [];
        this.errorCount = 0;
    }
}
