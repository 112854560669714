import { Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { selectAccountProfileState, selectOrganizationIdState } from '../../../enterprise/services/enterprise.selectors';
import { EnterpriseStates } from '../../../enterprise/services/enterprise.states';
import { MastResult } from '../../../models/dal/mast-result';
import { SearchCriteriaValues, SearchStatus } from '../../../models/search-criteria-values';
import { LookupService } from '../../../shared-services/lookup/lookup.service';
import { applyParameterReplacement, finalizeLink } from '../../../utils/lookup-tools';
import { formatStringToGuid } from '../../../utils/utils';
import { EnterpriseAccountProfileSearchAction, EnterpriseAccountProfileSearchResetAction } from '../account-profile/enterprise-account-profile-search.actions';
import { AccountRegistrationProfileSearchAction } from '../account-registration-details/account-registration-details-search.actions';
import { OrganizationSearchAction, OrganizationSearchResetAction } from '../organization/organization-search.actions';
import { UsersRolesResetActions } from '../users-roles/users-roles.actions';

export const EnterpriseLookupServiceToken = new InjectionToken<LookupService>('EnterpriseLookupServiceToken');

@Injectable()
export class EnterpriseLookupService extends LookupService {
    public static readonly organizationIdProperty = 'organizationId';
    public static readonly tenantIdProperty = 'tenantId';
    private static readonly genericIdProperty = 'id';
    private static readonly environmentProperty = 'environment';
    private static readonly methodProperty = 'method';
    private static readonly tenantAndOrgMethod = 'bytenantandorg';

    private accountProfileSearchObservable: Observable<any>;
    private organizationSearchObservable: Observable<any>;

    constructor(private store: Store<EnterpriseStates>) {
        super();
        this.accountProfileSearchObservable = store.select(selectAccountProfileState);
        this.organizationSearchObservable = store.select(selectOrganizationIdState);
    }

    public reset(): void {
        this.store.dispatch(new EnterpriseAccountProfileSearchResetAction());
    }

    public convertLink(link: string, searchCriteria: SearchCriteriaValues): string {
        let newLink = link;
        if (searchCriteria) {
            for (const propertyName in searchCriteria) {
                if (searchCriteria.hasOwnProperty(propertyName)) {
                    let linkPropertyName = propertyName;
                    if (propertyName === EnterpriseLookupService.organizationIdProperty || propertyName === EnterpriseLookupService.tenantIdProperty) {
                        linkPropertyName = EnterpriseLookupService.genericIdProperty;
                    }

                    newLink = applyParameterReplacement(newLink, propertyName, linkPropertyName, searchCriteria[propertyName]);
                }
            }

            newLink = finalizeLink(newLink);
        }

        return newLink;
    }

    /**
     * Performs the actual search against the various services necessary for the Enterprise Application Context.
     * @param searchCriteria
     */
    public performLookup(searchCriteria: SearchCriteriaValues): Observable<SearchCriteriaValues> {
        if (searchCriteria) {
            const searchTenantId: string = this.getStandardizedParameter(searchCriteria, EnterpriseLookupService.tenantIdProperty);
            const searchOrganizationId: string = this.getStandardizedParameter(searchCriteria, EnterpriseLookupService.organizationIdProperty);
            const environment: string = this.getStandardizedParameter(searchCriteria, EnterpriseLookupService.environmentProperty);

            if (!searchOrganizationId && searchTenantId) {
                this.store.dispatch(new EnterpriseAccountProfileSearchAction({ environment, tenantId: searchTenantId }));
                return this.accountProfileSearchObservable.pipe(
                    skipWhile((searchState) => !searchState.searchCompleted),
                    map((searchState) => searchState.results),
                    map((mastResults: MastResult) => {
                        this.resetAllCachedItems();
                        if (!mastResults || mastResults.hasErrored || !mastResults.Data) {
                            searchCriteria.searchStatus = SearchStatus.SearchFailed;
                        } else {
                            const profile = mastResults.Data;
                            this.store.dispatch(new OrganizationSearchResetAction());
                            if (profile && profile.TenantId) {
                                const tenantId = profile.TenantId;
                                searchCriteria[EnterpriseLookupService.tenantIdProperty] = tenantId;
                                searchCriteria.searchStatus = SearchStatus.SearchSuccessful;

                                this.store.dispatch(new AccountRegistrationProfileSearchAction({ tenantId, environment }));
                            } else {
                                searchCriteria.searchStatus = SearchStatus.SearchFailed;
                            }
                        }

                        return searchCriteria;
                    })
                );
            } else if (searchOrganizationId && searchTenantId) {
                this.store.dispatch(
                    new OrganizationSearchAction({
                        tenantId: searchTenantId,
                        organizationId: searchOrganizationId,
                        environment,
                    })
                );
                return this.organizationSearchObservable.pipe(
                    skipWhile((searchState) => !searchState.searchCompleted),
                    map((searchState) => searchState.results),
                    map((mastResults: MastResult) => {
                        this.resetAllCachedItems();
                        if (!mastResults || mastResults.hasErrored || !mastResults.Data) {
                            searchCriteria.searchStatus = SearchStatus.SearchFailed;
                        } else {
                            const profile = mastResults.Data;
                            this.store.dispatch(new EnterpriseAccountProfileSearchResetAction());
                            if (profile.TenantInfo && profile.UserInfo) {
                                const tenantId = profile.TenantInfo.TenantId;
                                const userPrincipalName = profile.UserInfo.UserPrincipalName;
                                if (tenantId && userPrincipalName) {
                                    searchCriteria[EnterpriseLookupService.tenantIdProperty] = tenantId;
                                    searchCriteria[EnterpriseLookupService.organizationIdProperty] = userPrincipalName;

                                    searchCriteria.searchStatus = SearchStatus.SearchSuccessful;
                                    this.store.dispatch(new AccountRegistrationProfileSearchAction({ tenantId, environment }));
                                }
                            } else {
                                searchCriteria.searchStatus = SearchStatus.SearchFailed;
                            }
                        }

                        return searchCriteria;
                    })
                );
            }

            searchCriteria = new SearchCriteriaValues();
            searchCriteria.searchStatus = SearchStatus.SearchFailed;
            return of(searchCriteria);
        }
    }

    public produceSearchCriteriaValues(snapshot: ActivatedRouteSnapshot): SearchCriteriaValues {
        const params = snapshot.params;
        const searchCriteriaValues = new SearchCriteriaValues();
        if (params[EnterpriseLookupService.environmentProperty]) {
            searchCriteriaValues.environment = params[EnterpriseLookupService.environmentProperty];
        }

        if (params[EnterpriseLookupService.tenantIdProperty]) {
            searchCriteriaValues[EnterpriseLookupService.tenantIdProperty] = params[EnterpriseLookupService.tenantIdProperty];
            searchCriteriaValues.wasGeneratedFromParams = true;
        }

        if (params[EnterpriseLookupService.organizationIdProperty]) {
            searchCriteriaValues[EnterpriseLookupService.organizationIdProperty] = params[EnterpriseLookupService.organizationIdProperty];
            searchCriteriaValues.method = EnterpriseLookupService.tenantAndOrgMethod;
            searchCriteriaValues.wasGeneratedFromParams = true;
        } else if (searchCriteriaValues[EnterpriseLookupService.tenantIdProperty]) {
            searchCriteriaValues.method = EnterpriseLookupService.tenantAndOrgMethod;
        }

        return searchCriteriaValues;
    }

    private resetAllCachedItems(): void {
        this.store.dispatch(new UsersRolesResetActions());
    }

    /**
     * Gets a standardized (e.g. trimed) value for any property declared.
     * @param searchCriteria The search criteria object.
     * @param propertyName The name of the property to standardize.
     */
    private getStandardizedParameter(searchCriteria: SearchCriteriaValues, propertyName: string): string {
        let id: string = searchCriteria[propertyName] as string;
        if (id) {
            id = id.trim();
            id = formatStringToGuid(id);
            searchCriteria[propertyName] = id;
        }

        return id;
    }
}
