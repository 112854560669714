import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SearchCriteriaValues, SearchStatus } from '../../models/search-criteria-values';
import { LookupService } from './lookup.service';

/**
 * This is a No Operation Lookup Service for any application context that doesn't contain any search criteria prerequesites.
 */
@Injectable()
export class NoopLookupService extends LookupService {
    private static readonly environmentProperty = 'environment';
    /**
     * Not used.
     */
    public reset(): void {}

    /**
     * This will transform abstract active links to its more definitve form.
     * @param link The abastract link.
     */
    public convertLink(link: string, searchCriteria: SearchCriteriaValues): string {
        let newLink = link;
        if (searchCriteria) {
            for (const propertyName in searchCriteria) {
                if (searchCriteria.hasOwnProperty(propertyName)) {
                    newLink = newLink.replace(`:${propertyName}`, searchCriteria[propertyName] as string);
                }
            }
        }

        return newLink;
    }

    /**
     * Performs a state change, but nothing else.
     * @param searchCriteria
     */
    public performLookup(searchCriteria: SearchCriteriaValues): Observable<SearchCriteriaValues> {
        searchCriteria.searchStatus = SearchStatus.SearchSuccessful;
        return of(searchCriteria);
    }

    /**
     * Creates a SearchCriteriaValues based on URL/Router parameters.
     * @param snapshot URL/Router parameters.
     */
    public produceSearchCriteriaValues(snapshot: ActivatedRouteSnapshot): SearchCriteriaValues {
        const params = snapshot.params;
        const searchCriteriaValues: SearchCriteriaValues = new SearchCriteriaValues();
        if (params[NoopLookupService.environmentProperty]) {
            searchCriteriaValues.environment = params[NoopLookupService.environmentProperty];
        }

        searchCriteriaValues.wasGeneratedFromParams = true;
        return searchCriteriaValues;
    }
}
