import { Directive, Input } from '@angular/core';
import { DrillDownConfig, DrillDownConfigDefaults } from './drill-down-config';
import { DrillDownFieldPathElement } from './drill-down-field-path-element';

/**
 * This is the base class for each default viewer.
 */
@Directive()
export abstract class GenericDefaultViewerDirective<T, V> {
    protected currentLineage: Array<DrillDownFieldPathElement>;
    public currentData: T;
    protected currentSchema: V;
    protected currentDepth: number;
    protected currentConfig: DrillDownConfig;
    protected currentPageState: string;

    constructor() {
        this.currentConfig = { ...DrillDownConfigDefaults };
    }

    /**
     * The name of the property that is currently being displayed.
     */
    @Input()
    name: string;

    /**
     * The data that's to be rendered.
     * @param value The data that's to be rendered.
     */
    abstract set data(value: T);

    /**
     * The schema that provides metadata on how to render the data provided.
     * @param value The schema that provides metadata on how to render the data provided.
     */
    @Input()
    set schema(value: V) {
        this.currentSchema = value;
    }

    get schema() {
        return this.currentSchema;
    }

    /**
     * The depth of the data relative to the root data currently being displayed by the DrillDownViewer.
     * @param value The depth.
     */
    @Input()
    set depth(value: number) {
        this.currentDepth = value;
    }

    /**
     * The global configuration.
     * @param value  The global configuration.
     */
    @Input()
    set config(value: DrillDownConfig) {
        this.currentConfig = { ...DrillDownConfigDefaults, ...value };
    }

    get config() {
        return this.currentConfig;
    }

    /**
     * The value of the lineage.
     * @param value The data of the parent/host object.
     */
    @Input()
    set lineage(value: Array<DrillDownFieldPathElement>) {
        this.currentLineage = value;
    }
}
