export enum ToolsSubpathBases {
    DeviceImeiLookup = 'deviceimeilookup',
    FulfillmentLookup = 'fulfillmentlookup',
    InventoryLookup = 'inventorylookup',
    ProductLookup = 'productlookup',
    TenantLookup = 'tenantlookup',
    TokenLookup = 'tokenlookup',
}

export enum ToolsRouteParamsEnum {
    ProductId = 'productid',
}

export const ToolsSubpaths = {
    DeviceImeiLookup: ToolsSubpathBases.DeviceImeiLookup,
    FulfillmentLookup: ToolsSubpathBases.FulfillmentLookup,
    InventoryLookup: ToolsSubpathBases.InventoryLookup,
    ProductLookupWithParams: `${ToolsSubpathBases.ProductLookup}/:${ToolsRouteParamsEnum.ProductId}`,
    ProductLookup: ToolsSubpathBases.ProductLookup,
    TenantLookup: ToolsSubpathBases.TenantLookup,
    TokenLookup: ToolsSubpathBases.TokenLookup,
};
